// eslint-disable-next-line import/no-cycle
import background from './portalGates/toBackground';
import { setActiveSchemaMigration } from './stateUpdaters/clientStateUpdaters/other';

const onDatabaseSchemaConflict = async () => {
  await setActiveSchemaMigration(true);
  await background.clearAllLocalData();
};

export default onDatabaseSchemaConflict;
