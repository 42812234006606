export const getATagAncestor = (element: HTMLElement): HTMLAnchorElement | null => {
  let iteratedElement: HTMLElement | null = element;
  while (iteratedElement != null) {
    if (iteratedElement.tagName === 'A') {
      return iteratedElement as HTMLAnchorElement;
    }
    if (iteratedElement.id === 'document-text-content') {
      return null;
    }
    iteratedElement = iteratedElement.parentElement;
  }
  return null;
};
