import { isHTMLElement } from '../../typeValidators';

const isFocusableElement = (node: Node): boolean => {
  if (!node) {
    throw new Error('Element not given');
  }

  if (!isHTMLElement(node)) {
    return false;
  }

  const element = node as HTMLElement;

  const tagName = element.tagName.toLowerCase();

  // Standalone images are allowed (they're given `display: block;` in CSS). Also, `<p><a ...><img ...></a></p>` should return true
  if (
    ['article', 'div', 'figcaption', 'mask', 'path', 'svg'].includes(tagName) ||
    tagName !== 'img' && !element.innerText?.trim() && !element.querySelector('img')
  ) {
    return false;
  }

  // skip the embedded tweet header and footer
  const embeddedTweetJunk = element.closest('.rw-embedded-tweet > header, .rw-embedded-tweet > footer');
  if (embeddedTweetJunk) {
    return false;
  }

  const { display } = window.getComputedStyle(element);
  return ['block', 'list-item'].includes(display);
};

export default isFocusableElement;
