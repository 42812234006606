import {
  arrayBufferToBase64,
  ensureNotFalsy,
  ensureRxStorageInstanceParamsAreCorrect,
  flatClone,
  RXDB_VERSION,
  RxStorage,
  RxStorageInstanceCreationParams,
} from 'rxdb/plugins/core';

import { RX_STORAGE_NAME_SQLITE } from './sqlite-helpers';
import { createSQLiteStorageInstance, RxStorageInstanceSQLite } from './sqlite-storage-instance';
import type {
  SQLiteInstanceCreationOptions,
  SQLiteInternals,
  SQLiteStorageSettings,
} from './sqlite-types';

export * from './sqlite-helpers';
export * from './sqlite-types';
export * from './sqlite-storage-instance';
export * from './sqlite-basics-helpers';

/**
 * @link https://stackoverflow.com/a/38877890/3443137
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Buffer: any;

export class RxStorageSQLite implements RxStorage<SQLiteInternals, SQLiteInstanceCreationOptions> {
  public name = RX_STORAGE_NAME_SQLITE;
  public rxdbVersion = RXDB_VERSION;
  constructor(public settings: SQLiteStorageSettings) {}

  public createStorageInstance<RxDocType>(
    params: RxStorageInstanceCreationParams<RxDocType, SQLiteInstanceCreationOptions>,
  ): Promise<RxStorageInstanceSQLite<RxDocType>> {
    ensureRxStorageInstanceParamsAreCorrect(params);
    return createSQLiteStorageInstance(this, params, this.settings);
  }

  /**
   * Helper functions for SQLite attachments.
   * We need that because some runtimes do not support
   * storing buffers, so we have to store a plain base64 string instead.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public base64AttachmentToStoredAttachmentsData(base64: string): any {
    if (this.settings.storeAttachmentsAsBase64String) {
      return base64;
    }
    return Buffer.from(base64, 'base64');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public storedAttachmentsDataToBase64(stored: any): string {
    if (typeof stored === 'string') {
      return stored;
    }
    if (stored instanceof Uint8Array) {
      return arrayBufferToBase64(stored);
    }
    return stored.toString('base64');
  }
}

export function getRxStorageSQLite(settings: SQLiteStorageSettings): RxStorageSQLite {

  /**
   * Wrap the SQLiteBasics
   * if a logger exists.
   */
  if (settings.log) {
    let logCount = 0;
    const logFn = (msg: string) => ensureNotFalsy(settings.log)(`## RxStorage SQLite log: ${msg}`);
    // eslint-disable-next-line no-param-reassign
    settings = flatClone(settings);
    const basics = flatClone(settings.sqliteBasics);

    // open()
    const openBefore = basics.open;
    basics.open = (name) => {
      const counter = logCount++;
      logFn(`open(${counter}) ${name}`);
      return openBefore(name)
        .then((result) => {
          logFn(`open(${counter}) DONE `);
          return result;
        })
        .catch((err) => {
          logFn(`open(${counter}) ERROR `);
          throw err;
        });
    };

    // all()
    const allBefore = basics.all;
    basics.all = (db, queryWithParams) => {
      const counter = logCount++;
      logFn(`all(${counter}) ${JSON.stringify(queryWithParams)}`);
      return allBefore(db, queryWithParams)
        .then((result) => {
          logFn(`all(${counter}) DONE `);
          return result;
        })
        .catch((err) => {
          logFn(`all(${counter}) ERROR `);
          throw err;
        });
    };

    // run()
    const runBefore = basics.run;
    basics.run = (db, queryWithParams) => {
      const counter = logCount++;
      logFn(`run(${counter}) ${JSON.stringify(queryWithParams)}`);
      return runBefore(db, queryWithParams)
        .then((result) => {
          logFn(`run(${counter}) DONE `);
          return result;
        })
        .catch((err) => {
          logFn(`run(${counter}) ERROR `);
          throw err;
        });
    };

    // setPragma()
    const setPragmaBefore = basics.setPragma;
    basics.setPragma = (db, key, value) => {
      const counter = logCount++;
      logFn(`setPragma(${counter}) ${JSON.stringify({ key, value })}`);
      return setPragmaBefore(db, key, value)
        .then((result) => {
          logFn(`setPragma(${counter}) DONE `);
          return result;
        })
        .catch((err) => {
          logFn(`setPragma(${counter}) ERROR `);
          throw err;
        });
    };

    settings.sqliteBasics = basics;
  }

  const storage = new RxStorageSQLite(settings);
  return storage;
}
