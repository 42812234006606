import pick from 'lodash/pick';

// eslint-disable-next-line restrict-imports/restrict-imports
import { Cache as LegacyCache } from '../../../background/cache.platform';
import { RxDBInstance } from '../../../types/database';
import makeLogger from '../../../utils/makeLogger';
import { schema } from '../defineSchema';
import getDatabaseName from '../getDatabaseName';
import { migrationStartedKey, stateSyncingUpdatesKey, StorageType, StorageVersion } from './constants';

const logger = makeLogger(__filename);

async function readDataFromStores(dbName: string, collections: string[]) {
  const db = await openIndexedDB(dbName);

  const allData: {
    [key in (typeof collections)[number]]?: unknown;
  } = {};
  for (const collection of collections) {
    const collectionSchema = schema[collection].schema;
    const storeName = `${collection}-${collectionSchema.version}-${StorageType.Documents}`;
    const storeExists = await checkObjectStoreExists(db, storeName);
    if (storeExists) {
      allData[collection] = await readAllFromStore(db, storeName);
    }
  }

  db.close();
  return allData;
}

function openIndexedDB(dbName: string): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = (event) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      reject(event.target.error);
    };
  });
}

interface IndexedDbData {
  id: string;
  sentToServer?: number;
  value: string;
}

function readAllFromStore(db: IDBDatabase, storeName: string) {
  return new Promise((resolve, reject) => {
    const data: IndexedDbData[] = [];
    const transaction = db.transaction(storeName, 'readonly');
    const objectStore = transaction.objectStore(storeName);
    const cursorRequest = objectStore.openCursor();

    cursorRequest.onsuccess = (event: Event) => {
      const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
      if (cursor) {
        // filter for updates that have not been sent to the server
        if (cursor.value.d.sentToServer === 0) {
          data.push({ ...pick(cursor.value.d, ['id', 'sentToServer', 'value']) });
        }

        cursor.continue();
      } else {
        resolve(data);
      }
    };

    cursorRequest.onerror = (event: Event) => {
      reject((event.target as IDBRequest).error);
    };
  });
}

async function deleteIndexedDB(dbName: string) {
  return new Promise<void>((resolve, reject) => {
    const request = indexedDB.deleteDatabase(dbName);

    request.onsuccess = () => {
      logger.debug(`Database "${dbName}" deleted successfully.`);
      resolve();
    };

    request.onerror = (event) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      logger.error(`Error deleting database "${dbName}":`, { error: event.target.error });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      reject(event.target.error);
    };

    request.onblocked = () => {
      logger.warn(`Deletion of database "${dbName}" is blocked.`);
    };
  });
}

/**
 * We essentially check for a change introduced in RxDB 15, WAL object stores (
 * write-ahead log). If the object store for WAL is present, we can assume we
 * have already migrated the storage to the RxDB 15 format, otherwise we treat
 * storage like it is still RxDB 14.
 *
 * @param dbName
 * @param collectionToTest
 */
export async function detectDatabaseStorageVersion(dbName: string, collectionToTest: string) {
  const collectionSchema = schema[collectionToTest].schema;
  const schemaVersion = collectionSchema.version;
  const db = await openIndexedDB(dbName);
  const documentStoreName = createObjectStoreName(
    collectionToTest,
    schemaVersion,
    StorageType.Documents,
  );
  const documentStoreExists = await checkObjectStoreExists(db, documentStoreName);
  const walStoreName = createObjectStoreName(collectionToTest, schemaVersion, StorageType.Wal);
  const walStoreExists = await checkObjectStoreExists(db, walStoreName);

  /*
   * We can safely assume that we are on the latest version if both storage
   * types are the same and they both exist.
   */
  if (documentStoreExists && documentStoreExists === walStoreExists) {
    return StorageVersion.Rxdb15;
  }

  /*
   * If the WAL storage does not exist, we assume it is Rxdb14
   */
  if (documentStoreExists && !walStoreExists) {
    return StorageVersion.Rxdb14;
  }

  return StorageVersion.Rxdb14;
}

export async function startStorageMigration() {
  const databaseName = getDatabaseName();

  localStorage.removeItem(`rx-storage-localstorage-${databaseName}--_rxdb_internal--0`);
  localStorage.removeItem(`rx-storage-localstorage-${databaseName}-remote--_rxdb_internal--0`);

  const data = await readDataFromStores(databaseName, ['state_syncing_updates']);

  const stateSyncingUpdatesData = data.state_syncing_updates;
  await LegacyCache.setItem(stateSyncingUpdatesKey, JSON.stringify(stateSyncingUpdatesData));
  await LegacyCache.setItem(migrationStartedKey, 1);

  await removeAllDatabases();
}

export async function finishStorageMigration(db: RxDBInstance) {
  const migrationData = await LegacyCache.getItems([stateSyncingUpdatesKey, migrationStartedKey]);

  if (!migrationData[migrationStartedKey]) {
    return;
  }

  const stateSyncingUpdatesData = JSON.parse(migrationData[stateSyncingUpdatesKey]) || [];
  await db.collections.state_syncing_updates.bulkInsert(stateSyncingUpdatesData);

  await LegacyCache.removeItems([stateSyncingUpdatesKey, migrationStartedKey]);
}

export async function removeAllDatabases() {
  const databaseName = getDatabaseName();
  await deleteIndexedDB(databaseName);
}

function createObjectStoreName(
  collectionName: string,
  schemaVersion: number,
  storeType: StorageType,
): string {
  return `${collectionName}-${schemaVersion}-${storeType}`;
}

async function checkObjectStoreExists(db: IDBDatabase, storeName: string): Promise<boolean> {
  return new Promise((resolve) => {
    const storeExists = db.objectStoreNames.contains(storeName);
    resolve(storeExists);
  });
}
