import type { PointerLikeEventCoordinatesObject } from '../../../types/browserEvents';
import type { CardinalDirection, Direction } from '../../../types/misc';
import getCoordinatesObjectFromPointerLikeEvent from '../../utils/getCoordinatesObjectFromPointerLikeEvent';

export default function getScrollDirection(
  originCoordinates: PointerLikeEventCoordinatesObject,
  pointermoveEvent: PointerEvent | TouchEvent,
): Direction {
  const newCoordinates = getCoordinatesObjectFromPointerLikeEvent(pointermoveEvent);

  const deltaY = newCoordinates.clientY - originCoordinates.clientY;
  let verticalCardinalDirection: CardinalDirection | '' = '';
  if (deltaY > 0) {
    verticalCardinalDirection = 'south';
  } else if (deltaY < 0) {
    verticalCardinalDirection = 'north';
  }

  const deltaX = newCoordinates.clientX - originCoordinates.clientX;
  let horizontalCardinalDirection: CardinalDirection | '' = '';
  if (deltaX > 0) {
    horizontalCardinalDirection = 'east';
  } else if (deltaX < 0) {
    horizontalCardinalDirection = 'west';
  }

  return ([verticalCardinalDirection, horizontalCardinalDirection].filter(Boolean).join('-') ||
    'south') as Direction;
}
