import {
  type AnyDocument,
  type FullZustandState,
  type Highlight,
  type PartialDocument,
  RightSidebarVisiblePanel,
  SubMenu,
} from '../../types';
import { DocumentTag } from '../../types/tags';
import nowTimestamp from '../../utils/dates/nowTimestamp';
import getServerBaseUrl from '../../utils/getServerBaseUrl.platform';
import pluralize from '../../utils/pluralize';
import requestWithAuth from '../../utils/requestWithAuth.platformIncludingExtension';
import { openDocNoteSubMenu, setCmdPaletteOpen, setMobileSubmenuOpen } from '../cmdPalette';
import database from '../database';
import eventEmitter from '../eventEmitter';
import { updateState } from '../models';
import { getNoteFromHighlight } from '../stateGetters';
import { saveDocumentNote } from '../stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import {
  updateHighlight,
  updateHighlightNote,
} from '../stateUpdaters/persistentStateUpdaters/documents/highlight';
import { updateGeneratedDocumentSummary } from '../stateUpdaters/persistentStateUpdaters/documents/summary';
import { addTags } from '../stateUpdaters/persistentStateUpdaters/documents/tag';
import {
  resetDocumentSummaryGeneration,
  startDocumentSummaryGeneration,
  stopDocumentSummaryGeneration,
} from '../stateUpdaters/transientStateUpdaters/documentSummary';
import {
  setFocusedDocumentId,
  setFocusedHighlightId,
  setGPTPromptLoadingStatus,
} from '../stateUpdaters/transientStateUpdaters/other';
import { createToast } from '../toasts.platform';
import type { Prompt } from './types';
import { errorIncludesResponse } from './utils';

export const addGptOutputToNote = async (
  doc: AnyDocument | PartialDocument<AnyDocument, 'id' | 'title' | 'notes'> | null | void,
  gptPrompt: FullZustandState['gptPrompt'],
  highlight?:
    | Highlight
    | PartialDocument<Highlight, 'id' | 'parent' | 'source_specific_data' | 'children'>
    | null
    | void,
  overriddenPrompt?: string,
  overriddenSystemPrompt?: string,
) => {
  if (!doc) {
    throw new Error('No document');
  }

  await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  const templatedPrompt = overriddenPrompt ?? gptPrompt?.prompt;
  const systemPrompt = overriddenSystemPrompt ?? gptPrompt?.systemPrompt;

  if (!templatedPrompt) {
    throw new Error('No prompt found');
  }

  setGPTPromptLoadingStatus(true);
  if (highlight) {
    setFocusedHighlightId(highlight.id);
    setMobileSubmenuOpen(SubMenu.HighlightNote, true);
  } else {
    setMobileSubmenuOpen(SubMenu.DocNote, true);
  }

  try {
    const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/completion/`, {
      body: JSON.stringify({
        template: {
          prompt: templatedPrompt,
          systemPrompt,
          selection: gptPrompt?.selection,
          highlightId: highlight?.id,
          expandedSelection: gptPrompt?.expandedSelection,
          focusIndicator: gptPrompt?.surroundingParagraphContents,
          documentId: highlight?.parent ?? doc?.id,
        },
      }),
      credentials: 'include',
      method: 'POST',
      mode: 'cors',
    });

    const response = await resp.json();

    if (highlight) {
      const oldNote = await getNoteFromHighlight(highlight);
      const noteInput = [response.gpt3.trim(), oldNote.trim()]
        .filter((note) => Boolean(note))
        .join('\n\n');
      await updateHighlightNote(highlight, noteInput, {
        isUndoable: false,
        userInteraction: 'gpt',
      });
      createToast({
        content: 'GPT added to highlight',
        category: 'success',
      });
      const oldData = highlight.source_specific_data;
      await updateHighlight(highlight.id, {
        source_specific_data: {
          ...oldData,
          ghostreader: {
            templatedPrompt,
            renderedPrompt: response.prompt,
          },
        },
      });

      eventEmitter.emit('ghostreader:highlight', highlight.id);
    } else {
      let newNoteContents = `${response.gpt3.trim()}`;
      if (doc.notes) {
        newNoteContents += `\n\n${doc.notes}`;
      }
      saveDocumentNote(doc.id, newNoteContents, { userInteraction: 'gpt' });
      eventEmitter.emit('document-sidebar:setVisiblePanel', RightSidebarVisiblePanel.DocumentNotebook);
      createToast({
        content: 'GPT added to document note',
        category: 'success',
        buttonText: 'View',
        onButtonClick: () => {
          setFocusedDocumentId(doc.id, { userInteraction: null });
          openDocNoteSubMenu();
        },
      });
    }
  } catch (error) {
    // see if we got a error message through the response object
    if (errorIncludesResponse(error)) {
      const response = await error.response.json();
      createToast({ content: response.error, category: 'error' });
    } else {
      createToast({ content: 'Failed to create document note', category: 'error' });
    }
  } finally {
    setGPTPromptLoadingStatus(false);

    await updateState(
      (state) => {
        // Free up memory
        state.gptPrompt = null;
      },
      { eventName: 'gpt-prompt', userInteraction: 'unknown' },
    );
  }
};

export const generateHighlights = async (
  doc: AnyDocument | PartialDocument<AnyDocument, 'id' | 'title' | 'notes'> | null | void,
  prompt: Prompt,
) => {
  if (!doc) {
    throw new Error('No document');
  }

  await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  const templatedPrompt = prompt.template;
  const systemPrompt = prompt.system;

  setGPTPromptLoadingStatus(true);
  setMobileSubmenuOpen(SubMenu.HighlightNote, true);

  try {
    const response = await requestWithAuth(`${getServerBaseUrl()}/reader/api/completion/`, {
      body: JSON.stringify({
        template: {
          prompt: templatedPrompt,
          systemPrompt,
          documentId: doc.id,
        },
      }),
      credentials: 'include',
      method: 'POST',
      mode: 'cors',
    });

    setGPTPromptLoadingStatus(false);

    if (!response.ok) {
      createToast({
        content: 'GPT failed, see network response',
        category: 'error',
      });

      await updateState(
        (state) => {
          // Free up memory
          state.gptPrompt = null;
        },
        { eventName: 'gpt-prompt', userInteraction: 'unknown' },
      );

      return;
    }

    const content = await response.json();
    if (content.warnings) {
      content.warnings.forEach((content: string) => createToast({ content, category: 'warning' }));
    }

    const highlights = JSON.parse(content.prompt);
    const tags: { [key: string]: DocumentTag; } = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '👻 ai highlighted': {
        name: '👻 ai highlighted',
        type: 'automatic',
        created: nowTimestamp(),
      },
    };

    eventEmitter.emit('highlightMatchingTextBlocks', {
      highlights,
      tags,
      userInteraction: 'unknown',
    });
    eventEmitter.emit('document-sidebar:setVisiblePanel', RightSidebarVisiblePanel.DocumentNotebook);

    if (highlights.length > 0) {
      createToast({
        category: 'success',
        content: `Generated ${highlights.length} ${pluralize('highlight', highlights.length)}`,
      });
    } else {
      createToast({
        category: 'info',
        content: "Couldn't find any meaningful highlights",
      });
    }
  } catch (error) {
    // see if we got a error message through the response object
    if (errorIncludesResponse(error)) {
      const response = await error.response.json();
      createToast({ content: response.error, category: 'error' });
    } else {
      createToast({ content: 'Failed extracting highlights', category: 'error' });
    }
  } finally {
    setGPTPromptLoadingStatus(false);
    eventEmitter.emit('document-sidebar:setVisiblePanel', RightSidebarVisiblePanel.DocumentNotebook);
    await updateState(
      (state) => {
        state.gptPrompt = null;
      },
      { eventName: 'gpt-prompt', userInteraction: 'unknown' },
    );
  }
};

export const generateTags = async (
  doc: AnyDocument | PartialDocument<AnyDocument, 'id' | 'title' | 'notes'> | null | void,
  prompt: Prompt,
) => {
  if (!doc) {
    throw new Error('No document');
  }

  await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
  setGPTPromptLoadingStatus(true);

  try {
    const tagNames = (await database.collections.global_tags.findAll()).map((tag) => tag.name);
    const result = await requestWithAuth(
      `${getServerBaseUrl()}/reader/api/create_auto_tags_for_document`,
      {
        // signal,
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({ document_id: doc.id, tags: tagNames }),
      },
    );

    const data = await result.json();
    if (data.tags && data.tags.length > 0) {
      await addTags(doc.id, data.tags, {
        type: 'generated',
        userInteraction: 'unknown',
      });

      createToast({
        content: `Extracted ${data.tags.length} ${pluralize('tag', data.tags.length)}`,
        category: 'info',
      });
    } else {
      createToast({
        content: 'Could not find any meaningful tags',
        category: 'info',
      });
    }
  } catch (error) {
    // Request was aborted, we throw the AbortError
    if (error instanceof DOMException && error.name === 'AbortError') {
      throw error;
    }

    // see if we got a error message through the response object
    if (errorIncludesResponse(error)) {
      const response = await error.response.json();
      createToast({ content: response.error, category: 'error' });
    } else {
      createToast({ content: 'Failed to generate tags', category: 'error' });
    }
  } finally {
    setGPTPromptLoadingStatus(false);
  }
};

export async function generateSummary(
  doc: AnyDocument | PartialDocument<AnyDocument, 'id' | 'title' | 'notes'> | null | void,
  prompt: Prompt,
) {
  if (!doc) {
    throw new Error('No document');
  }

  try {
    await setCmdPaletteOpen(false, { userInteraction: 'unknown' });
    await startDocumentSummaryGeneration('unknown');

    const result = await requestWithAuth(`${getServerBaseUrl()}/reader/api/completion`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify({
        template: {
          documentId: doc.id,
          model: prompt.model,
          prompt: prompt.template,
          systemPrompt: prompt.system,
        },
      }),
    });

    const json = await result.json();
    const summary = json.gpt3 && json.gpt3.length > 0 ? json.gpt3 : '';

    await updateGeneratedDocumentSummary(doc.id, summary);
    await stopDocumentSummaryGeneration('unknown');
  } catch (error) {
    // see if we got a error message through the response object
    if (errorIncludesResponse(error)) {
      const response = await error.response.json();
      createToast({ content: response.error, category: 'error' });
    } else {
      createToast({ content: 'Failed to generate summary', category: 'error' });
    }

    await resetDocumentSummaryGeneration('unknown');
  }
}
