import { Theme } from '../../../css/baseStyles';

export const getTTSStyles = ({ isHighContrast, theme }: { isHighContrast: boolean; theme: Theme; }) => {
  return `
  <style id="RWTtsStyles">
    .tts-position-indicator {
      position: absolute;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: end;
      background: ${isHighContrast ? 'transparent' : theme.selection};
      border-radius: 5px;
      width: 1px;
      padding: 0 1px;
      left: -1px;
      outline-color: ${theme.selection};
      outline-style: solid;
      outline-width: 2px;
      user-select: none;
      -webkit-user-select: none;
      touch-action: none;
      pointer-events: none;
      transition: none; /*  .tts-position-indicator--with-transition */
    }

    .tts-position-indicator::before {
      content: '';
    }

    .tts-position-indicator--with-transition {
      transition: transform 0.3s, width 0.3s, top 0.3s;
    }

    .tts-position-indicator--with-transition.tts-position-indicator-large {
      transition: transform 0.2s, width 0.2s, top 0.2s, height 0.2s;
    }

    .tts-position-indicator--with-transition.tts-position-indicator-end {
      transition: width 0.2s;
    }
  </style>
  `;
};
