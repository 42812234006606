import partition from 'lodash/partition';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { batchArray } from 'rxdb';
import { ulid } from 'ulid';

import type { AnyDocument, DocumentWithParsedDocId, EditableKeys } from '../../types';
import type { DocumentTagsObject } from '../../types/tags';
import { DeferredPromise } from '../../utils/DeferredPromise';
import delay from '../../utils/delay';
import { isDeployPreview, isDevOrTest, isWebApp } from '../../utils/environment';
// eslint-disable-next-line import/no-cycle
import exceptionHandler from '../../utils/exceptionHandler.platform';
import getDocumentOverrideOrReal from '../../utils/getDocumentOverrideOrReal';
import makeLogger from '../../utils/makeLogger';
import { RWDOMParser } from '../../utils/RWDOMParser.platform';
import { shouldIncludeDocumentInSearch } from '../../utils/shouldIncludeDocumentInSearch';
import {
  type CreateSqliteDatabaseFunction,
  type ISqliteDatabase,
  type SqliteValue,
  DiskImageMalformedError,
} from '../sqliteDatabase';
import { ABSOLUTE_BEST_RANK, SEARCH_DB_NAME, SEARCH_FIELD_WEIGHT } from './constants';
import type { SchemaMigrator } from './schemaMigrator';

const logger = makeLogger(__filename);

export type ISearchDocumentRow = {
  doc_id: string;
  parsed_doc_id: string;

  url: string;
  title: string;
  author: string | null;
  tags: string | null;
  notes: string | null;
  summary: string | null;
  content: string | null;
};

export type ISearchResultRow = {
  doc_id: string;
  parsed_doc_id: string;

  url: string;
  title: string;
  author: string | null;
  tags: string | null;
  notes: string | null;
  summary: string | null;

  // for debugging
  subquery: string;
} & ISearchRank &
  ISearchMatches;

// 'Rank' in our composite search query is a tuple of (subqueryIndex, rankInSubquery).
// This means a result from subquery N will ALWAYS be ranked above a result from subquery N+1,
// enabling us to order more exact search results above less exact ones.
export type ISearchRank = {
  subqueryIndex: number;
  rankInSubquery: number;
};

export type ISearchMatches = {
  content_match: string | null;
  title_match: string | null;
  author_match: string | null;
};

export type ISearchDocumentCounts = {
  total: number;
  withContent: number;
};

type ISearchDocumentPresence = {
  doc_id: string;
  has_content: number;
};

export type ISearchResult = {
  searchQuery: string[];
  rows: ISearchResultRow[];
  totalCount: number;
  pageSize: number;
};

export type AnyDocumentWithContent = AnyDocument & {
  html_content?: string;
  text_content?: string; // automatically populated from html_content if left blank
};

export type ISearchDocument = {
  doc_id: string;
  parsed_doc_id: string;
  url: string;

  title: string;
  author: string | null;
  tags: string | null; // separated by spaces, null if no tags
  notes: string | null;
  summary: string | null;

  html_content: string | null;
  text_content: string | null; // automatically populated from html_content if left blank
};

export interface IDocumentSearchEngine {
  init(): Promise<void>;

  upsertDocuments(
    docsToUpsert: AnyDocumentWithContent[],
    docsToOnlyUpdate?: AnyDocument[],
  ): Promise<void>;

  removeDocuments(docIds: string[]): Promise<void>;

  getDocumentCounts(): Promise<ISearchDocumentCounts>;

  searchDocuments(searchQuery: string, pageSize: number, minimumRank: number): Promise<ISearchResult>;

  loadMoreResultRows(result: ISearchResult): Promise<ISearchResult>;

  destroy(): Promise<void>;
}

export class DocumentSearchEngine implements IDocumentSearchEngine {
  private db: ISqliteDatabase;
  private initializationPromise: DeferredPromise<void> | undefined;
  private upsertLock: DeferredPromise<void> | undefined;

  constructor(
    private readonly createSqliteDatabase: CreateSqliteDatabaseFunction,
    private readonly migrator: SchemaMigrator | null,
  ) {
    this.db = createSqliteDatabase(SEARCH_DB_NAME);
    migrator?.init(this, createSqliteDatabase);
    if (isWebApp && (isDevOrTest || isDeployPreview)) {
      Object.assign(window, {
        documentSearchDB: this.db,
        documentSearchEngine: this,
      });
    }
  }

  async init(): Promise<void> {
    if (this.initializationPromise) {
      await this.initializationPromise;
      return;
    }
    this.initializationPromise = new DeferredPromise<void>();
    logger.debug('Initializing');
    try {
      await this.prepareDatabase();
    } catch (error) {
      if (error instanceof DiskImageMalformedError) {
        exceptionHandler.captureException('Search DB disk image malformed, rebuilding', {
          extra: {
            error,
            message: error.message,
          },
        });
        // Stop all functions which called init() from completing.
        this.initializationPromise.reject(error);
        // Reset initialization state so we reinitialize cleanly.
        this.initializationPromise = undefined;
        await this.deleteDatabase();
        await this.migrator?.resetMigrationState();
        await this.migrator?.clearContentStore();
        // Clear content so we index it into the rebuilt search DB.
        return;
      } else {
        throw error;
      }
    }
    // migrator is null in test
    await this.migrator?.migrateSchema();
    logger.debug('Initialized');
    this.initializationPromise.resolve();
  }

  async getDocumentCounts(): Promise<ISearchDocumentCounts> {
    await this.init();
    const [[{ total }], [{ withContent }]] = await Promise.all([
      this.db.select<{ total: number; }>('SELECT COUNT(1) as total FROM documents;'),
      this.db.select<{ withContent: number; }>(
        'SELECT COUNT(1) as withContent FROM documents WHERE content IS NOT NULL;',
      ),
    ]);
    return { total, withContent };
  }

  async upsertDocuments(
    docsToUpsert: AnyDocumentWithContent[],
    docsToOnlyUpdate: AnyDocument[] = [], // used for expensive metadata updates, so only pass docs that are updated.
  ): Promise<void> {
    if (docsToUpsert.length === 0 && docsToOnlyUpdate.length === 0) {
      return;
    }
    await this.init();
    // upsert lock ensures at most ONE upsert call is executing at a time, preventing data races
    while (this.upsertLock) {
      await this.upsertLock;
    }
    this.upsertLock = new DeferredPromise<void>();
    const id = ulid();
    logger.time(`Upsert documents ${id}`);
    const documents: ISearchDocument[] = this._prepareDocumentsForSearch(docsToUpsert);
    const docsToUpdateMetadataFor: ISearchDocument[] = this._prepareDocumentsForSearch(docsToOnlyUpdate);
    const oldContentByParsedDocId = await this.migrator?.maybeLoadContentFromOldSearchDB(documents);
    for (const doc of documents) {
      if (doc.text_content) {
        continue;
      }
      // if we can import text content from old search DB, do so
      const oldTextContent = oldContentByParsedDocId?.[doc.parsed_doc_id];
      if (oldTextContent) {
        doc.text_content = oldTextContent;
      } else if (doc.html_content) {
        // otherwise try to extract it out of the html content if it exists
        doc.text_content = this._extractTextContent(doc.html_content);
      }
    }
    const documentPresences = await this.db.select<ISearchDocumentPresence>(
      `
        SELECT doc_id, has_content
        FROM presence
        WHERE doc_id IN (${documents.map(() => '?').join(', ')});
      `,
      documents.map((doc) => doc.doc_id),
    );
    const existingRows = new Set(documentPresences.map((row) => row.doc_id));
    const existingRowsWithContent = new Set(
      documentPresences.filter((row) => row.has_content).map((row) => row.doc_id),
    );
    const docsToUpdateContentFor = documents.filter(
      (doc) =>
        doc.text_content && !existingRowsWithContent.has(doc.doc_id) && existingRows.has(doc.doc_id),
    );
    const docsToInsert = documents.filter((doc) => !existingRows.has(doc.doc_id));
    if (
      docsToInsert.length > 0 ||
      docsToUpdateContentFor.length > 0 ||
      docsToUpdateMetadataFor.length > 0
    ) {
      logger.debug(`Upsert documents ${id}`, {
        documents,
        documentPresences,
        docsToUpdateContentFor,
        docsToOnlyUpdate,
        docsToInsert,
        docsToUpdateMetadataFor,
      });
      // TODO: maybe we should queue upserts here so the rest of the code needn't worry about performance impact.
      await Promise.all([
        this._updateDocumentMetadata(docsToUpdateMetadataFor),
        this._updateDocumentContent(docsToUpdateContentFor),
        this._insertDocuments(docsToInsert),
      ]);
    }
    this.upsertLock.resolve();
    this.upsertLock = undefined;
    logger.timeEnd(`Upsert documents ${id}`);
  }

  async removeDocuments(docIds: string[]): Promise<void> {
    if (docIds.length === 0) {
      return;
    }
    await this.init();
    const id = ulid();
    logger.time(`Remove documents ${id}`);
    await this.db.execute(
      `
      DELETE
      FROM documents
      WHERE doc_id IN (${docIds.map(() => '?').join(', ')})
    `,
      docIds,
    );
    logger.timeEnd(`Remove documents ${id}`);
    logger.debug(`Remove documents ${id}`, { docIds });
  }

  async searchDocuments(searchQueryRaw: string, pageSize: number): Promise<ISearchResult> {
    const searchQuery = this._prepareSearchQuery(searchQueryRaw);
    if (searchQuery.length === 0) {
      return { rows: [], searchQuery, totalCount: 0, pageSize };
    }
    await this.init();
    const [rows, totalCount] = await Promise.all([
      this._findMatchingDocuments(searchQuery, pageSize, {
        rankInSubquery: ABSOLUTE_BEST_RANK,
        subqueryIndex: 0,
      }),
      this._countMatchingDocuments(searchQuery),
    ]);
    return { searchQuery, rows, totalCount, pageSize };
  }

  async loadMoreResultRows(result: ISearchResult): Promise<ISearchResult> {
    if (result.rows.length === 0) {
      return result;
    }
    await this.init();
    const { rankInSubquery, subqueryIndex } = result.rows[result.rows.length - 1];
    const id = ulid();
    logger.time(`Load more result rows ${id}`);
    const newRows = await this._findMatchingDocuments(
      result.searchQuery,
      result.pageSize,
      { rankInSubquery, subqueryIndex },
      result.rows.map((row) => row.doc_id),
    );
    // documents could've been removed or added from the search since, so we dedupe results on doc ID here.
    const existingDocIds = new Set(result.rows.map((row) => row.doc_id));
    const rowsToConcat = newRows.filter((row) => !existingDocIds.has(row.doc_id));
    const rows = result.rows.concat(rowsToConcat);
    logger.timeEnd(`Load more result rows ${id}`);
    logger.debug(`Load more result rows ${id}`, {
      searchQuery: result.searchQuery,
      pageSize: result.pageSize,
      rowsToConcat,
      rows,
      rankInSubquery,
      subqueryIndex,
    });
    return {
      ...result,
      rows,
    };
  }

  async destroy() {
    logger.debug('Destroying document search engine');
    await this.init();
    await this.deleteDatabase();
    await this.migrator?.resetMigrationState();
    this.db = this.createSqliteDatabase(SEARCH_DB_NAME);
    this.initializationPromise = undefined;
  }

  public async deleteDatabase() {
    logger.debug('Deleting SQLite database..');
    await this.db.delete();
    await this.db.save();
  }

  // Used only for testing
  async getAllDocuments(): Promise<ISearchDocumentRow[]> {
    await this.db.init();
    return this.db.select<ISearchDocumentRow>(`
      SELECT *
      FROM documents
    `);
  }

  public async prepareDatabase() {
    logger.debug('Preparing database..');
    await this.db.init();
    const { url, title, author, content, tags, notes, summary } = SEARCH_FIELD_WEIGHT;
    const initStatements = [
      'BEGIN;',
      `CREATE VIRTUAL TABLE IF NOT EXISTS documents USING fts5(
          parsed_doc_id unindexed,
          doc_id unindexed,
          url,
          title,
          author,
          content,
          tags,
          notes,
          summary,
          tokenize="porter unicode61 tokenchars '%$#@'",
          prefix='2 3',
        );`,
      `INSERT INTO documents(documents, rank)
       VALUES ('automerge', 2);`,
      `INSERT INTO documents(documents, rank)
       VALUES ('crisismerge', 8);`,
      `INSERT INTO documents(documents, rank)
       VALUES ('rank',
               'bm25(0, 0, ${url}, ${title}, ${author}, ${content}, ${tags}, ${notes}, ${summary})');`,

      `CREATE TABLE IF NOT EXISTS presence
       (
         doc_id      TEXT PRIMARY KEY,
         has_content INTEGER
       );`,
      'COMMIT;',
    ];
    for (const statement of initStatements) {
      logger.debug('Executing init statement', { statement });
      await this.db.execute(statement);
    }
  }

  private _extractTextContent(html: string): string {
    const root = new RWDOMParser().parseFromString(html, 'text/html');
    // ensure inline epub styles don't show up in the text content
    if (!root || !root.documentElement) {
      return '';
    }
    root.documentElement
      .querySelectorAll?.('style')
      .forEach((styleElement: HTMLStyleElement) => styleElement.remove());
    return root.documentElement.textContent ?? '';
  }

  private _prepareDocumentsForSearch(documents: AnyDocumentWithContent[]): ISearchDocument[] {
    const searchDocuments = documents
      .filter(shouldIncludeDocumentInSearch)
      .map((doc) => this._transformDocumentForSearch(doc));
    // uniqBy() uses the first occurrence of a document, so to ensure the latest document is used we first reverse().
    searchDocuments.reverse();
    const uniqueSearchDocuments = uniqBy(searchDocuments, 'doc_id');
    uniqueSearchDocuments.reverse();
    return uniqueSearchDocuments;
  }

  private _transformDocumentForSearch(doc: DocumentWithParsedDocId): ISearchDocument {
    const overridableKeys: (keyof EditableKeys)[] = ['title', 'author', 'summary'];
    const [title, author, summary] = overridableKeys.map(
      (key) => getDocumentOverrideOrReal(doc, key) as string,
    );
    return {
      url: doc.url,
      doc_id: doc.id,
      // this should never be '' because we filtered for docs with parsed doc ID above
      parsed_doc_id: doc.parsed_doc_id?.toString() ?? '',
      title,
      author: author || null,
      summary: summary || null,
      tags: this._encodeTagsForSearchDB(doc.tags),
      notes: doc.notes ?? null,
      html_content: (doc as AnyDocumentWithContent).html_content ?? null,
      text_content: (doc as AnyDocumentWithContent).text_content ?? null,
    };
  }

  private _encodeTagsForSearchDB(tags: DocumentTagsObject | undefined): string | null {
    if (!tags) {
      return null;
    }
    const tagKeys = Object.keys(tags);
    if (tagKeys.length === 0) {
      return null;
    }
    // join tags by spaces because we're only looking for inexact matches, and order doesn't matter
    return tagKeys.join(' ');
  }

  private async _countMatchingDocuments(searchQuery: string[]): Promise<number> {
    await this.init();
    const totalCountQuery = `
      SELECT COUNT(1) AS totalCount
      FROM documents
      WHERE ${searchQuery.map(() => 'documents MATCH ?').join(' OR ')};
    `;
    const params = searchQuery;
    const id = ulid();
    logger.time(`Count matching documents ${id}`);
    const rows = await this.db.select<{ totalCount: number; }>(totalCountQuery, params);
    logger.timeEnd(`Count matching documents ${id}`);
    if (rows.length === 0) {
      exceptionHandler.captureException('could not count matching documents', {
        extra: {
          totalCountQuery,
          searchQuery,
          params,
        },
      });
      return 0;
    }
    const [{ totalCount }] = rows;
    logger.debug(`Count matching documents ${id}`, { searchQuery, totalCountQuery, params, totalCount });
    return totalCount;
  }

  private async _findMatchingDocuments(
    searchQuery: string[],
    limit: number,
    rankCutoff: ISearchRank,
    excludedDocIds: string[] = [],
  ): Promise<ISearchResultRow[]> {
    await this.init();
    const id = ulid();
    logger.time(`Find matching documents ${id}`);
    const resultSets = await Promise.all(
      searchQuery.map((subquery, subqueryIndex) => {
        if (subqueryIndex < rankCutoff.subqueryIndex) {
          return Promise.resolve([]);
        }
        const sqlRankCutoff =
          subqueryIndex === rankCutoff.subqueryIndex ? rankCutoff.rankInSubquery : ABSOLUTE_BEST_RANK;
        return this._executeDocumentSearch(subquery, limit, sqlRankCutoff, excludedDocIds);
      }),
    );
    const mergedRows = resultSets.flatMap((rows, subqueryIndex) =>
      rows.map((row) => ({
        ...row,
        subqueryIndex,
        subquery: searchQuery[subqueryIndex],
      })));
    const mergedRowsUniq = uniqBy(mergedRows, 'doc_id');
    const resultRows = mergedRowsUniq.slice(0, limit);
    logger.timeEnd(`Find matching documents ${id}`);
    logger.debug(`Find matching documents ${id}`, {
      searchQuery,
      resultSets,
      mergedRowsUniq,
      mergedRows,
      resultRows,
    });
    return resultRows;
  }

  private async _executeDocumentSearch(
    matchQuery: string,
    limit: number,
    rankCutoff: number,
    // prevent selecting docs which have already been selected in a previous iteration of this search.
    excludedDocIds: string[],
  ): Promise<ISearchResultRow[]> {
    const query = `
      SELECT rank                                                  as rankInSubquery,
             doc_id,
             parsed_doc_id,
             title,
             author,
             url,
             tags,
             notes,
             summary,
             snippet(documents, 3, '<mark>', '</mark>', '', 72)    AS title_match,
             snippet(documents, 4, '<mark>', '</mark>', '', 72)    AS author_match,
             snippet(documents, 5, '<mark>', '</mark>', '...', 24) AS content_match
      FROM documents
      WHERE documents MATCH ?
        AND rank > ?
        AND doc_id NOT IN (${excludedDocIds.map(() => '?').join(', ')})
      ORDER BY rank
      LIMIT ${limit}
      ;
    `;
    const params = [matchQuery, rankCutoff, excludedDocIds].flat();
    const id = ulid();
    logger.time(`_executeDocumentSearch ${id}`);
    const rows = await this.db.select<ISearchResultRow>(query, params);
    logger.timeEnd(`_executeDocumentSearch ${id}`);
    logger.debug(`_executeDocumentSearch ${id}`, {
      query,
      matchQuery,
      limit,
      rankCutoff,
      rows,
      excludedDocIds,
    });
    return rows;
  }

  private async _updateDocumentMetadata(documents: ISearchDocument[]): Promise<void> {
    if (documents.length === 0) {
      return;
    }
    await this.init();
    const id = ulid();
    logger.debug(`Update document metadata ${id}`, { documents });
    // Have to execute these updates individually because FTS tables don't allow proper upserts
    // Luckily this only happens on metadata update, which is rare
    logger.time(`Update document metadata ${id}`);
    await Promise.all(
      documents.map((doc) =>
        this.db.execute(
          `
        UPDATE documents
        SET url=?,
            title=?,
            author=?,
            tags=?,
            notes=?,
            summary=?
        WHERE doc_id = ?
      `,
          [
            doc.url,
            doc.title,
            doc.author,
            doc.tags,
            doc.notes,
            doc.summary,

            doc.doc_id,
          ],
        )),
    );
    logger.timeEnd(`Update document metadata ${id}`);
  }

  private async _updateDocumentContent(documents: ISearchDocument[]): Promise<void> {
    if (documents.length === 0) {
      return;
    }
    await this.init();
    const [documentsWithContent, documentsWithoutContent] = partition(documents, 'text_content');
    if (documentsWithoutContent.length > 0) {
      exceptionHandler.captureException('Some documents have no text content, omitting', {
        extra: {
          documentsWithoutContent,
        },
      });
    }
    // We batch content updates and add a delay after each iteration because doc content can be massive,
    // and otherwise we overload the DB (whether web worker or mobile sqlite).
    for (const docBatch of batchArray(documentsWithContent, 5)) {
      const query = `
        UPDATE documents
        SET content = CASE doc_id
          ${docBatch.map(() => 'WHEN ? THEN ?').join('\n')}
          END
        WHERE doc_id IN (${docBatch.map(() => '?').join(', ')})
      `;
      const queryCaseParams = docBatch.flatMap((doc) => [doc.doc_id, doc.text_content ?? '']);
      const docIds = docBatch.map((doc) => doc.doc_id);
      const params = [queryCaseParams, docIds].flat();
      const id = ulid();
      logger.debug(`Update document content batch ${id}`, { query, docBatch, params });
      logger.time(`Update document content batch ${id}`);
      await Promise.all([this.db.execute(query, params), this._upsertDocumentPresences(docBatch)]);
      logger.timeEnd(`Update document content batch ${id}`);
      await this.db.save();
      await delay(100);
    }
  }

  private async _insertDocuments(documents: ISearchDocument[]) {
    if (documents.length === 0) {
      return;
    }
    await this.init();
    const query = `
      INSERT INTO documents
      (doc_id,
       parsed_doc_id,
       url,
       title,
       author,
       content,
       tags,
       notes,
       summary)
      VALUES
      ${documents.map(() => '(?, ?, ?, ?, ?, ?, ?, ?, ?)').join(', ')}
    `;
    const params: SqliteValue[] = documents.flatMap((doc) => [
      doc.doc_id,
      doc.parsed_doc_id,

      doc.url,
      doc.title,
      doc.author,
      doc.text_content,
      doc.tags,
      doc.notes,
      doc.summary,
    ]);
    const id = ulid();
    logger.debug(`Insert documents ${id}`, { documents, query, params });
    logger.time(`Insert documents ${id}`);
    await Promise.all([this.db.execute(query, params), this._upsertDocumentPresences(documents)]);
    await this.db.save();
    logger.timeEnd(`Insert documents ${id}`);
  }

  private async _upsertDocumentPresences(documents: ISearchDocument[]) {
    if (documents.length === 0) {
      return;
    }
    await this.init();
    const query = `
      INSERT INTO presence (doc_id, has_content)
      VALUES
      ${documents.map(() => '(?, ?)').join(', ')}
      ON CONFLICT(doc_id)
      DO UPDATE SET has_content=excluded.has_content
    `;
    const params = documents.flatMap((doc) => [doc.doc_id, doc.text_content ? 1 : 0]);
    await this.db.execute(query, params);
  }

  /**
   * Prepares the search query by normalizing and transforming it into multiple subqueries.
   *
   * NOTE the order of returned subqueries matters: results from subquery N get ranked above those of subquery N+1.
   *
   * @param {string} searchQueryRaw - The raw search query to be prepared.
   * @returns {string[]} - An array of subqueries prepared from the search query.
   * @private
   */
  private _prepareSearchQuery(searchQueryRaw: string): string[] {
    const searchQueryNormalQuotes = searchQueryRaw.toLowerCase().replaceAll(/[“”«»]/g, '"');
    const rawTerms = searchQueryNormalQuotes.match(/[^\s"]+|"[^"]+"/g);
    if (!rawTerms) {
      return [];
    }
    const terms = rawTerms
      .map((term) => term.startsWith('"') && term.endsWith('"') ? term.slice(1, -1) : term)
      .map((term) => term.trim())
      .filter((term) => term.length >= 2);
    if (terms.length === 0) {
      return [];
    }

    const quotedTerms = terms
      // make search query safe for SQL by surrounding terms with quotes
      .map((term) => `"${term}"`);
    const inexactQuery = quotedTerms.join(' ');
    const exactQuery = `"${terms.join(' ')}"`;
    const prefixQuery = `${exactQuery}*`;
    const subqueries = [exactQuery, prefixQuery, inexactQuery];
    return uniq(subqueries);
  }
}
