// eslint-disable-next-line import/no-cycle
import { createSqliteDatabase } from '../sqliteDatabase';
import { DocumentSearchEngine } from './documentSearchEngine.platformIncludingExtension';
// eslint-disable-next-line import/no-cycle
import { SchemaMigrator } from './schemaMigrator';

export const documentSearchEngine = new DocumentSearchEngine(createSqliteDatabase, new SchemaMigrator());

export type * from './documentSearchEngine';
export { DocumentSearchEngine } from './documentSearchEngine.platformIncludingExtension';
export * from './constants';
