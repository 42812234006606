import type { MaybePromise } from './typescriptUtils';

/**
 * Taken from https://stackoverflow.com/a/41956372 and modified.
 * Return 0 <= i <= array.length such that !pred(array[i - 1]) && pred(array[i]).
 */
export default async function binarySearch<T>(
  array: T[],
  predicate: (item: T) => MaybePromise<boolean>,
): Promise<T | undefined> {
  let low = -1;
  let high = array.length;
  while (1 + low < high) {
    const middle = low + (high - low >> 1);
    if (await predicate(array[middle])) {
      high = middle;
    } else {
      low = middle;
    }
  }
  return array[high];
}
