import type { ClientState, FirstClassDocument, PartialDocument } from '../types';
import { isDocumentWithPagination } from '../typeValidators';
import { globalState } from './models';

export default function checkIfDocumentIsInPaginatedMode(
  doc: PartialDocument<FirstClassDocument, 'category' | 'url' | 'isPaginatedMode'> | null,
  paginationOnByDefaultList: ClientState['readerSettings']['mobile']['paginationOnByDefaultList'] = globalState.getState()
    .client.readerSettings.mobile.paginationOnByDefaultList,
): boolean {
  if (!doc || !isDocumentWithPagination(doc)) {
    return false;
  }
  if (doc.isPaginatedMode !== undefined) {
    return doc.isPaginatedMode;
  }
  return paginationOnByDefaultList.includes(doc.category);
}
