export const getEpubStyles = (isTextJustified: boolean) => {
  const justifiedTextHyphenStyles = isTextJustified
    ? `
    hyphenate-limit-chars: 2 2 2;
    -webkit-hyphens: auto;
    -webkit-hyphenate-limit-before: 2;
    -webkit-hyphenate-limit-after: 2;
    -webkit-hyphenate-limit-chars: 2 2 2;
    -webkit-hyphenate-limit-lines: 4;
    -webkit-hyphenate-limit-last: always;
    -webkit-hyphenate-limit-zone: 8%;
    hyphens: auto;
    hyphenate-limit-chars: 2 2 2;
    hyphenate-limit-lines: 4;
    hyphenate-limit-last: always;
    hyphenate-limit-zone: 8%;
  `
    : '';

  return `<style>
  .epub-original-styles {
    ${justifiedTextHyphenStyles}
  }
</style>
`;
};
