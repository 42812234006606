import { Event } from '@sentry/browser';

export default (event: Event, extensionId?: string): boolean => {
  // Allow Sentry.captureMessage();
  if (event.level && ['log', 'info', 'warn'].includes(event.level)) {
    return true;
  }

  const frames =
    event.exception &&
      event.exception.values &&
      event.exception.values[0] &&
      event.exception.values[0].stacktrace &&
      event.exception.values[0].stacktrace.frames ||
    [];
  let filenameRegexStr = '^@?[a-z-]+-extension://';
  if (extensionId) {
    if (!extensionId.includes('@')) {
      filenameRegexStr += extensionId.replace(/[{}]/g, '');
    }
  } else {

    /*
      Another pattern for Safari extensions (on top of safari-web-extension://). I think this one might be due to a
      Safari bug. E.g:
      https://readwise.sentry.io/issues/5203542833/?alert_rule_id=6506214&alert_type=issue&notification_uuid=387e16b7-b248-4938-aa16-7eb7cb3f3a03&project=5795514&referrer=slack
    */
    filenameRegexStr = `(${filenameRegexStr}|@webkit-masked-url)`;
  }

  const filenameRegex = new RegExp(filenameRegexStr, 'i');
  return frames.some((frame) => frame.filename && filenameRegex.test(frame.filename));
};
