// eslint-disable-next-line import/no-cycle
import { undoDocumentAction } from '../../foreground/stateUpdaters/transientStateUpdaters/undo';
import type { UserEventWithDataUpdate } from '../../types';
import getServerBaseUrl from '../../utils/getServerBaseUrl.platform';
import requestWithAuth from '../../utils/requestWithAuth.platformIncludingExtension';
import urlJoin from '../../utils/urlJoin';
import makeExtensionLogger from '../common/makeExtensionLogger';
import onRequestError from './onRequestError';

const logger = makeExtensionLogger(__filename);

export default async function sendStateUpdateToApi(event: UserEventWithDataUpdate): Promise<void> {
  logger.debug(event.name, { event });

  try {
    await requestWithAuth(urlJoin([getServerBaseUrl(), '/reader/api/state/update/']), {
      body: JSON.stringify({
        events: [event],
      }),
      method: 'POST',
    });
  } catch (error) {
    await onRequestError({
      action: 'update',
      additionalLogContext: { event },
      error,
    });
    undoDocumentAction(event.id, null);
    throw error;
  }
}
