import capitalize from 'lodash/capitalize';

import { isTextNode } from '../../typeValidators';
import type { RangyRange } from '../types/rangy';
import getTextNodeFromHighlightElement from './getTextNodeFromHighlightElement';
import isHighlightNode from './isHighlightNode';
import rangy from './rangy';

// NOTE: this mutates the input range
export default function contractRangeIfPossible({
  doc,
  endToMove = 'start',
  rangyRange,
}: {
  doc?: Document;
  endToMove: 'end' | 'start';
  rangyRange: RangyRange;
}): boolean {
  const otherRange = rangy.createRange(doc);
  const originals = {
    container: rangyRange[`${endToMove}Container`],
    offset: rangyRange[`${endToMove}Offset`],
  };

  if (rangyRange[`${endToMove}Offset`]) {
    let newOffset = rangyRange[`${endToMove}Offset`] + (endToMove === 'start' ? 1 : -1);
    let newContainer = rangyRange[`${endToMove}Container`];

    // Validate
    if (
      newOffset < 0 ||
      newOffset > (isTextNode(newContainer) ? newContainer.length : newContainer.childNodes.length) - 1
    ) {

      /*
        This used to give up at this point (e.g. if there were no more characters left in the text node)
        but there was an edge case where the selection wasn't trimmed when resizing highlights so it
        now goes to the closest sibling if it's a text node (very rare case) / highlight (in this case
        it uses the inner text node).
      */
      let didFindSiblingToContractInto = false;

      if (newContainer.parentElement) {
        // Inward
        const closestSibling = newContainer[`${endToMove === 'end' ? 'previous' : 'next'}Sibling`];
        if (closestSibling) {
          let closestSiblingTextNode: Text | undefined;
          if (isTextNode(closestSibling)) {
            closestSiblingTextNode = closestSibling;
          } else if (isHighlightNode(closestSibling)) {
            const highlightTextNode = getTextNodeFromHighlightElement(closestSibling);
            if (highlightTextNode) {
              closestSiblingTextNode = highlightTextNode;
            }
          }

          if (closestSiblingTextNode) {
            newContainer = closestSiblingTextNode;
            // Remember we're contracting, so moving inward
            newOffset = endToMove === 'end' ? closestSiblingTextNode.length - 1 : 0;

            if (newOffset >= 0) {
              didFindSiblingToContractInto = true;
            }
          }
        }
      }

      if (!didFindSiblingToContractInto) {
        return false;
      }
    }

    otherRange.setStart(newContainer, newOffset);
  } else {
    otherRange.setStartBefore(rangyRange[`${endToMove}Container`]);
  }

  otherRange.collapse(true);

  const oppositeEnd = endToMove === 'start' ? 'end' : 'start';
  rangyRange[`set${capitalize(endToMove)}`](
    otherRange[`${oppositeEnd}Container`],
    otherRange[`${oppositeEnd}Offset`],
  );

  return (
    !rangyRange[`${endToMove}Container`].isEqualNode(originals.container) ||
    rangyRange[`${endToMove}Offset`] !== originals.offset
  );
}
