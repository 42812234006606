import type { PointerLikeEvent } from '../../../types/browserEvents';
import type { CardinalDirection } from '../../../types/misc';
import cropBoundingClientRectToWindow from '../../utils/cropBoundingClientRectToWindow';
import getCoordinatesObjectFromPointerLikeEvent from '../../utils/getCoordinatesObjectFromPointerLikeEvent';

export default function shouldPointerPositionCauseScrollIfPossible({
  ancestor = document.documentElement,
  cardinalDirection,
  distanceFromEdgeToStartScrollingAt,
  event,
}: {
  ancestor?: HTMLElement;
  cardinalDirection: CardinalDirection;
  distanceFromEdgeToStartScrollingAt: number;
  event: PointerLikeEvent;
}): boolean {
  const coordinates = getCoordinatesObjectFromPointerLikeEvent(event);
  const ancestorRect = cropBoundingClientRectToWindow(ancestor.getBoundingClientRect());

  if (cardinalDirection === 'east') {
    return coordinates.clientX < ancestorRect.left + distanceFromEdgeToStartScrollingAt;
  } else if (cardinalDirection === 'north') {
    return coordinates.clientY < ancestorRect.top + distanceFromEdgeToStartScrollingAt;
  } else if (cardinalDirection === 'south') {
    return coordinates.clientY > ancestorRect.bottom - distanceFromEdgeToStartScrollingAt;
  }
  // Must be west
  return coordinates.clientY > ancestorRect.right - distanceFromEdgeToStartScrollingAt;
}
