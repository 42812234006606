import getRectIntersection from './getRectIntersection';

// Think of set difference
export default function getRectDifference(rectA: DOMRect, rectB: DOMRect): DOMRect[] {
  const intersection = getRectIntersection(rectA, rectB);
  if (!intersection) {
    return [rectA];
  }

  const differences: DOMRect[] = [];

  // Top segment
  if (intersection.top > rectA.top) {
    differences.push(new DOMRect(rectA.left, rectA.top, rectA.width, intersection.top - rectA.top));
  }

  // Left segment
  if (intersection.left > rectA.left) {
    differences.push(
      new DOMRect(rectA.left, intersection.top, intersection.left - rectA.left, intersection.height),
    );
  }

  // Right segment
  if (intersection.right < rectA.right) {
    differences.push(
      new DOMRect(
        intersection.right,
        intersection.top,
        rectA.right - intersection.right,
        intersection.height,
      ),
    );
  }

  // Bottom segment
  if (intersection.bottom < rectA.bottom) {
    differences.push(
      new DOMRect(rectA.left, intersection.bottom, rectA.width, rectA.bottom - intersection.bottom),
    );
  }

  return differences;
}
