import getRectDifference from './getRectDifference';
import sortRectsByTopLeft from './sortRectsByTopLeft';

/*
  The results will still cover the same areas but there will be no overlapping. Also:
  - The results will be ordered by top, left.
  - Rects might need to split into multiple. If so, the firstmost rect will be preserved and the other
    rect will be split. So the first input rect will always be preserved. If you would prefer a
    particular end of the input array to be more preserved, reverse the array and then pass it.
  - This isn't guaranteed to return the fewest amount of non-overlapping rects.
*/
export default function makeIntoNonOverlappingRects(rects: DOMRect[]): DOMRect[] {
  const results: DOMRect[] = [];
  const sortedRects = sortRectsByTopLeft(rects);

  for (const current of sortedRects) {
    // Remove smaller rects from the `current` rect which are already covered in `results`
    let rectsFromCurrentToKeep: DOMRect[] = [current];
    for (const previousResult of results) {
      // Think of set difference
      const differencesVersusPreviousResult: DOMRect[] = [];
      for (const rectFromCurrentToKeep of rectsFromCurrentToKeep) {
        differencesVersusPreviousResult.push(
          ...getRectDifference(rectFromCurrentToKeep, previousResult),
        );
      }
      rectsFromCurrentToKeep = differencesVersusPreviousResult;
    }
    results.push(...rectsFromCurrentToKeep);
  }

  return results;
}
