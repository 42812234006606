import get from 'lodash/get';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import type { MangoQuery, MangoQueryNoLimit, RxCollection, RxDocument, WithDeleted } from 'rxdb';

import type {
  DatabaseCollection,
  DatabaseCollectionCommonFindAndUpdateFunctionOptions,
  DatabaseCollectionCommonUpdateFunctionOptions,
  DatabaseCollectionNames,
  DatabaseCollectionNamesToDocType,
  DatabaseDocType,
  LastRxDBUpdate,
  RxDocumentWithoutOrmMethods,
} from '../../types/database';
import type {
  HandleStateUpdateSideEffectsParameter,
  HandleStateUpdateSideEffectsResult,
} from '../../types/stateUpdates';
import { isDevOrTest } from '../../utils/environment';
import getValuesFromMap from '../../utils/getValuesFromMap';
import type { MaybePromise } from '../../utils/typescriptUtils';
import getPrimaryKeyFromRxDocument from '../getPrimaryKeyFromRxDocument';
import sortDatabaseItemsByIdList from '../sortDatabaseItemsByIdList';
import cloneQueryInput from './cloneQueryInput';
import convertRxDocumentToOurJson, { convertRxDocumentsToOurJson } from './convertRxDocumentToOurJson';
import formatDatabaseUpdateQueryResult from './formatDatabaseUpdateQueryResult';
import handleValidationErrorsCleanly from './handleValidationErrorsCleanly';
// eslint-disable-next-line import/no-cycle
import handleWhenFindAndUpdateFunctionFoundNothing from './handleWhenFindAndUpdateFunctionFoundNothing';
import logger from './logger';
import optimizeMangoQuery from './optimizeMangoQuery';
// eslint-disable-next-line import/no-cycle
import performDatabaseUpdatesWithSideEffects from './performDatabaseUpdatesWithSideEffects';

function addLastRxDBUpdateToRxDocumentData<
  TItem extends Partial<DatabaseDocType | WithDeleted<DatabaseDocType>>,
  TLastRxDBUpdate extends LastRxDBUpdate | undefined,
  TResult extends TItem | (TItem & { rxdbOnly: { lastRxDBUpdate: TLastRxDBUpdate; }; }),
>(item: TItem, lastRxDBUpdate?: TLastRxDBUpdate): TResult {
  if (lastRxDBUpdate) {
    return {
      ...item,
      rxdbOnly: {
        lastRxDBUpdate,
      },
    } as TResult;
  }
  return item as TResult;
}

export default function getDatabaseCollectionFromRxCollection<
  TCollectionName extends DatabaseCollectionNames = DatabaseCollectionNames,
  TCollectionDocType extends
    DatabaseCollectionNamesToDocType[TCollectionName] = DatabaseCollectionNamesToDocType[TCollectionName],
>(
  rxCollection: RxCollection<TCollectionDocType>,
  handleStateUpdateSideEffects: (
    arg: HandleStateUpdateSideEffectsParameter,
  ) => HandleStateUpdateSideEffectsResult,
): DatabaseCollection<TCollectionName, TCollectionDocType> {
  const databaseCollection: DatabaseCollection<TCollectionName, TCollectionDocType> = {
    bulkInsert: async function bulkInsert(docsData, options) {
      const { shouldErrorIfSomeNotInserted, ...optionsForPerformUpdateQuieres } = options;

      return performDatabaseUpdatesWithSideEffects(
        {
          ...(optionsForPerformUpdateQuieres as DatabaseCollectionCommonUpdateFunctionOptions<TCollectionName>),
          handleStateUpdateSideEffects,
          idsOfItemsToBeDeleted: [],
          rxCollection,
        },
        async (lastRxDBUpdate) => {
          const queryResult = await handleValidationErrorsCleanly(() =>
            rxCollection.bulkInsert(
              docsData.map((docData) => addLastRxDBUpdateToRxDocumentData(docData, lastRxDBUpdate)),
            ));

          const rxDBWriteErrors = queryResult.error;
          if (rxDBWriteErrors.length) {
            const logArguments: Parameters<typeof logger.error> = [
              '[bulkInsert] some documents not inserted',
              { rxDBWriteErrors },
            ];

            // Defaults to true
            const shouldError =
              shouldErrorIfSomeNotInserted !== false ||
              isDevOrTest && rxDBWriteErrors.some((rxDBWriteError) => rxDBWriteError.status === 422);
            if (shouldError) {
              logger.error(...logArguments);
              throw new Error('bulkInsert: some documents not inserted');
            }
            logger.debug(...logArguments);
          }
          return { rxDBWriteErrors };
        },
      );
    },
    bulkUpsert: async function bulkUpsert(docsData, options) {
      return performDatabaseUpdatesWithSideEffects(
        {
          ...options,
          handleStateUpdateSideEffects,
          idsOfItemsToBeDeleted: [],
          rxCollection,
        },
        async (lastRxDBUpdate) => {
          await handleValidationErrorsCleanly(() =>
            rxCollection.bulkUpsert(
              docsData.map((docData) => addLastRxDBUpdateToRxDocumentData(docData, lastRxDBUpdate)),
            ));
        },
      );
    },
    count: async function count(query) {
      if (!query) {
        throw new Error('No parameters given');
      }
      const rxQuery = rxCollection.count(
        optimizeMangoQuery(rxCollection, omit(query, ['sort', 'limit', 'skip'])),
      );
      return rxQuery.exec();
    },
    countAll: async function countAll() {
      const rxQuery = rxCollection.count();
      return rxQuery.exec();
    },
    // eslint-disable-next-line func-name-matching
    delete: async function _delete(query, options) {
      if (!query) {
        throw new Error('No parameters given');
      }
      const rxDocuments = await rxCollection.find(optimizeMangoQuery(rxCollection, query)).exec();
      if (!rxDocuments.length) {
        logger.warn('delete query did not match any documents');
        return formatDatabaseUpdateQueryResult({ queryResult: undefined, userEvent: undefined });
      }
      const ids = rxDocuments.map((rxDocument) =>
        getPrimaryKeyFromRxDocument<TCollectionDocType>(rxDocument));
      return performDatabaseUpdatesWithSideEffects(
        {
          ...options,
          handleStateUpdateSideEffects,
          idsOfItemsToBeDeleted: ids,
          rxCollection,
        },
        async () => {
          await rxCollection.bulkRemove(ids);
        },
      );
    },
    deleteAll: async function deleteAll(options) {
      return performDatabaseUpdatesWithSideEffects(
        {
          ...options,
          handleStateUpdateSideEffects,
          idsOfItemsToBeDeleted: 'all',
          rxCollection,
        },
        async () => {
          const rxQuery = rxCollection.find();
          const rxDocuments = await rxQuery.exec();
          if (!rxDocuments.length) {
            return;
          }
          const ids = rxDocuments.map((rxDocument) =>
            getPrimaryKeyFromRxDocument<TCollectionDocType>(rxDocument));
          await rxCollection.bulkRemove(ids);
        },
      );
    },
    deleteByIds: async function deleteByIds(ids, options) {
      if (!ids.length) {
        return {
          queryResult: {
            rxDBWriteErrors: [],
          },
        };
      }

      return performDatabaseUpdatesWithSideEffects(
        {
          ...options,
          handleStateUpdateSideEffects,
          idsOfItemsToBeDeleted: ids,
          rxCollection,
        },
        async () => {
          const queryResult = await rxCollection.bulkRemove(cloneQueryInput(ids));
          return {
            rxDBWriteErrors: queryResult.error,
          };
        },
      );
    },
    find: async function find<TDocType extends TCollectionDocType>(
      query: MangoQuery<TCollectionDocType>,
    ) {
      if (!query) {
        throw new Error('No parameters given');
      }
      const rxQuery = rxCollection.find(optimizeMangoQuery(rxCollection, query));
      const rxDocuments = await rxQuery.exec();
      return convertRxDocumentsToOurJson<TDocType>(rxCollection, rxDocuments);
    },
    clearDeleted: async function clearDeleted() {
      await rxCollection.cleanup(0);
    },
    findAll: async function findAll() {
      const rxQuery = rxCollection.find();
      const rxDocuments = await rxQuery.exec();
      return convertRxDocumentsToOurJson(rxCollection, rxDocuments);
    },
    findAllIds: async function findAllIds() {
      const rxQuery = rxCollection.find();
      const rxDocuments = await rxQuery.exec();
      return rxDocuments.map(getPrimaryKeyFromRxDocument);
    },
    findAndIncrementalModify: async function findAndIncrementalModify<
      TDocTypeBeforeUpdate extends TCollectionDocType,
    >(
      query: MangoQuery<TCollectionDocType>,
      mutationFunction: Parameters<RxDocument<TDocTypeBeforeUpdate>['incrementalModify']>[0],
      options: DatabaseCollectionCommonUpdateFunctionOptions<TCollectionName> &
        DatabaseCollectionCommonFindAndUpdateFunctionOptions,
    ) {
      const { errorMessageIfNothingFound, logLevelIfNothingFound, ...otherOptions } = options;

      return performDatabaseUpdatesWithSideEffects(
        {
          ...otherOptions,
          handleStateUpdateSideEffects,
          idsOfItemsToBeDeleted: [],
          rxCollection,
        },
        async (lastRxDBUpdate) => {
          const rxQuery = rxCollection.find(optimizeMangoQuery(rxCollection, query));
          const rxDocuments: RxDocumentWithoutOrmMethods<TDocTypeBeforeUpdate>[] = await rxQuery.exec();

          if (!rxDocuments.length) {
            handleWhenFindAndUpdateFunctionFoundNothing({
              errorMessageIfNothingFound,
              extraLogInfo: {
                mutationFunction,
                options,
                query,
              },
              logLevelIfNothingFound,
              methodName: 'findAndIncrementalModify',
            });
          }

          return Promise.all(
            rxDocuments.map((rxDocument) =>
              handleValidationErrorsCleanly<RxDocument<TDocTypeBeforeUpdate>>(() =>
                rxDocument.incrementalModify(async (docData: WithDeleted<TDocTypeBeforeUpdate>) => {
                  const updatedDocData = await mutationFunction(docData);
                  return addLastRxDBUpdateToRxDocumentData(updatedDocData, lastRxDBUpdate);
                }))),
          );
        },
      );
    },
    findAndIncrementalPatch: async function findAndIncrementalPatch<
      TDocTypeBeforeUpdate extends TCollectionDocType,
    >(
      query: MangoQuery<TCollectionDocType>,
      patch: Partial<TDocTypeBeforeUpdate>,
      options: DatabaseCollectionCommonUpdateFunctionOptions<TCollectionName> &
        DatabaseCollectionCommonFindAndUpdateFunctionOptions,
    ) {
      const { errorMessageIfNothingFound, logLevelIfNothingFound, ...otherOptions } = options;

      return performDatabaseUpdatesWithSideEffects(
        {
          ...otherOptions,
          handleStateUpdateSideEffects,
          idsOfItemsToBeDeleted: [],
          rxCollection,
        },
        async (lastRxDBUpdate) => {
          const rxQuery = rxCollection.find(optimizeMangoQuery(rxCollection, query));
          const rxDocuments: RxDocumentWithoutOrmMethods<TDocTypeBeforeUpdate>[] = await rxQuery.exec();

          if (!rxDocuments.length) {
            handleWhenFindAndUpdateFunctionFoundNothing({
              errorMessageIfNothingFound,
              extraLogInfo: {
                options,
                patch,
                query,
              },
              logLevelIfNothingFound,
              methodName: 'findAndIncrementalPatch',
            });
          }

          const actualPatch = addLastRxDBUpdateToRxDocumentData(patch, lastRxDBUpdate);
          return Promise.all(
            rxDocuments.map((rxDocument) =>
              handleValidationErrorsCleanly<RxDocument<TDocTypeBeforeUpdate>>(() =>
                rxDocument.incrementalPatch(actualPatch))),
          );
        },
      );
    },
    // This preserves the order of (ids -> items)
    findByIds: async function findByIds<TDocType extends TCollectionDocType>(ids: string[]) {
      if (!ids.length) {
        return [];
      }
      if (ids.length === 1) {
        const rxQuery = rxCollection.findOne(ids[0]);
        const rxDocument = await rxQuery.exec();
        if (!rxDocument) {
          return [];
        }
        return convertRxDocumentsToOurJson<TDocType>(rxCollection, [rxDocument]);
      }

      const uniqueIds = uniq(ids);
      const rxQuery = rxCollection.findByIds(uniqueIds);
      const rxDocuments = getValuesFromMap(await rxQuery.exec());
      return sortDatabaseItemsByIdList<TDocType>({
        ids,
        items: convertRxDocumentsToOurJson(rxCollection, rxDocuments),
        rxCollection,
      });
    },
    // This is the closest we have to `UPDATE ... SET ... WHERE ...`
    // TODO: also add findAndBulkUpsert
    findByIdsAndBulkUpsert: async function findByIdsAndBulkUpsert<
      TDocTypeBeforeUpdate extends TCollectionDocType,
    >(
      ids: string[],
      mutationFunction: (documents: TDocTypeBeforeUpdate[]) => MaybePromise<TDocTypeBeforeUpdate[]>,
      options: {
        shouldWarnIfAnyMissing?: boolean;
      } & DatabaseCollectionCommonUpdateFunctionOptions<TCollectionName> &
        DatabaseCollectionCommonFindAndUpdateFunctionOptions,
    ) {
      const {
        errorMessageIfNothingFound,
        logLevelIfNothingFound,
        shouldWarnIfAnyMissing,
        ...otherOptions
      } = options;

      return performDatabaseUpdatesWithSideEffects(
        {
          ...otherOptions,
          handleStateUpdateSideEffects,
          idsOfItemsToBeDeleted: [],
          rxCollection,
        },
        async (lastRxDBUpdate) => {
          let didFindAny = false;

          if (ids.length) {
            const uniqueIds = uniq(ids);
            const rxQuery = rxCollection.findByIds(uniqueIds);
            const rxDocuments = getValuesFromMap(await rxQuery.exec());
            if (rxDocuments.length) {
              didFindAny = true;

              if (shouldWarnIfAnyMissing && isDevOrTest) {
                const idsFound = new Set(rxDocuments.map((rxDocument) => rxDocument.id));
                const idsMissing = uniqueIds.filter((id) => !idsFound.has(id));
                if (idsMissing.length) {
                  logger.warn('findByIdsAndBulkUpsert: some IDs not found', {
                    ids,
                    idsMissing,
                    mutationFunction,
                    options,
                  });
                }
              }

              const documentsData = convertRxDocumentsToOurJson<TDocTypeBeforeUpdate>(
                rxCollection,
                rxDocuments,
              );
              const updatedDocumentsData = (await mutationFunction(documentsData)).map(
                (updatedDocumentData) =>
                  addLastRxDBUpdateToRxDocumentData(updatedDocumentData, lastRxDBUpdate),
              );
              await handleValidationErrorsCleanly(() => rxCollection.bulkUpsert(updatedDocumentsData));
            }
          }

          if (!didFindAny) {
            handleWhenFindAndUpdateFunctionFoundNothing({
              errorMessageIfNothingFound,
              extraLogInfo: {
                ids,
                mutationFunction,
                options,
              },
              logLevelIfNothingFound,
              methodName: 'findByIdsAndBulkUpsert',
            });
          }
        },
      );
    },
    findByIdsAndIncrementalModify: async function findByIdsAndIncrementalModify<
      TDocTypeBeforeUpdate extends TCollectionDocType,
    >(
      ids: string[],
      mutationFunction: Parameters<RxDocument<TDocTypeBeforeUpdate>['incrementalModify']>[0],
      options: DatabaseCollectionCommonUpdateFunctionOptions<TCollectionName> &
        DatabaseCollectionCommonFindAndUpdateFunctionOptions,
    ) {
      const { errorMessageIfNothingFound, logLevelIfNothingFound, ...otherOptions } = options;

      return performDatabaseUpdatesWithSideEffects(
        {
          ...otherOptions,
          handleStateUpdateSideEffects,
          idsOfItemsToBeDeleted: [],
          rxCollection,
        },
        async (lastRxDBUpdate) => {
          let rxDocuments: RxDocumentWithoutOrmMethods<TDocTypeBeforeUpdate>[] = [];

          if (ids.length) {
            const uniqueIds = uniq(ids);
            const rxQuery = rxCollection.findByIds(uniqueIds);
            rxDocuments = getValuesFromMap(await rxQuery.exec());
          }

          if (!rxDocuments.length) {
            handleWhenFindAndUpdateFunctionFoundNothing({
              errorMessageIfNothingFound,
              extraLogInfo: {
                ids,
                mutationFunction,
                options,
              },
              logLevelIfNothingFound,
              methodName: 'findByIdsAndIncrementalModify',
            });
          }

          return Promise.all(
            rxDocuments.map((rxDocument) =>
              handleValidationErrorsCleanly<RxDocument<TDocTypeBeforeUpdate>>(() =>
                rxDocument.incrementalModify(async (docData: WithDeleted<TDocTypeBeforeUpdate>) => {
                  const updatedDocData = await mutationFunction(docData);
                  return addLastRxDBUpdateToRxDocumentData(updatedDocData, lastRxDBUpdate);
                }))),
          );
        },
      );
    },
    findByIdsAndIncrementalPatch: async function findByIdsAndIncrementalPatch<
      TDocTypeBeforeUpdate extends TCollectionDocType,
    >(
      ids: string[],
      patch: Partial<TDocTypeBeforeUpdate>,
      options: DatabaseCollectionCommonUpdateFunctionOptions<TCollectionName> &
        DatabaseCollectionCommonFindAndUpdateFunctionOptions,
    ) {
      const { errorMessageIfNothingFound, logLevelIfNothingFound, ...otherOptions } = options;

      return performDatabaseUpdatesWithSideEffects(
        {
          ...otherOptions,
          handleStateUpdateSideEffects,
          idsOfItemsToBeDeleted: [],
          rxCollection,
        },
        async (lastRxDBUpdate) => {
          let rxDocuments: RxDocumentWithoutOrmMethods<TDocTypeBeforeUpdate>[] = [];

          if (ids.length) {
            const uniqueIds = uniq(ids);
            const rxQuery = rxCollection.findByIds(uniqueIds);
            rxDocuments = getValuesFromMap(await rxQuery.exec());
          }

          if (!rxDocuments.length) {
            handleWhenFindAndUpdateFunctionFoundNothing({
              logLevelIfNothingFound,
              errorMessageIfNothingFound,
              extraLogInfo: {
                ids,
                patch,
                options,
              },
              methodName: 'findByIdsAndIncrementalPatch',
            });
          }

          const actualPatch = addLastRxDBUpdateToRxDocumentData(patch, lastRxDBUpdate);
          return Promise.all(
            rxDocuments.map((rxDocument) =>
              handleValidationErrorsCleanly<RxDocument<TDocTypeBeforeUpdate>>(() =>
                rxDocument.incrementalPatch(actualPatch))),
          );
        },
      );
    },
    findIds: async function findIds(query) {
      if (!query) {
        throw new Error('No parameters given');
      }
      const rxQuery = rxCollection.find(optimizeMangoQuery(rxCollection, query));
      const rxDocuments = await rxQuery.exec();
      return rxDocuments.map(getPrimaryKeyFromRxDocument);
    },
    findOne: async function findOne<TDocType extends TCollectionDocType>(
      query: MangoQueryNoLimit<TCollectionDocType> | string,
    ) {
      const rxQuery = rxCollection.findOne(
        typeof query === 'string' ? query : optimizeMangoQuery(rxCollection, query),
      );
      const rxDocument = await rxQuery.exec();
      if (rxDocument) {
        return convertRxDocumentToOurJson<TDocType>(rxCollection, rxDocument);
      }
      return null;
    },
    findOneAndIncrementalModify: async function findOneAndIncrementalModify<
      TDocTypeBeforeUpdate extends TCollectionDocType,
    >(
      query: Parameters<RxCollection<TCollectionDocType>['findOne']>[0],
      mutationFunction: Parameters<RxDocument<TDocTypeBeforeUpdate>['incrementalModify']>[0],
      options: DatabaseCollectionCommonUpdateFunctionOptions<TCollectionName> &
        DatabaseCollectionCommonFindAndUpdateFunctionOptions,
    ) {
      const { errorMessageIfNothingFound, logLevelIfNothingFound, ...otherOptions } = options;

      return performDatabaseUpdatesWithSideEffects(
        {
          ...otherOptions,
          handleStateUpdateSideEffects,
          idsOfItemsToBeDeleted: [],
          rxCollection,
        },
        async (lastRxDBUpdate) => {
          const rxQuery = rxCollection.findOne(
            typeof query === 'string' ? query : optimizeMangoQuery(rxCollection, query),
          );
          const rxDocument: RxDocumentWithoutOrmMethods<TDocTypeBeforeUpdate> = await rxQuery.exec();

          if (!rxDocument) {
            handleWhenFindAndUpdateFunctionFoundNothing({
              errorMessageIfNothingFound,
              extraLogInfo: {
                query,
                mutationFunction,
                options,
              },
              logLevelIfNothingFound,
              methodName: 'findOneAndIncrementalModify',
            });
            return null;
          }

          return handleValidationErrorsCleanly(() =>
            rxDocument.incrementalModify(async (docData: WithDeleted<TDocTypeBeforeUpdate>) => {
              const updatedDocData = await mutationFunction(docData);
              return addLastRxDBUpdateToRxDocumentData(updatedDocData, lastRxDBUpdate);
            }));
        },
      );
    },
    findOneAndIncrementalPatch: async function findOneAndIncrementalPatch<
      TDocTypeBeforeUpdate extends TCollectionDocType,
    >(
      query: Parameters<RxCollection<TCollectionDocType>['findOne']>[0],
      patch: Partial<TDocTypeBeforeUpdate>,
      options: DatabaseCollectionCommonUpdateFunctionOptions<TCollectionName> &
        DatabaseCollectionCommonFindAndUpdateFunctionOptions,
    ) {
      const { errorMessageIfNothingFound, logLevelIfNothingFound, ...otherOptions } = options;

      return performDatabaseUpdatesWithSideEffects(
        {
          ...otherOptions,
          handleStateUpdateSideEffects,
          idsOfItemsToBeDeleted: [],
          rxCollection,
        },
        async (lastRxDBUpdate) => {
          const rxQuery = rxCollection.findOne(
            typeof query === 'string' ? query : optimizeMangoQuery(rxCollection, query),
          );
          const rxDocument = await rxQuery.exec();

          if (!rxDocument) {
            handleWhenFindAndUpdateFunctionFoundNothing({
              logLevelIfNothingFound,
              errorMessageIfNothingFound,
              extraLogInfo: {
                query,
                patch,
                options,
              },
              methodName: 'findOneAndIncrementalPatch',
            });
            return null;
          }

          const actualPatch = addLastRxDBUpdateToRxDocumentData(patch, lastRxDBUpdate);
          return handleValidationErrorsCleanly(
            () =>
              rxDocument.incrementalPatch(
                actualPatch,
              ) as RxDocumentWithoutOrmMethods<TDocTypeBeforeUpdate>,
          );
        },
      );
    },
    incrementalUpsert: async function incrementalUpsert<TDocType extends TCollectionDocType>(
      data: Partial<TCollectionDocType>,
      options: DatabaseCollectionCommonUpdateFunctionOptions<TCollectionName>,
    ) {
      return performDatabaseUpdatesWithSideEffects(
        {
          ...options,
          handleStateUpdateSideEffects,
          idsOfItemsToBeDeleted: [],
          rxCollection,
        },
        async (lastRxDBUpdate) => {
          const rxDocument = await handleValidationErrorsCleanly(() =>
            rxCollection.incrementalUpsert(addLastRxDBUpdateToRxDocumentData(data, lastRxDBUpdate)));
          if (!rxDocument) {
            throw new Error('No rxDocument returned from .incrementalUpsert');
          }
          const result = convertRxDocumentToOurJson<TDocType>(rxCollection, rxDocument);
          if (!result) {
            throw new Error('Unexpectedly no result after conversion from rxDocument');
          }
          return result;
        },
      );
    },
    insert: async function insert<TDocType extends TCollectionDocType>(
      data: TCollectionDocType,
      options: DatabaseCollectionCommonUpdateFunctionOptions<TCollectionName>,
    ) {
      return performDatabaseUpdatesWithSideEffects(
        {
          ...options,
          handleStateUpdateSideEffects,
          idsOfItemsToBeDeleted: [],
          rxCollection,
        },
        async (lastRxDBUpdate) => {
          const rxDocument = await handleValidationErrorsCleanly(() =>
            rxCollection.insert(addLastRxDBUpdateToRxDocumentData(data, lastRxDBUpdate)));
          if (!rxDocument) {
            throw new Error('No rxDocument returned from .insert');
          }
          const result = convertRxDocumentToOurJson<TDocType>(rxCollection, rxDocument);
          if (!result) {
            throw new Error('Unexpectedly no result after conversion from rxDocument');
          }
          return result;
        },
      );
    },
    // You should probably use incrementalUpsert; see https://rxdb.info/rx-collection.html#incrementalUpsert
    upsert: async function upsert<TDocType extends TCollectionDocType>(
      data: TCollectionDocType,
      options: DatabaseCollectionCommonUpdateFunctionOptions<TCollectionName>,
    ) {
      return performDatabaseUpdatesWithSideEffects(
        {
          ...options,
          handleStateUpdateSideEffects,
          idsOfItemsToBeDeleted: [],
          rxCollection,
        },
        async (lastRxDBUpdate) => {
          const rxDocument = await handleValidationErrorsCleanly(() =>
            rxCollection.upsert(addLastRxDBUpdateToRxDocumentData(data, lastRxDBUpdate)));
          if (!rxDocument) {
            throw new Error('No rxDocument returned from .upsert');
          }
          const result = convertRxDocumentToOurJson<TDocType>(rxCollection, rxDocument);
          if (!result) {
            throw new Error('Unexpectedly no result after conversion from rxDocument');
          }
          return result;
        },
      );
    },
  };

  // Add logs and error handling
  const collectionsProxy = new Proxy(databaseCollection, {
    get: (target, propertyName) => {
      if (
        typeof propertyName !== 'string' ||
        !(propertyName in target) ||
        typeof target[propertyName] !== 'function'
      ) {
        if (propertyName === '$') {
          return rxCollection.$;
        }
        return;
      }

      return async function wrappedDatabaseCollectionFunc(...args: unknown[]) {
        let startLogMessage = `[${rxCollection.name}] [${propertyName}]`;

        if (
          args[0] &&
          ['find', 'findOne', 'incrementalUpsert', 'insert', 'upsert'].includes(propertyName)
        ) {
          if (typeof args[0] === 'string') {
            startLogMessage += ` ${args[0]}`;
          } else if (typeof args[0] === 'object' && propertyName.startsWith('find')) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const primaryKeyValue = JSON.stringify(
              get('selector' in args[0] ? args[0].selector : args[0], rxCollection.schema.primaryPath),
            );
            if (primaryKeyValue) {
              startLogMessage += ` ${primaryKeyValue}`;
            }
          }
        }

        logger.debug(startLogMessage, { args });

        const func = target[propertyName];
        let result: ReturnType<typeof func>;
        try {
          result = await func(...args);
        } catch (error) {
          logger.error(`[${rxCollection.name}] ${propertyName} threw error`, { error });
          throw error;
        }
        return result;
      };
    },
  });

  return collectionsProxy;
}
