/*
  This file is for state updaters related to the "Links" feature, not links / anchors in general.
*/

import isEqual from 'lodash/isEqual';

import type {
  DocumentId,
  DocumentLink,
  DocumentLinkSet,
  DocumentWithParsedDocId,
  DocumentWithUrl,
  StringOrUnknownString,
} from '../../../types';
import delay from '../../../utils/delay';
import getUrlDomain from '../../../utils/getUrlDomain';
import makeLogger from '../../../utils/makeLogger';
import { normalizeUrl } from '../../../utils/urls';
// eslint-disable-next-line import/no-cycle
import { findDocsForParsedDocIds } from '../../database/getters';
// eslint-disable-next-line import/no-cycle
import { CancelStateUpdate, globalState, updateState } from '../../models';
import background from '../../portalGates/toBackground';
import { getDocumentWithTransientData } from '../../stateGetters';

const logger = makeLogger(__filename);

async function reloadDocumentLinksUntilLoaded({ docId }: { docId: DocumentId; }) {
  for (let i = 0; i < 20; i++) {
    const state = globalState.getState();
    const documentWithTransientData = await getDocumentWithTransientData<DocumentWithParsedDocId>(
      docId,
      state,
    );
    if (!document) {
      logger.warn('User deleted document while reloading links, or document never existed', { docId });
      break;
    }
    const parsedDocId = documentWithTransientData?.parsed_doc_id?.toString();
    if (parsedDocId === undefined) {
      throw Error('document does not have a parsed_doc_id');
    }
    await loadDocumentLinks({ parsedDocIds: [parsedDocId], shouldBypassCache: true });
    const links = documentWithTransientData?.transientData.links;
    if (links === undefined) {
      // document or links don't exist, so nothing to wait for
      break;
    }
    const atLeastOneLinkLoading = links.some((link) => link.loading);
    if (!atLeastOneLinkLoading) {
      break;
    }
    await delay(1000);
  }
}

export const watchLinksReceivedFromServer = (
  ...args: Parameters<typeof background.watchLinksReceivedFromServer>
): ReturnType<typeof background.watchLinksReceivedFromServer> =>
  background.watchLinksReceivedFromServer(...args);

export async function loadDocumentLinks({
  parsedDocIds,
  shouldBypassCache,
}: {
  parsedDocIds: DocumentId[];
  shouldBypassCache: boolean;
}) {
  await background.loadDocumentLinksByIds(parsedDocIds, shouldBypassCache);
}

export async function setNewLinkLoading({
  docId,
  url,
  newDocId,
  userInteraction,
}: {
  docId: DocumentId;
  url: string;
  newDocId: DocumentId;
  userInteraction: StringOrUnknownString;
}) {
  // we might be in a freshly loaded document for which links haven't been fetched yet, so fetch them.
  const state = globalState.getState();
  const documentWithTransientData = await getDocumentWithTransientData<DocumentWithParsedDocId>(
    docId,
    state,
  );

  if (documentWithTransientData && documentWithTransientData?.transientData.links === undefined) {
    if (!documentWithTransientData.parsed_doc_id) {
      // cannot load links for doc without parsed doc id, so just give up
      return;
    }
    await loadDocumentLinks({
      parsedDocIds: [documentWithTransientData.parsed_doc_id.toString()],
      shouldBypassCache: true,
    });
  }
  const normalizedUrl = normalizeUrl(url);
  await updateState(
    (state) => {
      const links = state.transientDocumentsData[docId].links;
      if (!links) {
        throw new Error('no links???');
      }
      const linkIndex = links.findIndex((link) => normalizedUrl === normalizeUrl(link.url));
      if (linkIndex === -1) {
        logger.info('could not find link to set loading', {
          extra: {
            docUrl: (document as unknown as DocumentWithUrl).url,
            links,
            url,
            normalizedUrl,
            docId,
            newDocId,
          },
        });
        return;
      }
      const existingLink = links[linkIndex];
      if (existingLink.loading) {
        throw new CancelStateUpdate();
      }
      links[linkIndex] = {
        metadata: {
          title: '',
          author: '',
          summary: 'Loading..',
          domain: getUrlDomain(url),
          favicon_url: '',
          word_count: 0,
        },
        other_document: newDocId,
        loading: true,
        ...existingLink,
      };
    },
    {
      eventName: 'document-saved-from-link',
      userInteraction,
    },
  );
  reloadDocumentLinksUntilLoaded({ docId });
}

export async function updateDocumentLinks(linkSets: DocumentLinkSet[]): Promise<void> {
  const parsedDocIds = linkSets.map((linkSet) => linkSet.parsed_doc_id);
  const docsForParsedDocId = await findDocsForParsedDocIds(parsedDocIds);

  await updateState(
    (state) => {
      let stateChanged = false;
      for (const linkSet of linkSets) {
        const docs = docsForParsedDocId[linkSet.parsed_doc_id] ?? [];
        for (const doc of docs) {
          if (!state.transientDocumentsData[doc.id]) {
            logger.warn('document transient data does not exist, skipping', {
              docId: doc.id,
            });
            continue;
          }
          // Merge existing links that are marked 'loading' with new links from the server
          const loadingLinks: { [url: string]: DocumentLink; } = {};
          for (const existingLink of state.transientDocumentsData[doc.id].links ?? []) {
            if (existingLink.loading) {
              loadingLinks[existingLink.url] = existingLink;
            }
          }
          const updatedLinks = linkSet.links.map((newLink) => {
            if (newLink.other_document) {
              // new link is in my library, so always use it
              return newLink;
            }
            // new link is not in my library, so default to loading link if it exists
            if (loadingLinks[newLink.url]) {
              return loadingLinks[newLink.url];
            }
            return newLink;
          });
          if (isEqual(state.transientDocumentsData[doc.id].links, updatedLinks)) {
            continue;
          }
          state.transientDocumentsData[doc.id].links = updatedLinks;
          stateChanged = true;
        }
      }
      if (!stateChanged) {
        throw new CancelStateUpdate();
      }
    },
    {
      isUndoable: false,
      eventName: 'document-links-updated',
      userInteraction: null,
    },
  );
}
