import merge from 'lodash/merge';

import { Font, TshirtSize } from '../types';

export const FontSizeNumberDesktop = {
  [TshirtSize['5XS']]: 14,
  [TshirtSize['4XS']]: 15,
  [TshirtSize['3XS']]: 16,
  [TshirtSize['2XS']]: 17,
  [TshirtSize.XS]: 18,
  [TshirtSize.S]: 19,
  [TshirtSize.M]: 20,
  [TshirtSize.L]: 21,
  [TshirtSize.XL]: 22,
  [TshirtSize['2XL']]: 23,
  [TshirtSize['3XL']]: 24,
  [TshirtSize['4XL']]: 25,
  [TshirtSize['5XL']]: 26,
  [TshirtSize['6XL']]: 27,
  [TshirtSize['7XL']]: 28,
  [TshirtSize['8XL']]: 29,
  [TshirtSize['9XL']]: 30,
  [TshirtSize['10XL']]: 32,
  [TshirtSize['11XL']]: 34,
  [TshirtSize['12XL']]: 36,
  [TshirtSize['13XL']]: 38,
  [TshirtSize['14XL']]: 40,
  [TshirtSize['15XL']]: 44,
  [TshirtSize['16XL']]: 48,
  [TshirtSize['17XL']]: 52,
  [TshirtSize['18XL']]: 56,
  [TshirtSize['19XL']]: 60,
  [TshirtSize['20XL']]: 66,
  [TshirtSize['21XL']]: 74,
  [TshirtSize['22XL']]: 80,
};
export const FontSizeNumberMobile = {
  [TshirtSize['9XS']]: 12,
  [TshirtSize['8XS']]: 13,
  [TshirtSize['7XS']]: 14,
  [TshirtSize['6XS']]: 15,
  [TshirtSize['5XS']]: 16,
  [TshirtSize['4XS']]: 17,
  [TshirtSize['3XS']]: 18,
  [TshirtSize['2XS']]: 19,
  [TshirtSize.XS]: 20,
  [TshirtSize.S]: 21,
  [TshirtSize.M]: 22,
  [TshirtSize.L]: 23,
  [TshirtSize.XL]: 24,
  [TshirtSize['2XL']]: 25,
  [TshirtSize['3XL']]: 26,
  [TshirtSize['4XL']]: 27,
  [TshirtSize['5XL']]: 28,
  [TshirtSize['6XL']]: 29,
  [TshirtSize['7XL']]: 30,
  [TshirtSize['8XL']]: 32,
  [TshirtSize['9XL']]: 34,
  [TshirtSize['10XL']]: 36,
  [TshirtSize['11XL']]: 38,
  [TshirtSize['12XL']]: 40,
  [TshirtSize['13XL']]: 44,
  [TshirtSize['14XL']]: 48,
  [TshirtSize['15XL']]: 52,
  [TshirtSize['16XL']]: 56,
  [TshirtSize['17XL']]: 60,
  [TshirtSize['18XL']]: 66,
  [TshirtSize['19XL']]: 74,
  [TshirtSize['20XL']]: 80,
};
export const LineLengthNumberDesktop = {
  [TshirtSize.XS]: 504,
  [TshirtSize.S]: 576,
  [TshirtSize.M]: 648,
  [TshirtSize.L]: 720,
  [TshirtSize.XL]: 792,
};
// These are modifiers to the base margin, so M size is 20px (base) + 8px;
export const LineLengthNumberMobile = {
  [TshirtSize.XS]: 0,
  [TshirtSize.S]: 4,
  [TshirtSize.M]: 8,
  [TshirtSize.L]: 12,
  [TshirtSize.XL]: 16,
};
export const PageMarginMultiplierMobileTablet = {
  [TshirtSize.XS]: 4,
  [TshirtSize.S]: 3,
  [TshirtSize.M]: 2,
  [TshirtSize.L]: 1,
  [TshirtSize.XL]: 0,
};
export const LineHeightNumberDesktop = {
  [TshirtSize['4XS']]: 1.2,
  [TshirtSize['3XS']]: 1.3,
  [TshirtSize['2XS']]: 1.4,
  [TshirtSize.XS]: 1.5,
  [TshirtSize.S]: 1.6,
  [TshirtSize.M]: 1.7,
  [TshirtSize.L]: 1.8,
  [TshirtSize.XL]: 1.9,
  [TshirtSize['2XL']]: 2,
  [TshirtSize['3XL']]: 2.1,
  [TshirtSize['4XL']]: 2.2,
};
export const LineHeightNumberMobile = {
  [TshirtSize['4XS']]: 1.2,
  [TshirtSize['3XS']]: 1.3,
  [TshirtSize['2XS']]: 1.4,
  [TshirtSize.XS]: 1.5,
  [TshirtSize.S]: 1.6,
  [TshirtSize.M]: 1.7,
  [TshirtSize.L]: 1.8,
  [TshirtSize.XL]: 1.9,
  [TshirtSize['2XL']]: 2,
  [TshirtSize['3XL']]: 2.1,
  [TshirtSize['4XL']]: 2.2,
};

export function getNextTshirtSizeFromKeys({
  keys,
  currentSize,
  direction = 1,
}: { keys: string[]; currentSize: TshirtSize; direction?: number; }): TshirtSize {
  const currentIndex = keys.findIndex((key) => {
    return key === currentSize;
  });

  if (direction > 0) {
    const lastSize = keys[keys.length - 1];
    return (keys[currentIndex + 1] || lastSize) as TshirtSize;
  }

  const firstSize = keys[0];
  return (keys[currentIndex - 1] || firstSize) as TshirtSize;
}

export const MOBILE_DEFAULT_FONT = Font.SourceSerif;
export const MOBILE_FONTS_SERIF = {
  shared: {
    [Font.Literata]: 'Literata-Regular',
    [Font.Piazzolla]: 'Piazzolla-Regular',
    [Font.SourceSerif]: 'SourceSerif4Roman-DisplayRegular',
    [Font.Times]: 'Times new Roman',
  },
  ios: {},
  android: {},
};
export const MOBILE_FONTS_SANS_SERIF = {
  shared: {
    [Font.Inter]: 'Inter-Regular',
    [Font.IBMPlexSans]: 'IBMPlexSans-Regular',
    [Font.SourceSans]: 'SourceSans3-Regular',
    [Font.OpenDyslexic]: 'OpenDyslexic-Regular',
    [Font.AtkinsonHyperlegible]: 'AtkinsonHyperlegible-Regular',
    [Font.PublicSans]: 'PublicSansRoman-Regular',
    [Font.RobotoMono]: 'RobotoMono-Regular',
  },
  ios: {},
  android: {
    // For some reason this font isn't working on android correctly, this is only in the font selection sheet;
    [Font.AtkinsonHyperlegible]: 'default',
  },
};
export const MOBILE_FONTS = merge({}, MOBILE_FONTS_SERIF, MOBILE_FONTS_SANS_SERIF);

export const MOBILE_CSS_FONTS_SERIF = [Font.Literata, Font.Piazzolla, Font.SourceSerif, Font.Times];
export const MOBILE_CSS_FONTS_SANS_SERIF = [
  Font.AtkinsonHyperlegible,
  Font.Inter,
  Font.IBMPlexSans,
  Font.PublicSans,
  Font.SourceSans,
  Font.OpenDyslexic,
];

export const MOBILE_CSS_FONTS = {
  [Font.Times]: 'Times New Roman, serif',
  [Font.Inter]: "'Inter VF', sans-serif",
  [Font.AtkinsonHyperlegible]: "'Atkinson Hyperlegible', sans-serif",
  [Font.PublicSans]: "'Public Sans VF', Helvetica, sans-serif",
  [Font.Literata]: "'Literata VF', Georgia, Serif",
  [Font.IBMPlexSans]: "'IBM Plex Sans VF', sans-serif",
  [Font.Piazzolla]: "'Piazzolla VF', serif",
  [Font.SourceSans]: "'Source Sans VF', sans-serif",
  [Font.SourceSerif]: "'Source Serif VF', serif",
  [Font.OpenDyslexic]: 'OpenDyslexic',
};

export const WEB_CSS_FONTS = {
  [Font.Inter]: "'Inter VF', sans-serif",
  [Font.AtkinsonHyperlegible]: "'Atkinson Hyperlegible', sans-serif",
  [Font.PublicSans]: "'Public Sans VF', Helvetica, sans-serif",
  [Font.Literata]: "'Literata VF', Georgia, Serif",
  [Font.IBMPlexSans]: "'IBM Plex Sans VF', sans-serif",
  [Font.Piazzolla]: "'Piazzolla VF', serif",
  [Font.SourceSans]: "'Source Sans VF', sans-serif",
  [Font.SourceSerif]: "'Source Serif VF', serif",
  [Font.OpenDyslexic]: "'OpenDyslexic', sans-serif",
};

export const WEB_DEFAULT_FONT = Font.SourceSerif;
export const WEB_FONTS_SERIF = [Font.Literata, Font.Piazzolla, Font.SourceSerif];
export const WEB_FONTS_SANS_SERIF = [
  Font.AtkinsonHyperlegible,
  Font.Inter,
  Font.IBMPlexSans,
  Font.PublicSans,
  Font.SourceSans,
  Font.OpenDyslexic,
];
