import TauriSQL from '@tauri-apps/plugin-sql';
import type { RxDocumentData } from 'rxdb';

import { isDevOrTest } from '../../utils/environment';
import makeLogger from '../../utils/makeLogger';
import { type SQLiteQueryWithParams, getRxStorageSQLite, SQLiteBasics } from './storages/storage-sqlite';

const logger = makeLogger(__filename, { shouldLog: true });

type TauriSQLDataRow = { data: string; };
type TauriSQLCountRow = { count: number; };
type TauriSQLRxDBDocumentRow<T> = RxDocumentData<unknown> & { data: T; id: string; };
type TauriSQLQueryResult = { id: string; data: string; }[];

let transaction: SQLiteQueryWithParams[] | undefined;

const desktopSqliteBasics: SQLiteBasics<TauriSQL> = {
  journalMode: 'WAL2',

  async open(name: string): Promise<TauriSQL> {
    const path = `sqlite:${name}.sqlite`;
    logger.debug(`OPENING DB`, { name, path });
    const db = await TauriSQL.load(path);
    if (isDevOrTest) {
      // Attach DB to window for easy debugging
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).RxDBSqlite = db;
    }
    return db;
  },

  async all(
    db: TauriSQL,
    { query, params: _params, context }: SQLiteQueryWithParams,
  ): Promise<TauriSQLQueryResult> {
    const start = performance.now();
    let queryResult: TauriSQLQueryResult;
    const params = _params.map((param) => {
      if (typeof param === 'boolean') {
        return param ? 1 : 0;
      } else {
        return param;
      }
    });
    const rows = await db.select<TauriSQLCountRow[] | TauriSQLDataRow[]>(query, params);
    if (rows.length === 0) {
      queryResult = [];
    } else if ('count' in rows[0]) {
      // RxDB types often just straight up lie, so I'm forced to cast to `any` here. This is technically incorrect.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      queryResult = rows as TauriSQLCountRow[] as any;
    } else {
      const rxdbDocRows = (rows as TauriSQLDataRow[]).map(
        ({ data }) => JSON.parse(data) as TauriSQLRxDBDocumentRow<unknown>,
      );
      queryResult = rxdbDocRows.map((row) => ({ id: row.id, data: JSON.stringify(row) }));
    }
    const duration = performance.now() - start;
    logger.debug(`SELECT QUERY (${duration.toFixed()}ms): ${query}`, {
      query,
      queryResult,
      params,
      context,
    });
    return queryResult;
  },

  async run(db: TauriSQL, { query, params, context }: SQLiteQueryWithParams): Promise<void> {
    const executeQuery = async ({ query, params, context }: SQLiteQueryWithParams) => {
      const start = performance.now();
      try {
        await db.execute(query, params);
      } catch (error) {
        logger.error(`QUERY ERROR: ${query}`, { params, context, error });
        throw error;
      }
      const duration = performance.now() - start;
      logger.debug(`EXECUTED QUERY (${duration.toFixed()}ms): ${query}`, { query, params, context });
    };
    // NOTE: have to manually batch queries into a transaction because to prevent data race where transactions
    // were committed before they were started... probably something to do with the Rust async runtime (tokio).
    if (query === 'BEGIN;') {
      if (transaction) {
        logger.error('BEGIN WHILE TRANSACTION ALREADY ONGOING', { transaction });
        return;
      }
      logger.debug('BEGINNING TRANSACTION');
      transaction = [];
    } else if (query === 'COMMIT;') {
      if (!transaction) {
        logger.error('COMMIT WHILE NO TRANSACTION ONGOING');
        return;
      }
      logger.debug(`COMMITTING TRANSACTION`);
      const statements = transaction.map(({ query }) => `${query};`).join('\n');
      const transactionParams = transaction.map(({ params }) => params).flat();
      const transactionQuery = `
        BEGIN;
        ${statements}
        COMMIT;
      `;
      transaction = undefined;
      await executeQuery({ query: transactionQuery, params: transactionParams, context });
    } else if (transaction) {
      logger.debug(`ADDING QUERY TO TRANSACTION: ${query}`, { transaction, query, params });
      transaction.push({ query, params, context });
    } else {
      // Single query without ongoing transaction.
      await executeQuery({ query, params, context });
    }
  },

  async setPragma(db: TauriSQL, key: string, value: string): Promise<void> {
    const stmt = `PRAGMA ${key}=${value};`;
    logger.debug('PRAGMA', { key, value, stmt });
    await db.execute(stmt);
  },

  async close(_db: TauriSQL): Promise<void> {
    logger.debug('DB CLOSE');
    // DON'T CLOSE DB SINCE RXDB IS STILL GONNA WANNA OPEN IT TO CLEAR EVERYTHING.
  },
};

export function getDesktopSQLiteRxStorage() {
  return getRxStorageSQLite({
    sqliteBasics: desktopSqliteBasics,
    // log: logger.debug.bind(logger),
  });
}
