import type { CardinalDirection } from '../../types/misc';
import convertCardinalDirectionToAxis from '../../utils/convertCardinalDirectionToAxis';

// We were getting false positives on Android (at least) without this
function testScroll(element: HTMLElement, scrollPropertyName: 'scrollLeft' | 'scrollTop'): boolean {
  const originalValue = element[scrollPropertyName];
  element[scrollPropertyName] += 1;
  const canScroll = element[scrollPropertyName] !== originalValue;
  element[scrollPropertyName] = originalValue;
  return canScroll;
}

export default function isElementScrollable(
  element: HTMLElement,
  cardinalDirection?: CardinalDirection,
): boolean {
  const axes = cardinalDirection ? [convertCardinalDirectionToAxis(cardinalDirection)] : ['X', 'Y'];

  // Not sure why an extra pixel was needed in Chrome
  return (
    axes.includes('Y') &&
      element.scrollHeight > element.clientHeight + 1 &&
      testScroll(element, 'scrollTop') ||
    axes.includes('X') &&
      element.scrollWidth > element.clientWidth + 1 &&
      testScroll(element, 'scrollLeft')
  );
}
