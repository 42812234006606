export function parseSerializedPosition(serialized: string): { offset: number; nodeIndices: number[]; } {
  const parts = serialized.split(':');
  const nodeIndices = (parts[0] ? parts[0].split('/') : []).map((index) => parseInt(index, 10));
  const offset = parseInt(parts[1], 10);
  return { offset, nodeIndices };
}

export function unparseSerializedPosition(nodeIndices: number[], offset: number): string {
  return `${nodeIndices.join('/')}:${offset}`;
}

const deserializeRegex = /^([^,]+),([^,{]+)({([^}]+)})?$/;

export function splitSerializedRange(serialized: string): { end: string; start: string; } {
  const result = deserializeRegex.exec(serialized);
  if (!result) {
    throw new Error('Failed to parse serialized location');
  }
  return { start: result[1], end: result[2] };
}

export function extractTopLevelNodeIndex(serializedPosition: string): number | undefined {
  const { nodeIndices } = parseSerializedPosition(serializedPosition);
  return nodeIndices.pop();
}
