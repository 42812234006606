// eslint-disable-next-line import/no-named-default
import { default as getEndOfDay } from 'date-fns/endOfDay';
// eslint-disable-next-line import/no-named-default
import { default as getStartOfDay } from 'date-fns/startOfDay';
// eslint-disable-next-line import/no-named-default
import { default as getStartOfToday } from 'date-fns/startOfToday';
import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import subMonths from 'date-fns/subMonths';
import subWeeks from 'date-fns/subWeeks';
import subYears from 'date-fns/subYears';
import camelCase from 'lodash/camelCase';
import flatten from 'lodash/flatten';
import type { MangoQueryOperators, MangoQuerySelector } from 'rxdb';

import mangoQueryBuilders from '../database/mangoQueryBuilders';
import { makeDocsWithTagNameQuery, makeRegexSelector } from '../database/queryHelpers';
import {
  documentDoesntHaveHighlightsQuery,
  documentDoesntHaveNotesQuery,
  documentDoesntHaveTagsQuery,
  documentHasHighlightsQuery,
  documentHasNotesQuery,
  documentHasTagsQuery,
  isDocumentThatCanBeSharedQuery,
} from '../database/standardQueries';
import { AnyDocument, DocumentLocation } from '../types';
import createDate from '../utils/dates/createDate';
import { shortListTag } from '../utils/filteredViews';
import { LangCodeForLanguageName, SuffixesForLangCode } from './languages';
import { ExpressionNode, LogicalOperator, TypeOperator } from './types';

function addCamelCaseKey<T extends object>(obj: T): T {
  return Object.keys(obj).reduce((acc, key) => {
    if (key.includes('_')) {
      return {
        ...acc,
        [camelCase(key)]: obj[key],
        [key]: obj[key],
      };
    }

    return {
      ...acc,
      [key]: obj[key],
    };
  }, {} as T);
}

export const logicalOperatorMap = {
  [LogicalOperator.OR]: '$or',
  [LogicalOperator.AND]: '$and',
  [LogicalOperator.NOT]: '$nor',
};

const defaultTypeOperatorMap = {
  [TypeOperator.GreaterThan]: '$gt',
  [TypeOperator.LessThan]: '$lt',
  [TypeOperator.GreaterThanOrEqual]: '$gte',
  [TypeOperator.LessThanOrEqual]: '$lte',
  [TypeOperator.After]: '$gt',
  [TypeOperator.Before]: '$lt',
  [TypeOperator.Contains]: '$regex',
  [TypeOperator.Fuzzy]: '$regex',
  [TypeOperator.Exact]: '$eq',
  [TypeOperator.Not]: '$ne',
};

// Strings

const stringKeyMap = addCamelCaseKey({
  id: 'id',
  domain: 'url',
  url: 'url',
  category: 'category',
  type: 'category',
  saved_using: 'source',
  rss_source: 'source_specific_data.rss_feed',
  language: 'language',
  author: 'author',
  triage_status: 'triage_status',
  location: 'triage_status',
  in: 'triage_status',
  title: 'title',
});

export const stringTypeOperatorMap = {
  ...defaultTypeOperatorMap,
  [TypeOperator.Not]: '$not',
};

export const stringKeys = Object.keys(stringKeyMap);

export function convertStringSearchValue({
  key,
  value,
}: { key: string; value: string; }): MangoQueryOperators<string> | string {
  switch (key) {
    case 'language': {
      const language = value.toLowerCase();
      const langCode = LangCodeForLanguageName[language] ?? language; // hopefully ISO-639-1 2-letter language code
      const suffixes = SuffixesForLangCode[langCode] ?? [];
      const possibleValues = [langCode];
      for (const suffix of suffixes) {
        possibleValues.push(`${langCode}-${suffix}`);
        possibleValues.push(`${langCode}-${suffix.toUpperCase()}`);
      }

      return { $in: possibleValues };
    }

    case 'title':
      // In RxDB we save the title index as lowercase
      return value.substring(0, 350).toLowerCase();

    case 'author':
      // In RxDB we save the author index as lowercase
      return value.substring(0, 20).toLowerCase();

    // Make triage_status:inbox work too
    case 'triage_status':
    case 'triageStatus':
    case 'location':
    case 'in':
      return value === 'inbox' ? DocumentLocation.New : value;

    default:
      return value;
  }
}

export const stringKeysThatShouldDefaultExactMatch = new Set(
  flatten(
    ['id', 'category', 'type', 'source_specific_data.rss_feed', 'triage_status', 'in', 'location'].map(
      (s) => [s, camelCase(s)],
    ),
  ),
);

// Numbers

const numberKeyMap = addCamelCaseKey({
  words: 'word_count',
  minutes: 'minutes',
  progress: 'rxdbOnly.indexFields.readingPosition_scrollDepth',
  highlights: 'rxdbOnly.indexFields.childrenCount',
  saved_count: 'rxdbOnly.indexFields.savedCount',
});

export const numberTypeOperatorMap = {
  ...defaultTypeOperatorMap,
  [TypeOperator.Fuzzy]: '$eq',
};

export const numberKeys = Object.keys(numberKeyMap);

export const queryMakerForNumberKey = {
  minutes: (node: ExpressionNode) => {
    const minutesValue = Number(node.value);
    const secondsValue = minutesValue * 60;
    const operator = numberTypeOperatorMap[node.operator];

    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'rxdbOnly.indexFields.length_in_seconds': {
        [operator]: secondsValue,
      },
    };
  },
};

// Arrays

const arrayKeyMap = addCamelCaseKey({
  tag: 'tags',
});

export const arrayTypeOperatorMap = {
  ...defaultTypeOperatorMap,
};

export const arrayKeys = Object.keys(arrayKeyMap);

export const queryFromArrayNodeMap: {
  [key: string]: (node: ExpressionNode) => MangoQuerySelector<AnyDocument>;
} = {
  tag: (node: ExpressionNode) => {
    const tagNameKey = node.value.toLocaleLowerCase().trim();
    const existsValue = node.operator === TypeOperator.Not ? 0 : 1;
    const isShortlistTag = tagNameKey === shortListTag;

    if (isShortlistTag) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      return { 'rxdbOnly.indexFields.hasShortlistTag': existsValue };
    }

    if (node.operator === TypeOperator.Contains) {
      return {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'rxdbOnly.allTagsJoined': makeRegexSelector(tagNameKey),
      };
    }

    return makeDocsWithTagNameQuery(tagNameKey, existsValue).selector;
  },
};

// Boolean

const booleanKeyMap = addCamelCaseKey({
  feed: 'feed',
  opened: 'opened',
  open: 'open',
  seen: 'seen',
  unseen: 'unseen',
  has_highlights: 'has_highlights',
  has_tags: 'has_tags',
  has_notes: 'has_notes',
  has_note: 'has_note',
  shared: 'shared',
  has: 'has',
  started_reading: 'started_reading',
  trash: 'trash',
});

export const booleanKeys = Object.keys(booleanKeyMap);

export const getQueryFromBooleanKey = (
  node: ExpressionNode,
): MangoQuerySelector<AnyDocument> | undefined => {
  const value = Boolean(node.value === 'true');

  switch (node.key) {
    case 'feed':
      if (value) {
        return {
          triage_status: DocumentLocation.Feed,
        };
      }

      return {
        triage_status: { $ne: DocumentLocation.Feed },
      };
    case 'opened':
    case 'open':
    case 'seen':
      return {
        firstOpenedAt: { $exists: value },
      };
    case 'unseen':
      return {
        firstOpenedAt: { $exists: !value },
      };
    case 'has_highlights':
    case 'hasHighlights':
      if (value) {
        return documentHasHighlightsQuery;
      }
      return documentDoesntHaveHighlightsQuery;
    case 'has_tags':
    case 'hasTags':
      if (value) {
        return documentHasTagsQuery;
      }
      return documentDoesntHaveTagsQuery;
    case 'has_notes':
    case 'hasNotes':
    case 'has_note':
    case 'hasNote':
      if (value) {
        return documentHasNotesQuery;
      }
      return documentDoesntHaveNotesQuery;
    case 'shared':
      return mangoQueryBuilders.$and([
        isDocumentThatCanBeSharedQuery,
        {
          sharedAt: { $exists: value },
        },
      ]);
    case 'has': {
      const isNotOperator = node.operator === TypeOperator.Not;

      switch (node.value) {
        case 'highlights':
          if (isNotOperator) {
            return documentDoesntHaveHighlightsQuery;
          }
          return documentHasHighlightsQuery;
        case 'tags':
          if (isNotOperator) {
            return documentDoesntHaveTagsQuery;
          }
          return documentHasTagsQuery;
        case 'notes':
        case 'note':
          if (isNotOperator) {
            return documentDoesntHaveNotesQuery;
          }
          return documentHasNotesQuery;
        default:
          return undefined;
      }
    }
    case 'started_reading':
    case 'startedReading':
      // eslint-disable-next-line @typescript-eslint/naming-convention
      return { 'rxdbOnly.indexFields.startedReading': value ? 1 : 0 };
    case 'trash':
      return {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _deleted: { $eq: value },
      };
    default:
      return undefined;
  }
};

// Date

const dateKeyMap = addCamelCaseKey({
  last_status: 'last_status_update',
  moved: 'last_status_update',
  saved: 'saved_at',
  last_opened: 'rxdbOnly.indexFields.lastOpenedAt',
  // published: (d: AnyDocument) => isDocumentWithPublishedDate(d) ? d.published_date : undefined,
  published: 'published_date',
});

export const dateKeys = Object.keys(dateKeyMap);

export const getDateTypeQueryFromKey = ({
  expectedDate,
  operator,
}: { expectedDate: number; operator: TypeOperator; }) => {
  switch (operator) {
    case TypeOperator.After:
    case TypeOperator.GreaterThan:
      return {
        $gt: expectedDate,
      };

    case TypeOperator.Before:
    case TypeOperator.LessThan:
      return {
        $lt: expectedDate,
      };

    case TypeOperator.GreaterThanOrEqual:
      return {
        $gte: expectedDate,
      };

    case TypeOperator.LessThanOrEqual:
      return {
        $lte: expectedDate,
      };

    case TypeOperator.Fuzzy:
    case TypeOperator.Exact:
      return {
        $gte: expectedDate,
        $lte: getEndOfDay(expectedDate).getTime(),
      };

    case TypeOperator.Not:
      return {
        $not: {
          $gte: getStartOfDay(expectedDate).getTime(),
          $lt: getEndOfDay(expectedDate).getTime(),
        },
      };
  }
};

const someWordNumbersToNumber = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  nine: 9,
  ten: 10,
  eleven: 11,
  twelve: 12,
  thirteen: 13,
  fourteen: 14,
  fiftheen: 15,
  sixteen: 16,
  seventeen: 17,
  eighteen: 18,
  nineteen: 19,
  twenty: 20,
};

export const getValueFromDateKey = (node: ExpressionNode) => {
  const value = node.value;
  const operator = node.operator;

  // E.g '2022-03-28'
  if (value.includes('-')) {
    const dateValueRegex = /(19|20)\d\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/;

    if (!dateValueRegex.test(value)) {
      throw new Error('Invalid date value');
    }

    let time = '00:00';

    if (
      operator === TypeOperator.LessThan ||
      operator === TypeOperator.LessThanOrEqual ||
      operator === TypeOperator.Before
    ) {
      time = '23:59:59';
    }

    return createDate(`${value} ${time}`).getTime();
  }

  const [amountFromUser, unit] = value.split(' ');
  const lowerCasedAmount = amountFromUser.toLowerCase();
  const amount = someWordNumbersToNumber[lowerCasedAmount] || lowerCasedAmount;

  const startOfToday = getStartOfToday();

  if (amount === 'today') {
    return startOfToday.getTime();
  }

  if (amount === 'yesterday') {
    return subDays(startOfToday, 1).getTime();
  }

  // E.g `last month` or `4 days ago`
  const isLast = amount === 'last';
  const numberAmount = isLast ? 1 : Number(amount);

  if (!numberAmount) {
    throw new Error('Invalid time value');
  }

  switch (unit.toLowerCase()) {
    case 'years':
    case 'year':
      return subYears(startOfToday, numberAmount).getTime();

    case 'months':
    case 'month':
      return subMonths(startOfToday, numberAmount).getTime();

    case 'weeks':
    case 'week':
      return subWeeks(startOfToday, numberAmount).getTime();

    case 'days':
    case 'day':
      return subDays(startOfToday, numberAmount).getTime();

    case 'hours':
    case 'hour':
      return subHours(new Date(), numberAmount).getTime();

    default:
      throw new Error('Invalid time value');
  }
};

export const keyMap = {
  ...stringKeyMap,
  ...numberKeyMap,
  ...arrayKeyMap,
  ...booleanKeyMap,
  ...dateKeyMap,
};

export const allKeys = Object.keys(keyMap);
