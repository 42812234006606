import type { CardinalDirection } from '../../types/misc';
import isElementScrollable from './isElementScrollable';

export default function getScrollDistanceRemainingInCardinalDirection(
  element: HTMLElement,
  cardinalDirection: CardinalDirection,
): number {
  if (!isElementScrollable(element, cardinalDirection)) {
    return 0;
  }
  if (cardinalDirection === 'east') {
    return element.scrollWidth - element.clientWidth - element.scrollLeft;
  }
  if (cardinalDirection === 'north') {
    return element.scrollTop;
  }
  if (cardinalDirection === 'south') {
    return element.scrollHeight - element.clientHeight - element.scrollTop;
  }
  // The direction must be west...
  return element.scrollLeft;
}
