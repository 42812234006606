import { Token, TokenType } from './types';

export const getQueryFromTokens = (tokens: Token[]) => {
  return tokens.reduce((acc, token) => {
    if (token.type === TokenType.LogicOperator) {
      return `${acc} ${token.value} `;
    }

    return `${acc}${token.value}`;
  }, '');
};
