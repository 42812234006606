import getHtmlFromRange from './getHtmlFromRange';

export default (selection = window.getSelection()) => {
  if (!selection || !selection.rangeCount) {
    return '';
  }
  const range = selection.getRangeAt(0);

  if (range.collapsed) {
    return '';
  }
  return getHtmlFromRange(range);
};
