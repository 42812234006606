import { Theme } from '../../../css/baseStyles';
import getWebviewHorizontalMarginCss from '../../../utils/getWebviewHorizontalMarginCss';

export const getDigestStyles = (horizontalMargin: number, isTablet: boolean) => {
  return `
    <style>
      .document-content img {
        display: none!important;
      }
      .document-content figure {
        display: none!important;
      }
      .header-secondary-image-container {
        min-height: 0px;
        max-height: 140px;
      }
      ${getWebviewHorizontalMarginCss(horizontalMargin, isTablet, -4)}
    </style>
  `;
};

export const getPDFPreviewStyles = (theme: Theme) => `
<style>
  .pdf-container {
    background: ${theme.pdfDigestBackground};
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }
  .pdf-container .header-content h1 {
    font-weight: 700!important;
    font-size: 48px!important;
    line-height: 100%!important;
    letter-spacing: -0.015em!important;
    -webkit-hyphens: auto;
    -webkit-hyphenate-limit-chars: 10 3 3;
    -webkit-hyphenate-limit-before: 10;
  }

  .overflow-hidden-enforcer {
    width: 100%;
  }

  .header-secondary-image-container {
    display: none;
  }

  .header-container {
    background: none;
    width: 100%;
  }

  .document-container {
    display: none;
  }
  .pdf-fold {
    position: fixed;
    top: -9px;
    width: 56px;
    height: 56px;
    right: -10px;
    background: linear-gradient(180deg, #D2D6DB 24.07%, #FFFFFF 134.26%);
    box-shadow: 0px 29px 60px rgba(11, 20, 34, 0.08), 0px 18.7963px 35.1389px rgba(11, 20, 34, 0.0607407), 0px 11.1704px 19.1111px rgba(11, 20, 34, 0.0485926), 0px 5.8px 9.75px rgba(11, 20, 34, 0.04), 0px 2.36296px 4.88889px rgba(11, 20, 34, 0.0314074), 0px 0.537037px 2.36111px rgba(11, 20, 34, 0.0192593);
    border-bottom-left-radius: 16px;
  }

  .pdf-fold-black {
    width: 78px;
    height: 69px;
    background: black;
    position: absolute;
    transform: rotateZ(45deg);
    top: -23px;
    left: 11px;
  }

  .theme--dark .pdf-fold {
    background: linear-gradient(180deg, rgba(30, 39, 47, 0.2) 0%, rgba(136, 146, 156, 0.2) 100%), #555F6B;
    box-shadow: 0px 29px 60px rgba(11, 20, 34, 0.08), 0px 18.7963px 35.1389px rgba(11, 20, 34, 0.0607407), 0px 11.1704px 19.1111px rgba(11, 20, 34, 0.0485926), 0px 5.8px 9.75px rgba(11, 20, 34, 0.04), 0px 2.36296px 4.88889px rgba(11, 20, 34, 0.0314074), 0px 0.537037px 2.36111px rgba(11, 20, 34, 0.0192593);
  }

  .theme--dark .pdf-icon-svg path{
    fill: #6E7883;
  }


</style>
`;
