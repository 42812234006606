import { EventEmitter2 } from 'eventemitter2';
import checkIfReallyOnline from 'is-online';

import { setNetworkStatus } from './foreground/stateUpdaters/transientStateUpdaters/network';
import { NetworkDetector as INetworkDetector, NetworkStatus } from './NetworkDetector'; // eslint-disable-line import/no-cycle
import { isCi, isExtension } from './utils/environment';

/*
  Safari doesn't support private class methods yet, so we use a `_` prefix instead.
  Please add/remove it when making something private/public.
*/
class NetworkDetector extends EventEmitter2 implements INetworkDetector {
  status: NetworkStatus = window.navigator.onLine ? NetworkStatus.Online : NetworkStatus.Offline;
  isOnline: boolean = window.navigator.onLine;

  constructor() {
    super();

    if (isCi) {
      this._setStatus(NetworkStatus.Online);
      return;
    }

    this._setStatus(this.status);

    if (window.navigator.onLine) {
      // Make sure navigator.onLine isn't a false positive
      this._checkIfReallyOnline()
        .then((isOnline) => {
          this._setStatus(isOnline ? NetworkStatus.Online : NetworkStatus.Offline);
        })
        .catch(() => {

          /* Ignore */
        });
    }

    this._subscribe();
  }

  async _checkIfReallyOnline() {
    // https://linear.app/readwise/issue/RW-9659/browser-extension-makes-requests-to-icanhaszip-on-every-single-tab
    if (isExtension) {
      return true;
    }

    return checkIfReallyOnline();
  }

  _setStatus(status: NetworkStatus) {
    this.status = status;
    this.isOnline = status !== NetworkStatus.Offline;
  }

  _subscribe() {
    const onChanged = async (newStatus: NetworkStatus) => {
      if (this.status === newStatus) {
        return;
      }

      this._setStatus(newStatus);

      this.emit('changed', { isOnline: this.isOnline, status: this.status });
      this.emit(newStatus);

      await setNetworkStatus(newStatus, this.isOnline);
    };

    window.addEventListener('offline', () => onChanged(NetworkStatus.Offline));

    window.addEventListener('online', async () => {
      if (!await this._checkIfReallyOnline()) {
        return;
      }
      onChanged(NetworkStatus.Online);
    });
  }
}

export default new NetworkDetector();
