import * as Sentry from '@sentry/browser';

// eslint-disable-next-line import/no-cycle
import getSentryConfig from '../../getSentryConfig';
import { isDevOrTest } from '../../utils/environment';
import makeLogger from '../../utils/makeLogger';

const logger = makeLogger(__filename);

export const IGNORE_SENTRY_PREFIX = '[ignore-sentry]';

export function init() {
  try {
    const commonSentryConfig = getSentryConfig();
    Sentry.init({
      ...commonSentryConfig,
      beforeSend(event) {
        if (!commonSentryConfig.beforeSend(event)) {
          return null;
        }

        if (isDevOrTest) {
          if (event.level && ['error', 'fatal'].includes(event.level)) {
            logger.error('From Sentry.beforeSend: ', { event });

            const message = event.exception?.values?.[0]?.value;
            if (typeof message === 'string' && !message.includes(IGNORE_SENTRY_PREFIX)) {
              // eslint-disable-next-line no-alert
              alert(`[WebView Sentry] ${message}`);
            }
          }
          return null;
        }
        const message = event.exception?.values?.[0]?.value;
        if (typeof message === 'string' && message.includes(IGNORE_SENTRY_PREFIX)) {
          return null;
        }
        return event;
      },
      dsn: 'https://ae9d77e7bdd040c4af857a2c286abae4@o374023.ingest.sentry.io/5795592',
    });
  } catch (e) {
    logger.error('error initializing Sentry', { e });
  }
}
export function setContext({ email, title, docId }: { email: string; title: string; docId: string; }) {
  Sentry.setUser({ email });
  // We dont use prop 'title' because its reserved and it replaces setContext first argument in sentry issue view
  Sentry.setContext('document', {
    docTitle: title,
    docId,
  });
}

export function trackError(...args: Parameters<typeof Sentry.captureException>) {
  Sentry.captureException(...args);
}

export class ScrollingManagerError extends Error {
  name = 'ScrollingManagerError';
}
