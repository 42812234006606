import { Category } from '../../types';
import type { WebviewDocumentChunk } from '../../types/chunkedDocuments';
import { DeferredPromise } from '../../utils/DeferredPromise';
import makeLogger from '../../utils/makeLogger';
import { rwSanitizeHtml } from '../../utils/rwSanitizeHtml';
// eslint-disable-next-line import/no-cycle
import { portalGate as portalGateToForeground } from '../portalGates/contentFrame/from/reactNativeWebview';

export type ChunkSanitizationOptions = {
  isOriginalEmailView: boolean;
  showEnhancedYouTubeTranscript: boolean | undefined;
  category: Category;
};

const logger = makeLogger(__filename);

class ChunkContainer {
  readonly chunkId: string;
  readonly index: number;
  readonly element: HTMLDivElement;
  readonly chunkChildNodeCount: number;
  readonly sanitizationOptions: ChunkSanitizationOptions;

  hasContent: boolean;

  private _entryIntersectionObserver: IntersectionObserver | null = null;
  private _exitIntersectionObserver: IntersectionObserver | null = null;

  constructor(chunk: WebviewDocumentChunk, sanitizationOptions: ChunkSanitizationOptions) {
    this.sanitizationOptions = sanitizationOptions;
    this.chunkId = chunk.id;
    this.index = chunk.index;
    this.chunkChildNodeCount = chunk.html_child_node_count;
    this.element = document.createElement('div');
    this.element.dataset.chunkId = this.chunkId;
    this.element.dataset.chunkIndex = this.index.toString();
    this.element.dataset.chunkChildNodeCount = this.chunkChildNodeCount.toString();
    this.element.classList.add('rw-chunk-container');
    if (chunk.content) {
      this.hasContent = true;
      this._loadContentIntoElement(chunk.content);
    } else {
      this.hasContent = false;
      this._clearElementContent();
    }
    this._setupIntersectionObservers();
  }

  setContent(content: string | null) {
    if (content && !this.hasContent) {
      this._loadContentIntoElement(content);
    } else if (!content && this.hasContent) {
      this._clearElementContent();
    }
  }

  destroy() {
    this._entryIntersectionObserver?.disconnect();
    this._exitIntersectionObserver?.disconnect();
    this._entryIntersectionObserver = null;
    this._exitIntersectionObserver = null;
  }

  private _setupIntersectionObservers() {
    this._entryIntersectionObserver = new IntersectionObserver(
      (entries) => {
        const shouldHaveContent = entries.some((entry) => entry.isIntersecting);
        if (!shouldHaveContent || this.hasContent) {
          return;
        }
        logger.debug('IntersectionObserver: loading content', {
          id: this.chunkId,
          index: this.index,
        });
        portalGateToForeground.emit('on-chunk-entered-viewing-window', this.chunkId);
      },
      {
        threshold: 0,
        rootMargin: '1000px 0',
      },
    );
    this._exitIntersectionObserver = new IntersectionObserver(
      (entries) => {
        const shouldHaveContent = entries.some((entry) => entry.isIntersecting);
        if (shouldHaveContent || !this.hasContent) {
          return;
        }
        logger.debug('IntersectionObserver: unloading content', {
          id: this.chunkId,
          index: this.index,
        });
        portalGateToForeground.emit('on-chunk-exited-viewing-window', this.chunkId);
      },
      {
        threshold: 0,
        rootMargin: '4000px 0',
      },
    );
    this._entryIntersectionObserver.observe(this.element);
    this._exitIntersectionObserver.observe(this.element);
  }

  private _clearElementContent() {
    logger.debug(`clearing element content`, { id: this.chunkId, index: this.index });
    let unloadedChunkHeight = window.innerHeight;
    if (this.hasContent) {
      unloadedChunkHeight = this.element.offsetHeight;
    }
    this.element.innerHTML = '';
    this.element.style.height = `${unloadedChunkHeight}px`;
    this.hasContent = false;
  }

  private _loadContentIntoElement(content: string) {
    logger.debug(`loading content into element`, { id: this.chunkId, index: this.index });
    const root = new DOMParser().parseFromString(content, 'text/html');
    const contentBody = root.body.innerHTML;
    // TODO: process <head> tags: scripts, styles, etc
    const sanitizedContent = rwSanitizeHtml(
      contentBody,
      this.sanitizationOptions.category,
      this.sanitizationOptions.isOriginalEmailView,
      this.sanitizationOptions.showEnhancedYouTubeTranscript,
    );
    this.element.innerHTML = sanitizedContent;
    this.element.style.height = '';
    this.hasContent = true;
  }
}

let chunkIdToContainerMap: { [chunkId: string]: ChunkContainer; } | null = null;
let initializationPromise: DeferredPromise<void> | null = null;

export function initChunkedContent(
  chunks: WebviewDocumentChunk[],
  sanitizationOptions: ChunkSanitizationOptions,
) {
  const contentRoot = document.querySelector('#document-text-content');
  if (!contentRoot) {
    throw new Error('No #document-text-content found');
  }
  initializationPromise = new DeferredPromise();
  contentRoot.innerHTML = '';
  const containers = chunks.map((chunk) => new ChunkContainer(chunk, sanitizationOptions));
  chunkIdToContainerMap = Object.fromEntries(
    containers.map((container) => [container.chunkId, container]),
  );
  for (const container of containers) {
    contentRoot.appendChild(container.element);
  }
  const endOfContent = document.createElement('div');
  endOfContent.id = 'end-of-content';
  contentRoot.appendChild(endOfContent);
  contentRoot.classList.add('rw-chunk-containers-root');
  logger.debug('initialized chunked content', { chunks: chunks.map((c) => c.id) });
  initializationPromise.resolve();
}

export async function updateChunkedContent(chunks: WebviewDocumentChunk[]) {
  if (initializationPromise) {
    await initializationPromise;
  }
  if (!chunkIdToContainerMap) {
    throw new Error('chunked content was not initialized');
  }
  for (const chunk of chunks) {
    const container: ChunkContainer | undefined = chunkIdToContainerMap[chunk.id];
    if (!container) {
      throw new Error(`no chunk container: ${chunk.id}`);
    }
    container.setContent(chunk.content);
  }
  logger.debug('updated chunked content', { chunks: chunks.map((c) => [c.id, Boolean(c.content)]) });
}

export function destroyChunkedContent() {
  if (!chunkIdToContainerMap) {
    return;
  }
  for (const container of Object.values(chunkIdToContainerMap)) {
    container.destroy();
  }
  chunkIdToContainerMap = null;
  initializationPromise = null;
}
