import type { MaybePromise } from '../../utils/typescriptUtils';
import isRunningInExtensionBackground from '../common/isRunningInExtensionBackground';
import makeExtensionLogger from '../common/makeExtensionLogger';
import adaptAsyncCallbackForChromeRuntimeOnMessage from './adaptAsyncCallbackForChromeRuntimeOnMessage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Subscription = (...args: any[]) => MaybePromise<unknown>;
const subscriptions: Subscription[] = [];

const logger = makeExtensionLogger(__filename);

export function init() {
  if (isRunningInExtensionBackground()) {
    throw new Error('isRunningInExtensionBackground');
  }
  chrome.runtime.onMessage.addListener(
    adaptAsyncCallbackForChromeRuntimeOnMessage(async (request, sender) => {
      const { message, data } = request;
      logger.debug('onMessage', { message, loc: 'start', data });

      let result;
      for (const subscription of subscriptions) {
        result = await subscription(request, sender);
        if (typeof result !== 'undefined') {
          break;
        }
      }

      logger.debug('onMessage', {
        message,
        loc: 'end',
        result: typeof result === 'string' && result.length > 23 ? `${result.substr(0, 20)}...` : result,
      });
      return result;
    }),
  );
}

export const listenForMessages = (callback: Subscription): void => {
  subscriptions.push(callback);
};

export const messageBackground = async (message: string, data?: unknown): Promise<unknown> => {
  logger.debug('messageBackground', { message, loc: 'start', data });
  const response = await chrome.runtime.sendMessage({ message, data });
  logger.debug('messageBackground', {
    message,
    loc: 'end',
    response:
      typeof response === 'string' && response.length > 23 ? `${response.substr(0, 20)}...` : response,
  });
  return response;
};
