import { browserOrDesktop } from '../../../utils/browserOrDesktop';
import {
  detectDatabaseStorageVersion as detectDatabaseStorageVersionForIndexedDB,
  finishStorageMigration as finishStorageMigrationForIndexedDB,
  removeAllDatabases as removeAllDatabasesForIndexedDB,
  startStorageMigration as startStorageMigrationForIndexedDB,
} from './helpers.web.browser';
import {
  detectDatabaseStorageVersion as detectDatabaseStorageVersionForSQLite,
  finishStorageMigration as finishStorageMigrationForSQLite,
  removeAllDatabases as removeAllDatabasesForSQLite,
  startStorageMigration as startStorageMigrationForSQLite,
} from './helpers.web.desktop';

export const detectDatabaseStorageVersion = browserOrDesktop(
  detectDatabaseStorageVersionForIndexedDB,
  detectDatabaseStorageVersionForSQLite,
);
export const startStorageMigration = browserOrDesktop(
  startStorageMigrationForIndexedDB,
  startStorageMigrationForSQLite,
);
export const finishStorageMigration = browserOrDesktop(
  finishStorageMigrationForIndexedDB,
  finishStorageMigrationForSQLite,
);
export const removeAllDatabases = browserOrDesktop(
  removeAllDatabasesForIndexedDB,
  removeAllDatabasesForSQLite,
);
