export async function loadSqliteFileFromBrowserCache(
  filename: string,
): Promise<undefined | ArrayBuffer> {
  const cache = await caches.open(filename);
  const cacheResponse = await cache.match('/data.json');
  return cacheResponse && cacheResponse.arrayBuffer();
}

export async function saveSqliteFileToBrowserCache(filename: string, buffer: Uint8Array) {
  const cache = await caches.open(filename);
  await cache.put('/data.json', new Response(buffer));
}

export class DiskImageMalformedError extends Error {}
