import { isDesktopApp, isMobile, isWebApp } from '../../utils/environment';

/*
  We need to be very careful here.

  This name is given to RxDB when creating the database. It uses it to create the database instance/file, e.g. the
  SQLite database file on mobile. If this name were to change, we'd essentially lose all old data (syncing, documents,
  etc) and start fresh (plus the old database would be still be there taking up space).

  It would be best to use one name for every platform and scenario, but that's not what we did when we initially
  released RxDB usage to production; we released mobile and web with `name: storage.name || 'reader'`.

  If we kept this, every time we change storage, the database name could change. E.g. let's say we temporarily stopped
  using IndexedDB via a SharedWorker in the web app and used IndexedDB directly instead, we'd needlessly lose all data.

  What we do now is use hardcoded strings, the same ones used in the initial production release; i.e. whatever
  `storage.name || 'reader'` evaluated to on each platform and scenario. (Yes this does mean that in the future we could
  have a mismatch between the name and the actual storage used)
*/
export default function getDatabaseName() {
  if (isMobile) {
    return 'sqlite';
  }
  if (isWebApp && !isDesktopApp) {
    if ('SharedWorker' in window) {
      return 'remote';
    }
    return 'indexeddb';
  }
  // Extension, document share app, and anything else new. From now on we want to use the same name.
  return 'reader';
}
