import type { CardinalDirection } from '../../types/misc';
import getClosestHTMLElement from './getClosestHTMLElement';
import canElementBeScrolledInCardinalDirection from './getScrollDistanceRemainingInCardinalDirection';

export default function getClosestAncestorWhichCanBeScrolledInCardinalDirection(
  element: Node,
  direction: CardinalDirection,
): HTMLElement | undefined {
  return getClosestHTMLElement(
    element,
    (ancestor) => canElementBeScrolledInCardinalDirection(ancestor, direction) > 0,
  );
}
