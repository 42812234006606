/*
  This file does not have exports; you just import the whole file and it defines all custom elements as a
  side-effect. It safely handles when it's imported more than once.
*/

/*
  We use this polyfill because of
  https://stackoverflow.com/questions/42800035/why-cant-you-create-custom-elements-in-content-scripts.

  TODO: confirm this works in Firefox. NOTE: previously we injected JS into the page context in Firefox
  to achieve this because of https://bugzilla.mozilla.org/show_bug.cgi?id=1488010. Since MV3, we'd have
  to do that even for Chrome, plus we'd need the new scripting permission (we don't want to add any
  permissions), and the new scripting API is very awkward... so we use a polyfill for all browsers in the
  extension now.
*/
import '@webcomponents/webcomponentsjs';

class ReadwiseElement extends HTMLElement {
  constructor() {
    super();

    Array.from(this.children).forEach((child) => this.appendChild(child));
  }
}

function defineCustomElement(
  tagName: string,
  elementConstructor: CustomElementConstructor,
  options = {},
) {
  if (customElements?.get(tagName)) {
    return;
  }

  try {
    customElements.define(tagName, elementConstructor, options);
  } catch (error) {
    if (
      !(error instanceof Error) ||
      !error.message.includes('already been defined') &&
        !error.message.includes('already been used with this registry')
    ) {
      // eslint-disable-next-line no-console
      console.error('Failed to define custom element', error);
      throw error;
    }
  }
}

defineCustomElement(
  'readwise-tooltip-container',
  class ReadwiseTooltipContainer extends ReadwiseElement {},
  {
    extends: 'div',
  },
);

defineCustomElement('rw-highlight', class ReadwiseHighlight extends ReadwiseElement {}, {
  extends: 'mark',
});
