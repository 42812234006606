import type { RxChangeEvent, RxCollection } from 'rxdb';
import { ulid } from 'ulid';

import type {
  DatabaseCollectionCommonUpdateFunctionOptions,
  DatabaseCollectionCommonUpdateFunctionOptionsWhenUpdatesHaveSideEffects,
  DatabaseCollectionNames,
  DatabaseCollectionNamesToDocType,
  DatabaseUpdateQueryResult,
  LastRxDBUpdate,
} from '../../types/database';
import type {
  HandleStateUpdateSideEffectsParameter,
  HandleStateUpdateSideEffectsResult,
} from '../../types/stateUpdates';
import collectionNamesWithoutUpdateSideEffects from '../collectionNamesWithoutUpdateSideEffects';
import createJsonPatchOperationsFromRxChangeEvents from '../createJsonPatchOperationsFromRxChangeEvents';
import formatDatabaseUpdateQueryResult from './formatDatabaseUpdateQueryResult';

export default async function performDatabaseUpdatesWithSideEffects<
  TCollectionName extends DatabaseCollectionNames,
  TCollectionDocType extends
    DatabaseCollectionNamesToDocType[TCollectionName] = DatabaseCollectionNamesToDocType[TCollectionName],
  TCallbackResult = unknown,
>(
  options: {
    // This is a parameter to make sure we use a portal gate when called from the background
    handleStateUpdateSideEffects: (
      arg: HandleStateUpdateSideEffectsParameter,
    ) => HandleStateUpdateSideEffectsResult;
    idsOfItemsToBeDeleted: string[] | 'all';
    rxCollection: RxCollection<TCollectionDocType>;
  } & DatabaseCollectionCommonUpdateFunctionOptions<TCollectionName>,
  executeQueries: (lastRxDBUpdate?: LastRxDBUpdate) => Promise<TCallbackResult>,
): DatabaseUpdateQueryResult<TCallbackResult> {
  if (
    collectionNamesWithoutUpdateSideEffects.includes(
      options.rxCollection.name as (typeof collectionNamesWithoutUpdateSideEffects)[0],
    )
  ) {
    return formatDatabaseUpdateQueryResult({
      queryResult: await executeQueries(),
      userEvent: undefined,
    });
  }

  const currentRxDBUpdateInfo: LastRxDBUpdate = {
    id: ulid(),
  };

  type TDocTypeWithLastRxDBUpdate = TCollectionDocType & {
    rxdbOnly: {
      lastRxDBUpdate: LastRxDBUpdate;
    };
  };

  // Find relevant RxCollection events to link changes back to queries
  const events: RxChangeEvent<TDocTypeWithLastRxDBUpdate>[] = [];
  const eventSubscription = options.rxCollection.$.subscribe((eventData) => {
    const eventDataWithLastRxDBUpdate = eventData as RxChangeEvent<TDocTypeWithLastRxDBUpdate>;
    const lastRxDBUpdate = eventDataWithLastRxDBUpdate.documentData.rxdbOnly.lastRxDBUpdate;
    if (
      lastRxDBUpdate?.id === currentRxDBUpdateInfo.id ||
      // Deletes require special checks
      eventData.operation === 'DELETE' &&
        (options.idsOfItemsToBeDeleted === 'all' ||
          options.idsOfItemsToBeDeleted.includes(eventData.documentId))
    ) {
      events.push(eventDataWithLastRxDBUpdate);
    }
  });

  const onQueriesFinished = () => {
    eventSubscription.unsubscribe();
  };

  try {
    // Perform the queries
    const queryResult = await executeQueries(currentRxDBUpdateInfo);
    onQueriesFinished();

    /*
      We clone it because we're going to modify it later and don't want a caller doing something wrong because the
      options they gave are now different
    */
    const fullSideEffectOptions = {
      ...options,
    } as DatabaseCollectionCommonUpdateFunctionOptionsWhenUpdatesHaveSideEffects;

    if (fullSideEffectOptions.dangerouslySkipSideEffects) {
      return { queryResult };
    }

    if (!('correlationId' in fullSideEffectOptions) || !fullSideEffectOptions.correlationId) {
      fullSideEffectOptions.correlationId = ulid();
    }
    const handleStateUpdateSideEffectsResult = await options.handleStateUpdateSideEffects({
      ...fullSideEffectOptions,
      jsonPatchOperations: createJsonPatchOperationsFromRxChangeEvents(events, options.rxCollection),
    });

    return {
      ...handleStateUpdateSideEffectsResult,
      queryResult,
    };
  } catch (error) {
    onQueriesFinished();
    throw error;
  }
}
