import isEqual from 'lodash/isEqual';
import transform from 'lodash/transform';
import { defaultCacheReplacementPolicyMonad } from 'rxdb';

import { CommonNonSyncingProperties } from '../../../nonSyncingDocumentProperties';
import { DatabaseGetSchemaResult } from '../../../types/database';
import { DocumentIndexesForDocumentsJSONSchema } from '../indexes';
import * as commonDefinitions from './commonDefinitions';
import { getIndexableInteger, getIndexableTimestamp } from './commonDefinitions';
import getLastRxDBUpdateInfoSchema from './getLastRxDBUpdateInfoSchema';

/*
  This schema must be kept in sync with the backend; e.g. the max-lengths defined here vs the truncate_long_json_fields
  function /reader/models.py
*/

const nonIndexedLongUrl = commonDefinitions.getString(4096);

const readingPosition = commonDefinitions.orType(
  {
    type: 'object',
    additionalProperties: false,
    properties: {
      pageNumber: commonDefinitions.integer,
      scrollDepth: {
        ...commonDefinitions.orType(commonDefinitions.anyNumber, 'null'),
        minimum: 0,
        maximum: 1,
      },
      serializedPosition: commonDefinitions.orType(commonDefinitions.getString(Infinity), 'null'),
      mobileSerializedPositionElementVerticalOffset: commonDefinitions.anyNumber,
      mobileSerializedPositionElementHorizontalOffset: commonDefinitions.anyNumber,
    },
  },
  'null',
);

const authorString = commonDefinitions.getString(250);
const imageUrl = nonIndexedLongUrl;
const language = commonDefinitions.getString(30);
// eslint-disable-next-line @typescript-eslint/naming-convention
const published_date = commonDefinitions.orType(
  commonDefinitions.getTimestamp({
    shouldAllowAnyTimeInPast: true,
  }),
  'null',
);
const category = commonDefinitions.getString(20);
const summary = commonDefinitions.getString(2000);
const title = commonDefinitions.getString(350);
const randomNumber = {
  type: 'integer',
  minimum: 0,
  maximum: 9,
  multipleOf: 1,
};

function getSchema(): { documents: DatabaseGetSchemaResult<'documents'>; } {
  return {
    documents: {
      schema: {
        primaryKey: 'id',
        additionalProperties: false,
        properties: {
          author: commonDefinitions.orType(authorString, 'null'),
          category,
          children: {
            type: 'array',
            items: commonDefinitions.ulid,
          },
          content: commonDefinitions.getString(20000),
          // I don't understand why this is complaining
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          context: commonDefinitions.getString(Infinity),
          currentScrollPosition: readingPosition,
          savedReadingPositions: {
            type: 'object',
            patternProperties: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '.*': readingPosition,
            },
          },
          deleted_at: commonDefinitions.getTimestamp(),
          favicon_url: commonDefinitions.orType(nonIndexedLongUrl, 'null'),
          firstOpenedAt: commonDefinitions.getIndexableTimestamp(),
          generated_summary: summary,
          html: commonDefinitions.getString(20000),
          id: commonDefinitions.ulid,
          image_url: commonDefinitions.orType(imageUrl, 'null'),
          isExtensionActivated: commonDefinitions.booleanInteger, // No longer used
          isExtensionBarMinimized: commonDefinitions.booleanInteger,
          is_virtual: commonDefinitions.booleanInteger,
          isPaginatedMode: commonDefinitions.booleanInteger,
          language,
          last_status_update: commonDefinitions.getIndexableTimestamp(),
          lastOpenedAt: commonDefinitions.orType(commonDefinitions.getTimestamp(), 'null'),
          lastSeenStatusUpdateAt: commonDefinitions.orType(commonDefinitions.getTimestamp(), 'null'),
          listening_time_seconds: commonDefinitions.getIndexableInteger(),
          location: commonDefinitions.getString(Infinity),

          /*
           * Non-synced, database internals (indices, sorting, ...). Strictly used for query optimizations (or make
           * things work like descending sorting, etc.)
           */
          rxdbOnly: {
            type: 'object',
            additionalProperties: false,
            properties: {
              ...getLastRxDBUpdateInfoSchema(),
              allTagsJoined: commonDefinitions.getString(Infinity),
              indexFields: {
                type: 'object',
                additionalProperties: false,
                properties: {
                  author: { ...authorString, maximum: 20 },
                  category: { ...category, maximum: 20 },
                  childrenCount: getIndexableInteger(),
                  firstOpenedAtExists: commonDefinitions.booleanInteger,
                  hasHighlights: commonDefinitions.booleanInteger,
                  hasShortlistTag: commonDefinitions.booleanInteger,
                  isNewOrLater: commonDefinitions.booleanInteger,
                  savedCount: getIndexableInteger(),
                  showInUnseenAfter: getIndexableTimestamp(),
                  showInSeenAfter: getIndexableTimestamp(),
                  last_status_update: getIndexableTimestamp(),
                  last_status_update_desc: getIndexableTimestamp(),
                  lastOpenedAt: getIndexableTimestamp(),
                  length_in_seconds: getIndexableInteger(),
                  parsed_doc_id: commonDefinitions.getIndexableInteger(),
                  published_date: getIndexableTimestamp(),
                  // We use these props to sort by random
                  randomNumber1: randomNumber,
                  randomNumber2: randomNumber,
                  randomNumber3: randomNumber,
                  randomNumber4: randomNumber,
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  readingPosition_scrollDepth: getIndexableInteger(),
                  saved_at: getIndexableTimestamp(),
                  saved_at_desc: getIndexableTimestamp(),
                  startedReading: commonDefinitions.booleanInteger,
                  title,
                  triage_status_exists: commonDefinitions.booleanInteger,
                  triage_status_is_feed: commonDefinitions.booleanInteger,
                },
                required: [
                  'author',
                  'category',
                  'childrenCount',
                  'firstOpenedAtExists',
                  'hasHighlights',
                  'hasShortlistTag',
                  'isNewOrLater',
                  'savedCount',
                  'showInUnseenAfter',
                  'showInSeenAfter',
                  'last_status_update',
                  'last_status_update_desc',
                  'lastOpenedAt',
                  'length_in_seconds',
                  'parsed_doc_id',
                  'published_date',
                  'randomNumber1',
                  'randomNumber2',
                  'randomNumber3',
                  'randomNumber4',
                  'readingPosition_scrollDepth',
                  'saved_at',
                  'saved_at_desc',
                  'startedReading',
                  'title',
                  'triage_status_exists',
                ],
              },
            },
            required: ['indexFields', 'allTagsJoined', 'lastRxDBUpdate'],
          },
          markdown: commonDefinitions.getString(20000),
          non_distributable: commonDefinitions.booleanInteger,
          notes: commonDefinitions.getString(Infinity),
          offset: commonDefinitions.integer,
          overrides: {
            type: 'object',
            additionalProperties: false,
            properties: {
              author: authorString,
              generated_summary: summary,
              image_url: imageUrl,
              language,
              published_date,
              summary,
              title,
              category,
            },
          },
          parent: commonDefinitions.ulid,
          parsed_doc_id: commonDefinitions.orType(
            commonDefinitions.orType(commonDefinitions.integer, 'string'),
            'null',
          ),
          published_date,
          reading_status: commonDefinitions.getString(20),
          readingPosition: {
            ...readingPosition,
            properties: {
              ...readingPosition.properties,
              tts: {
                type: 'object',
                additionalProperties: true,
              },
            },
          },
          saved_at: commonDefinitions.getIndexableTimestamp(),
          saved_at_history: {
            type: 'array',
            items: commonDefinitions.getTimestamp(),
          },
          saved_from_feed_at: commonDefinitions.getIndexableTimestamp(),
          sharedAt: commonDefinitions.getTimestamp({
            shouldAllowNull: true,
          }),
          shows_in_feed: commonDefinitions.booleanInteger,
          site_name: commonDefinitions.orType(commonDefinitions.getString(350), 'null'),
          source_id: {
            type: ['number', 'string', 'null'],
          },
          source_specific_data: {
            type: 'object',
            // This definitely contains more properties but we're deliberately not defining the schema for them
            additionalProperties: true,
            properties: {
              rss_feed: commonDefinitions.ulid,
            },
          },
          source: commonDefinitions.getString(50),
          summary,
          tags: {
            type: 'object',
            patternProperties: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '.*': {
                properties: {
                  name: commonDefinitions.getString(100),
                  created: commonDefinitions.getTimestamp(),
                  type: commonDefinitions.getString(30),
                },
                required: ['created', 'name'],
              },
            },
          },
          text: commonDefinitions.getString(20000),
          title,
          triage_status: {
            ...commonDefinitions.getString(12),
          },
          ttsPosition: {
            type: 'object',
            properties: {
              paraIndex: commonDefinitions.anyNumber,
              paraTextPos: commonDefinitions.anyNumber,
              textPos: commonDefinitions.anyNumber,
              trackPos: commonDefinitions.anyNumber,
              word: commonDefinitions.getString(Infinity),
            },
          },
          updated: commonDefinitions.getIndexableTimestamp(),
          url: commonDefinitions.getString(1000),
          word_count: getIndexableInteger(),
          enrichment_sources: {
            type: 'object',
            additionalProperties: true,
          },
        },
        indexes: DocumentIndexesForDocumentsJSONSchema,
        required: [
          'category',
          // I don't understand why this is complaining
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'rxdbOnly',
          'saved_at',
        ],
        // https://readwise.slack.com/archives/C6MTBU1J4/p1698146990617879
        // allOf: [
        //   {
        //     if: {
        //       properties: {
        //         category: {
        //           enum: ['highlight', 'note'],
        //         },
        //       },
        //     },
        //     then: {
        //       properties: {
        //         triage_status: false,
        //       },
        //     },
        //   },
        // ],
        type: 'object',
        version: 0,
      },
      migrationStrategies: {},
      cacheReplacementPolicy: defaultCacheReplacementPolicyMonad(300, 60 * 1000),
    },
  } as const;
}

function getServerSideSchema(): ReturnType<typeof getSchema>['documents']['schema'] {
  const schema = getSchema().documents.schema;
  CommonNonSyncingProperties.forEach((property) => {
    delete schema.properties[property];
    if (schema.required) {
      schema.required = schema.required.filter((requiredProperty) => requiredProperty !== property);
    }
  });

  // Replace boolean integer properties with booleans for server-side JSON schema validation.
  schema.properties = transform(
    schema.properties,
    (result, property, key) => {
      if (isEqual(property, commonDefinitions.booleanInteger)) {
        result[key] = {
          type: 'boolean',
        };
      } else {
        result[key] = property;
      }
    },
    {} as typeof schema.properties,
  );
  // Always force enable key compression
  schema.keyCompression = true;

  return schema;
}

export default {
  getSchema,
  getServerSideSchema,
};
