// eslint-disable-next-line restrict-imports/restrict-imports
import database from '../../foreground/database';
import { isDocumentShareApp, isTest } from '../../utils/environment';

/*
  Our Cypress component tests were throwing "Cannot access 'database' before initialization" for some reason. This works around that.
*/
export default isTest || isDocumentShareApp
  ? new Proxy(
      {},
    {
      get(target, property) {
        return database[property];
      },
    },
    )
  : database;
