import type { Event } from '@sentry/browser';
import omit from 'lodash/omit';

import { appCategory, appVersion, channel, commitId, isExtension } from './utils/environment';
// eslint-disable-next-line import/no-cycle
import { ReadwiseFetchNetworkConnectivityError, ReadwiseFetchServerError } from './utils/Errors';
import isSentryEventFromExtension from './utils/isSentryEventFromExtension';

export default ({
  currentExtensionId,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: { currentExtensionId?: string; } = {}): { [key: string]: any; } => {
  return {
    beforeSend(event: Event) {
      if (isExtension !== isSentryEventFromExtension(event, currentExtensionId)) {
        return null;
      }

      const firstValue = event.exception?.values?.[0]?.value;

      if (isExtension) {
        // Ignore the Firefox mobile app
        if (navigator.userAgent.toLowerCase().includes('android')) {
          return null;
        }

        if (
          typeof firstValue === 'string' &&
          (firstValue.includes('No tab with id:') || firstValue.includes('The browser is shutting down'))
        ) {
          return null;
        }
      }

      if (
        typeof firstValue === 'string' &&
        firstValue.includes(
          "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing",
        )
      ) {
        return null;
      }

      const type = event.exception?.values?.[0]?.type;
      if (
        type &&
        [ReadwiseFetchNetworkConnectivityError.name, ReadwiseFetchServerError.name].includes(type)
      ) {
        return null;
      }

      // Let's clean up RxDB remote storage errors. Their message contains JSON
      if (typeof firstValue === 'string' && firstValue.startsWith('could not requestRemote: {')) {
        const json = firstValue.replace(/^could not requestRemote: /, '');
        let parsedValue:
          | {
              error?: {
                message?: string;
                // And more
              };
              methodName: string;
              params?: unknown[];
            }
          | undefined;
        try {
          parsedValue = JSON.parse(json);
        } catch (e) {
          // Ignore
        }

        if (parsedValue && event.exception?.values?.[0]) {
          event.extra = {
            ...event.extra,
            extrasFromRxDbRemoteError: omit(parsedValue, ['error']),
            isRxDBRemoteStorageError: true,
            originalSentryExceptionValue: firstValue,
          };

          if (parsedValue.error?.message) {
            // Don't update the `type` property because it could something better than `Error` already
            event.exception.values[0].value = parsedValue.error?.message;
            return event;
          } else if (parsedValue.methodName) {
            event.exception.values[0].value = `${parsedValue.methodName}: non-specific error`;
            return event;
          }
        }
      }

      return event;
    },
    environment: channel,
    maxValueLength: 5000,
    release: `${appCategory}@${appVersion === 'unknown' ? commitId : appVersion}`,
    // Percentage of transactions captured for performance monitoring
    tracesSampleRate: 0,
  };
};
