import getBoundingClientRect from './getBoundingClientRect';

export default async ({
  subject,
  scrollableRoot,
  canCauseReflow,
  rootPercentRange,
}: {
  subject: Element | Range | DOMRect;
  scrollableRoot: HTMLElement;
  canCauseReflow?: boolean;
  rootPercentRange?: { top: number; bottom: number; };
}): Promise<{
  isBottomInView: boolean;
  isCompletelyInView: boolean;
  isInView: boolean;
  isTopInView: boolean;
  rect: DOMRect;
  scrollableRect: DOMRect;
}> => {
  let rect: DOMRect;
  if (subject instanceof DOMRect) {
    rect = subject;
  } else if (canCauseReflow || subject instanceof Range) {
    rect = subject.getBoundingClientRect();
  } else {
    rect = await getBoundingClientRect(subject);
  }

  const scrollableRect = canCauseReflow
    ? scrollableRoot.getBoundingClientRect()
    : await getBoundingClientRect(scrollableRoot);

  let rootTop = scrollableRect.top;
  let rootBottom = scrollableRect.bottom;
  if (rootPercentRange) {
    const rootHeight = rootBottom - rootTop;
    rootTop = rootTop + rootHeight * rootPercentRange.top;
    rootBottom = rootTop + rootHeight * rootPercentRange.bottom;
  }

  const isPointInView = (point: number) => point >= rootTop && point <= rootBottom;

  const isBottomInView = isPointInView(rect.bottom);
  const isTopInView = isPointInView(rect.top);

  return {
    isBottomInView,
    isCompletelyInView: isBottomInView && isTopInView,
    isInView: isBottomInView || isTopInView || rect.top < rootTop && rect.bottom > rootBottom,
    isTopInView,
    rect,
    scrollableRect,
  };
};
