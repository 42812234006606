import uniqBy from 'lodash/uniqBy';
import { ulid as createId } from 'ulid';

import type { DataUpdatesItemUpdatedReference, ID, Patch, UserEvent } from '../types';
import nowTimestamp from '../utils/dates/nowTimestamp';
import {
  appCategory,
  appVersion,
  browserInfo,
  channel,
  commitId,
  device,
  isMobile,
  os,
} from '../utils/environment';
// eslint-disable-next-line import/no-cycle
import { addUserEvent } from './models';
// eslint-disable-next-line import/no-cycle
import background from './portalGates/toBackground';

let focusSessionId: ID | null;
let instanceSessionId: ID;
let page: NonNullable<UserEvent['environment']['app']>['page'];
let pageSessionId: ID;
let windowSessionId: ID;

export const init = async (): Promise<void> => {
  focusSessionId = createId();
  instanceSessionId = createId();
  pageSessionId = createId();
  windowSessionId = createId();

  trackUserEvent({
    eventName: 'app-launched',
    userInteraction: 'unknown',
  });
};

// Entire app lost / gained focus
export const onAppFocusChange = async (isFocused: boolean): Promise<void> => {
  focusSessionId = focusSessionId ?? createId();
  if (isFocused) {
    background.pollLatestState(1);
  }
  background.tellStateSyncerThatAppFocusChanged(isFocused);

  await trackUserEvent({
    eventName: `app-focus-${isFocused ? 'gained' : 'lost'}`,
    userInteraction: isFocused ? 'focus' : 'blur',
  });
};

export const onPageChange = (pageInfo: NonNullable<UserEvent['environment']['app']>['page']): void => {
  page = pageInfo;
  pageSessionId = createId();
};

export const createUserEvent = ({
  correlationId,
  dataUpdates: incomingDataUpdates,
  eventName,
  userInteraction,
}: {
  correlationId?: UserEvent['correlationId'];
  dataUpdates?: { forwardPatch: Patch; reversePatch: Patch; };
  eventName: UserEvent['name'];
  userInteraction?: string | null;
}): UserEvent => {
  let dataUpdates: UserEvent['dataUpdates'];
  if (incomingDataUpdates) {
    const itemsUpdated = uniqBy(incomingDataUpdates.forwardPatch, (operation) => {
      const [type, id] = operation.path.split('/').filter(Boolean);
      return `${type}#${id}`;
    }).map((operation) => {
      const [type, id] = operation.path.split('/').filter(Boolean);
      return { id, type } as DataUpdatesItemUpdatedReference;
    });

    dataUpdates = {
      forwardPatch: incomingDataUpdates.forwardPatch,
      itemsUpdated,
      reversePatch: incomingDataUpdates.reversePatch,
    };
  }

  const id = createId();

  const nonMobileProperties = isMobile
    ? {}
    : {
      browser: {
        ...browserInfo,
        userAgent: navigator.userAgent,
      },
      device,
    };

  return {
    correlationId: correlationId ?? id,
    dataUpdates,
    environment: {
      agent: {
        category: appCategory,
        version: appVersion,
      },
      app: {
        category: appCategory,
        commitId,
        page,
        sessions: {
          focusSessionId,
          instanceSessionId,
          pageSessionId,
          windowSessionId,
        },
        version: appVersion,
      },
      channel,
      os,
      ...nonMobileProperties,
    },
    id,
    name: eventName,
    timestamp: nowTimestamp(),
    userInteraction: userInteraction ? { name: userInteraction } : undefined,
  };
};

export function trackUserEvent(options: Parameters<typeof createUserEvent>[0]): UserEvent {
  const event = createUserEvent(options);
  addUserEvent(event);
  return event;
}
