import type { PointerLikeEvent, PointerLikeEventCoordinatesObject } from '../../types/browserEvents';
import type { CardinalDirection } from '../../types/misc';
import convertCardinalDirectionToAxis from '../../utils/convertCardinalDirectionToAxis';
import convertCardinalDirectionToSide from '../../utils/convertCardinalDirectionToSide';
import { isMobile } from '../../utils/environment';
import makeLogger from '../../utils/makeLogger';
import cropBoundingClientRectToWindow from '../utils/cropBoundingClientRectToWindow';
import getClosestAncestorWhichCanBeScrolledInCardinalDirection from '../utils/getClosestAncestorWhichCanBeScrolledInCardinalDirection';
import getCoordinatesObjectFromPointerLikeEvent from '../utils/getCoordinatesObjectFromPointerLikeEvent';
import getScrollDistanceRemainingInCardinalDirection from '../utils/getScrollDistanceRemainingInCardinalDirection';
import getScrollDirection from './selectionEmulation/getScrollDirection';
import shouldPointerPositionCauseScrollIfPossible from './selectionEmulation/shouldPointerPositionCauseScrollIfPossible';

const logger = makeLogger(__filename);

const defaultState: {
  isAutoScrolling: boolean;
  lastPointerMoveEvent: PointerLikeEvent | null;
  originCoordinates: PointerLikeEventCoordinatesObject | null;
  originTarget: Node | null;
  scrollCardinalDirection: CardinalDirection | null;
  status: 'active' | 'inactive';
} = {
  isAutoScrolling: false,
  lastPointerMoveEvent: null,
  originCoordinates: null,
  originTarget: null,
  scrollCardinalDirection: null,
  status: 'inactive',
};

let state = defaultState;

const cardinalDirectionToScrollSide: { [key: string]: 'left' | 'top'; } = {
  east: 'left',
  north: 'top',
  south: 'top',
  west: 'left',
};

const distanceFromEdgeToStartScrollingAt = isMobile ? 50 : 30;

// Recursively called while auto-scrolling is enabled
function autoScroll() {
  if (!state.isAutoScrolling || !state.scrollCardinalDirection || !state.originTarget) {
    return;
  }

  let closestAncestorToScroll = getClosestAncestorWhichCanBeScrolledInCardinalDirection(
    state.originTarget,
    state.scrollCardinalDirection,
  );
  if (!closestAncestorToScroll) {
    return;
  }

  let totalDistanceToScroll = calculateAutoScrollDistanceForFrame(closestAncestorToScroll);

  /*
    We need to go upwards through scrollable ancestors and scroll them until we have scrolled the total
    distance. This is how browsers behave
  */
  while (totalDistanceToScroll > 0 && closestAncestorToScroll) {
    const remainingScrollDistance = getScrollDistanceRemainingInCardinalDirection(
      closestAncestorToScroll,
      state.scrollCardinalDirection,
    );
    const distanceToScrollAncestor = Math.min(remainingScrollDistance, totalDistanceToScroll);

    closestAncestorToScroll.scrollBy({
      behavior: 'instant',
      [cardinalDirectionToScrollSide[state.scrollCardinalDirection]]:
        distanceToScrollAncestor * (['east', 'north'].includes(state.scrollCardinalDirection) ? -1 : 1),
    });

    totalDistanceToScroll -= distanceToScrollAncestor;
    closestAncestorToScroll = getClosestAncestorWhichCanBeScrolledInCardinalDirection(
      closestAncestorToScroll,
      state.scrollCardinalDirection,
    );
  }

  requestAnimationFrame(autoScroll);
}

// When this is called, we know it's valid to scroll. This just decides how far per frame / how fast
function calculateAutoScrollDistanceForFrame(ancestor: HTMLElement): number {
  if (!state.isAutoScrolling || !state.scrollCardinalDirection || !state.lastPointerMoveEvent) {
    throw new Error('Bad state');
  }

  const lastPointerMoveEventCoordinates = getCoordinatesObjectFromPointerLikeEvent(
    state.lastPointerMoveEvent,
  );
  const axis = convertCardinalDirectionToAxis(state.scrollCardinalDirection);
  const cursorCoordinate = lastPointerMoveEventCoordinates[`client${axis}`];

  // This makes the calculations easier. Otherwise there would be a LOT of `if` statements
  const doCoordinatesGetSmallerInScrollCardinalDirection = ['east', 'north'].includes(
    state.scrollCardinalDirection,
  );

  const ancestorRect = cropBoundingClientRectToWindow(ancestor.getBoundingClientRect());
  const ancestorEndCoordinate =
    ancestorRect[convertCardinalDirectionToSide(state.scrollCardinalDirection)];
  const scrollZoneStartCoordinate =
    ancestorEndCoordinate +
    distanceFromEdgeToStartScrollingAt * (doCoordinatesGetSmallerInScrollCardinalDirection ? 1 : -1);

  let distancePastScrollZoneStart: number;
  if (doCoordinatesGetSmallerInScrollCardinalDirection) {
    distancePastScrollZoneStart = scrollZoneStartCoordinate - cursorCoordinate;
  } else {
    distancePastScrollZoneStart = cursorCoordinate - scrollZoneStartCoordinate;
  }
  distancePastScrollZoneStart = Math.min(
    distancePastScrollZoneStart,
    distanceFromEdgeToStartScrollingAt,
  );

  const maxAdditionalDistance = isMobile ? 10 : 20;
  return Math.round(
    5 + maxAdditionalDistance * (distancePastScrollZoneStart / distanceFromEdgeToStartScrollingAt),
  );
}

function startAutoScrolling(event: PointerLikeEvent, scrollCardinalDirection: CardinalDirection) {
  logger.debug('startAutoScrolling', { event, scrollCardinalDirection, state });
  if (state.status === 'inactive' || state.isAutoScrolling) {
    return;
  }

  state.isAutoScrolling = true;
  state.lastPointerMoveEvent = event;
  state.scrollCardinalDirection = scrollCardinalDirection;

  autoScroll();
}

function stopAutoScrolling() {
  logger.debug('stopAutoScrolling', { state });
  if (state.status === 'inactive' || !state.isAutoScrolling) {
    return;
  }

  state.isAutoScrolling = false;
  state.lastPointerMoveEvent = null;
  state.scrollCardinalDirection = null;
}

function onPointerMove(event: PointerEvent) {
  if (state.status === 'inactive' || !state.originCoordinates || !state.originTarget) {
    return;
  }
  state.lastPointerMoveEvent = event;

  const scrollDirection = getScrollDirection(state.originCoordinates, event);
  const scrollCardinalDirection = scrollDirection.split('-')[0] as CardinalDirection;

  const closestAncestorToScroll = getClosestAncestorWhichCanBeScrolledInCardinalDirection(
    state.originTarget,
    scrollCardinalDirection,
  );

  if (
    shouldPointerPositionCauseScrollIfPossible({
      ancestor: closestAncestorToScroll,
      cardinalDirection: scrollCardinalDirection,
      distanceFromEdgeToStartScrollingAt: 50,
      event,
    })
  ) {
    if (!state.isAutoScrolling) {
      startAutoScrolling(event, scrollCardinalDirection);
    }
  } else if (state.isAutoScrolling) {
    stopAutoScrolling();
  }
}

export function startListening(lastPointerDownEvent: PointerLikeEvent) {
  state = {
    isAutoScrolling: false,
    lastPointerMoveEvent: null,
    originCoordinates: getCoordinatesObjectFromPointerLikeEvent(lastPointerDownEvent),
    originTarget: lastPointerDownEvent.target as Node,
    scrollCardinalDirection: null,
    status: 'active',
  };

  document.addEventListener('pointermove', onPointerMove);
}

export function stopListening() {
  document.removeEventListener('pointermove', onPointerMove);
  stopAutoScrolling();
  state = defaultState;
}
