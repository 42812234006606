import { addRxPlugin } from 'rxdb';
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup';
// Aliased to a no-op in dev
import * as rxDbDevMode from 'rxdb/plugins/dev-mode';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration-schema';

import { isDevOrTest } from '../../utils/environment';

let hasBeenCalled = false;

export default function setUpPlugins() {
  if (hasBeenCalled) {
    return;
  }
  hasBeenCalled = true;

  if (isDevOrTest) {
    addRxPlugin(rxDbDevMode.RxDBDevModePlugin);
  }
  addRxPlugin(RxDBCleanupPlugin);
  addRxPlugin(RxDBLeaderElectionPlugin);
  addRxPlugin(RxDBMigrationPlugin);
}
