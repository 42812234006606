import '../from/singleProcess'; // Make sure the other gate is running

import PortalGate, { CallResponsePayload } from '../../../../PortalGate';
import type * as contentFrameMethods from '../../../contentFramePortalGateInternalMethods';
import foregroundEventEmitter from '../../../eventEmitter';
// eslint-disable-next-line import/no-cycle
import * as methods from '../../../methods';
import { ContentFrameEventMessageArgumentMap } from '../../../types/events';

/*
  This is the portal gate to the content frame. Specifically, when both the foreground and
  content frame aren't separated into multiple processes / webviews. See the equivalent file
  in the `../from` for more info.
*/

export const portalGate = new PortalGate<
  typeof contentFrameMethods,
  ContentFrameEventMessageArgumentMap
>({
  getGateName: () => 'Foreground',
  localMethods: methods,
  loggingPrefix: '🖼',
  loggingColor: 'darkgreen',
  portalName: 'foreground-contentFrame',
  sendMessage: async ({ message, data }) => {
    foregroundEventEmitter.emit(message, data);
  },
});

foregroundEventEmitter.onAny((event: string | string[], data: CallResponsePayload['data']) => {
  portalGate.handleIncomingMessage({ message: event as string, data });
});

export default portalGate.methods;
