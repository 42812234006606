import type { LenientWindow } from '../../types/LenientWindow';
import { findAllElementsFromTagList } from '../utils/findAllElementsFromTagList';
import { findCenteredElementInViewport } from '../utils/findCenteredElementInViewport';

// populates the result array with a list of TTS'able nodes
// WARNING: This function closely maps to a similar function in unreal_speech_v1.py on the backend.
// Changing anything here necessitates a change in the python function too.
export function populateTtsAbleElements(element: ChildNode, result: ChildNode[]): void {
  const readableTags = new Set(['P', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'LI', 'BLOCKQUOTE']);
  findAllElementsFromTagList(element, result, readableTags);
}

/**
 *
 * @return object with "element" if it can be found
 */
export function findTTSableElementFromCenterOfViewport(
  documentElement: HTMLElement,
  ttsAbleElements: HTMLElement[],
  window: LenientWindow,
): { element?: HTMLElement; index: number; } {
  if (ttsAbleElements.length === 0) {
    populateTtsAbleElements(documentElement, ttsAbleElements);
  }
  // No selection, get an element in the center of the screen
  // Filter out invisible elements before binary search.
  const filteredElements = ttsAbleElements.filter((elem) => elem.getBoundingClientRect().height > 0);
  const targetNode = findCenteredElementInViewport(filteredElements, window);
  if (!targetNode) {
    // No element below center of screen, just skip back to the final TTS'able element in that case. Should be quite rare.
    return { element: ttsAbleElements[ttsAbleElements.length - 1], index: ttsAbleElements.length - 1 };
  }
  return { element: targetNode, index: ttsAbleElements.indexOf(targetNode) };
}

export function findTtsAbleNode(
  textPos: number,
  ttsAbleElements: Element[],
): [Node | undefined, number] {
  let totalTextLength = 0;
  let currentNode;
  for (let i = 0; i < ttsAbleElements.length; i++) {
    // loop through all nodes, counting the total text length
    // Take the textPos, end the loop when the text length is greater than textPos
    let nextTextLength = totalTextLength;
    const textContent = ttsAbleElements[i].textContent;
    if (textContent) {
      nextTextLength = totalTextLength + textContent.length;
    }
    if (nextTextLength > textPos) {
      // the node which should contain the word will be the last non empty node right before we terminate.
      let j = i;
      while (j >= 0) {
        const textContent = ttsAbleElements[i].textContent;
        if (textContent && textContent.length > 0) {
          currentNode = ttsAbleElements[j];
          break;
        }
        j--;
      }
      break;
    }
    totalTextLength = nextTextLength;
  }
  const startOfParagraph = totalTextLength;
  const wordOffset = textPos - startOfParagraph;
  return [currentNode, wordOffset];
}

export class TextToSpeechContentFrameError extends Error {
  name = 'TextToSpeechContentFrameError';
}
