import { isExtension } from '../../utils/environment';
import makeExtensionLogger from './makeExtensionLogger';

// eslint-disable-next-line no-undef
const logger = makeExtensionLogger(__filename);

/*
  Why does this file exist?

  1. For logging / debugging.
  2. To prune old data in storage.

  Do not introduce/use `storage.onChanged`.
*/

// Do NOT call any of the exported functions in here in this readyPromise function.
const readyPromise = (async () => {
  if (!isExtension) {
    return;
  }
  const items = await chrome.storage.local.get(null);
  const keysToDelete = Object.keys(items).filter(
    (key) => key === 'hadAccessToReader' || key.startsWith('document-'),
  );
  if (!keysToDelete.length) {
    logger.debug('[prepare] nothing to do, exiting...');
    return;
  }

  logger.debug('[prepare] deleting old items in storage...', { keysToDelete });
  await chrome.storage.local.remove(keysToDelete);
  logger.debug('[prepare] ...done');
})();

export const clear = async () => {
  await readyPromise;
  logger.debug('clear');
  return chrome.storage.local.clear();
};

export const get = async (keys: Parameters<typeof chrome.storage.local.get>[0]) => {
  await readyPromise;
  logger.debug('get', { keys });
  return chrome.storage.local.get(keys);
};

export const getSingleItem = async <T>(key: string): Promise<T | undefined> => {
  await readyPromise;
  logger.debug('getSingleItem', { key });
  const items = await chrome.storage.local.get(key);
  const item = items[key];
  if (typeof item !== 'undefined') {
    return items[key] as T;
  }
};

export const remove = async (keys: Parameters<typeof chrome.storage.local.remove>[0]) => {
  await readyPromise;
  logger.debug('remove', { keys });
  return chrome.storage.local.remove(keys);
};

export const set = async (items: Parameters<typeof chrome.storage.local.set>[0]) => {
  await readyPromise;
  logger.debug('set', { items });
  return chrome.storage.local.set(items);
};

export default {
  clear,
  get,
  getSingleItem,
  remove,
  set,
};
