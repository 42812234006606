import sanitizeHtml from 'sanitize-html';

import { Category } from '../../types';
import {
  allowVulnerableTags,
  defaultAllowedSchemesByTag,
  getDefaultAllowedAttributes,
  getDefaultAllowedTags,
  getDefaultTransformTags,
} from './common';

export function rwSanitizeForEPUBs(contentParam: string, category: Category) {

  /*
    We used to add all epub styles to a root #document-text-content selector.
    Now we attach it all to .document-content but this is for backwards compatability
  */
  const content = contentParam.replaceAll('#document-text-content', '.document-content');
  // we keep inline styles for images in emails, as sometimes they're needed to properly size the images.
  // TODO: the parser could transform the inline widths/heights to width/height dom properties so we could avoid this
  const shouldAllowImageStyles = false;
  const defaultAllowedTags = getDefaultAllowedTags();
  const allowedTags = defaultAllowedTags.concat(['style']);
  const allowedAllAttrs: string[] = ['data-rw-*', 'id', 'class'];
  const allowedClasses = { '*': ['*'] }; // eslint-disable-line
  const defaultTransformTags = getDefaultTransformTags(shouldAllowImageStyles, category);
  return sanitizeHtml(content, {
    allowedAttributes: getDefaultAllowedAttributes(allowedAllAttrs, shouldAllowImageStyles),
    allowedClasses,
    allowedTags,
    // For Style tag warning (style tag is considered dangerous)
    allowVulnerableTags,
    allowedSchemes: ['data', 'http', 'https'],
    transformTags: defaultTransformTags,
    allowedSchemesByTag: defaultAllowedSchemesByTag,
  });
}
