import type { DocumentTag } from '../types/tags';

export function cleanAndValidateTagName(tagName: DocumentTag['name']):
  | {
      cleanTagName: DocumentTag['name'];
      validationError: undefined;
    }
  | {
      cleanTagName: undefined;
      validationError: Error;
    } {
  if (tagName.startsWith('|')) {
    return {
      cleanTagName: undefined,
      validationError: new Error('Tag name cannot start with |'),
    };
  }

  if (tagName.includes('"')) {
    return {
      cleanTagName: undefined,
      validationError: new Error('Tag name cannot contain quotation mark'),
    };
  }

  if (tagName.includes('[') || tagName.includes(']')) {
    return {
      cleanTagName: undefined,
      validationError: new Error('Tag name cannot contain square brackets'),
    };
  }

  if (tagName.includes('\\')) {
    return {
      cleanTagName: undefined,
      validationError: new Error('Tag name cannot contain a backlash'),
    };
  }

  const cleanTagName = cleanUpTagName(tagName);

  if (!cleanTagName) {
    return {
      cleanTagName: undefined,
      validationError: new Error('Tag name cannot be empty'),
    };
  }

  return {
    cleanTagName,
    validationError: undefined,
  };
}

export function cleanUpTagName(tagName: DocumentTag['name']): DocumentTag['name'] {
  // RxDB doesn't allow `\n`, `\r`, `"`
  return tagName.replace(/\r|\n/g, '').trim();
}

export function cleanUpTagNames(tagNames: DocumentTag['name'][]): DocumentTag['name'][] {
  return tagNames.map((tagName) => cleanUpTagName(tagName));
}
