import type { AnyDocument, EditableKeys, PartialDocument } from '../types';

export default function getDocumentOverrideOrReal<Key extends keyof EditableKeys>(
  doc: AnyDocument | PartialDocument<AnyDocument, 'overrides' | Key> | null,
  key: Key,
) {
  if (!doc) {
    return '';
  }
  // If the user overrode a key, return that value. E.g it could be an empty string.
  if (doc.overrides && key in doc.overrides && doc.overrides[key] !== undefined) {
    return doc.overrides[key];
  }

  return doc[key] || '';
}
