// Sometimes on Android, an element's bounding client rect can go past the viewport :shrug:
export default function cropBoundingClientRectToWindow(rect: DOMRect): DOMRect {
  const x = Math.max(rect.left, 0);
  const y = Math.max(rect.top, 0);
  const right = Math.min(rect.right, window.innerWidth);
  const bottom = Math.min(rect.bottom, window.innerHeight);
  const width = right - x;
  const height = bottom - y;
  return new DOMRect(x, y, width, height);
}
