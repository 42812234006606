import type { Axis, CardinalDirection } from '../types/misc';

const cardinalDirectionToAxis: { [key: string]: Axis; } = {
  east: 'X',
  north: 'Y',
  south: 'Y',
  west: 'X',
};

export default function convertCardinalDirectionToAxis(cardinalDirection: CardinalDirection): Axis {
  return cardinalDirectionToAxis[cardinalDirection];
}
