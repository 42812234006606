// eslint-disable-next-line restrict-imports/restrict-imports
import foregroundEventEmitter from '../../foreground/eventEmitter';
import Toaster from '../../foreground/Toaster';
import delay from '../../utils/delay';
// eslint-disable-next-line import/no-cycle
import {
  ReadwiseFetchClientError,
  ReadwiseFetchNetworkConnectivityError,
  ReadwiseFetchServerError,
} from '../../utils/Errors';
import parseJsonResponseBody from '../../utils/parseJsonResponseBody';
import makeExtensionLogger from '../common/makeExtensionLogger';
import on401 from '../common/on401';
import * as messenger from './messenger';

const logger = makeExtensionLogger(__filename);

export default async function onRequestError({
  action = 'network request',
  additionalLogContext = {},
  error,
}: {
  action?: string;
  additionalLogContext?: { [key: string]: unknown; };
  error: Error | unknown;
}) {
  if (!(error instanceof Error)) {
    return;
  }

  const toastCreationOptions: Parameters<Toaster['createToast']>[0] = {
    category: 'error',
    content: `Reader ${action} failed`,
  };

  const logContext: { [key: string]: unknown; } = { ...additionalLogContext };

  if (error instanceof ReadwiseFetchClientError || error instanceof ReadwiseFetchServerError) {
    logContext.responseStatus = error.response.status;
    let shouldToastHaveReloadButton = true;

    if (error instanceof ReadwiseFetchClientError) {
      if (error.response.status === 401) {
        await on401();
        shouldToastHaveReloadButton = false;
        toastCreationOptions.buttonText = 'Reconnect';
        toastCreationOptions.content += ', unauthorized';
        toastCreationOptions.onButtonClick = async () => {
          foregroundEventEmitter.emit('extension:removeAllToasts');
          await messenger.messageBackground('extension:open-install-page');
        };
      } else if (error.response.status === 403) {
        shouldToastHaveReloadButton = false;
        toastCreationOptions.content += ', forbidden. Try disabling and re-enabling the extension';
      } else {
        toastCreationOptions.content += '. Tab may be out of sync. Please reload and try again';
        toastCreationOptions.duration = false;
      }
      try {
        logContext.responseJson = await parseJsonResponseBody(error.response);
      } catch (e) {
        // Ignore
      }
    }

    if (shouldToastHaveReloadButton) {
      // eslint-disable-next-line require-atomic-updates
      toastCreationOptions.buttonText = 'Reload';
      toastCreationOptions.onButtonClick = () => {
        // @ts-expect-error this is fine
        window.location = window.location.href;
      };
    }
  } else if (error instanceof ReadwiseFetchNetworkConnectivityError) {
    logContext.offline = true;
    toastCreationOptions.content += ' due to a network connectivity error';
  }

  (async () => {
    await delay(50); // To allow for any successful toast to get registered first
    foregroundEventEmitter.emit('extension:removeAllToasts');
    foregroundEventEmitter.emit('extension:createToast', toastCreationOptions);
  })();

  logger.error('API request error', { ...logContext, error });
}
