import isEqual from 'lodash/isEqual';

import {
  type DisplayTheme,
  type DocumentId,
  type Highlight,
  type MobileImageModalData,
} from '../../../types';
import type { HighlightResizeState } from '../../../types/highlights';
// eslint-disable-next-line import/no-cycle
import {
  CancelStateUpdate,
  getDefaultFullZustandState,
  globalState,
  updateFocusedHighlightIdState,
  updatePropertyInState,
  updateState,
} from '../../models';
// eslint-disable-next-line import/no-cycle
import { StateUpdateOptionsWithoutEventName } from '../../types';

export const setFocusedDocumentId = async (
  documentId: string | null,
  options: StateUpdateOptionsWithoutEventName & { forceIfDropdownOpen?: boolean; },
): Promise<void> => {
  await updateState(
    (state) => {
      const prevFocusedDocId = state.focusedDocumentId;
      // Make sure we don't change the focused doc if a dropdown is open
      if (!window.isRadixDropdownOpen || options.forceIfDropdownOpen) {
        state.focusedDocumentId = documentId;
      }
      if (prevFocusedDocId === documentId) {
        throw new CancelStateUpdate();
      }
    },
    {
      ...options,
      eventName: 'focused-document-id-updated',
      shouldCreateUserEvent: false, // We don't care when this happens
      isUndoable: false,
    },
  );
};

export const setFocusedHighlightId = async (highlightId: Highlight['id'] | null): Promise<void> => {
  updateFocusedHighlightIdState((state) => {
    state.focusedHighlightId = highlightId;
  });
};

export const setOpenDocumentId = async (documentId: string | null): Promise<void> => {
  if (globalState.getState().openDocumentId === documentId) {
    return;
  }
  await updateState(
    (state) => {
      state.openDocumentId = documentId;
    },
    {
      eventName: 'open-document-id-updated',
      shouldCreateUserEvent: false, // We don't care when this happens
      isUndoable: false,
      userInteraction: null,
    },
  );
};

export const setOpenNotebookId = async (openNotebookId: string | null): Promise<void> => {
  if (globalState.getState().openNotebookId === openNotebookId) {
    return;
  }
  await updateState(
    (state) => {
      state.openNotebookId = openNotebookId;
    },
    {
      eventName: 'open-notebook-id-updated',
      shouldCreateUserEvent: false, // We don't care when this happens
      isUndoable: false,
      userInteraction: null,
    },
  );
};

export const setFocusedFeedId = async (feedId: string | null): Promise<void> => {
  await updatePropertyInState('focusedFeedId', feedId, {
    userInteraction: 'unknown',
    eventName: 'focused-feed-id-updated',
    shouldCreateUserEvent: false, // We don't care when this happens
    isUndoable: false,
  });
};

export const setFocusedTagId = async (tagId: string | null): Promise<void> => {
  await updateState(
    (state) => {
      if (state.focusedTagId === tagId) {
        throw new CancelStateUpdate();
      }
      state.focusedTagId = tagId;
    },
    {
      userInteraction: 'unknown',
      eventName: 'focused-tag-id-updated',
      shouldCreateUserEvent: false, // We don't care when this happens
      isUndoable: false,
    },
  );
};

export const setFocusedViewId = async (viewId: string | null): Promise<void> => {
  await updatePropertyInState('focusedViewId', viewId, {
    userInteraction: 'unknown',
    eventName: 'focused-view-id-updated',
    shouldCreateUserEvent: false, // We don't care when this happens
    isUndoable: false,
  });
};

export const setIsEditTagsPopoverShown = async (val: boolean): Promise<void> => {
  await updatePropertyInState('isEditTagsPopoverShown', val, {
    eventName: 'is-edit-tags-open-updated',
    userInteraction: 'unknown',
    isUndoable: false,
  });
};

export const setLinkActionUrl = async (val: string | null): Promise<void> => {
  await updatePropertyInState('linkActionsUrl', val, {
    eventName: 'link-action-url-updated',
    userInteraction: 'unknown',
  });
};

export const setScreenWidth = async (
  width: number,
  options: Omit<Parameters<typeof updateState>[1], 'eventName' | 'userInteraction'> = {},
): Promise<void> => {
  await updatePropertyInState('screenWidth', width, {
    ...options,
    isUndoable: false,
    eventName: 'set-screen-width',
    userInteraction: null,
  });
};

export const addRouteToNavigationStack = async (route: string): Promise<void> => {
  await updateState(
    (state) => {
      // Only save the last 5 routes
      const newStack = [...state.routeStack.slice(-4), route];
      state.routeStack = newStack;

      const filterPreviousRoute = Array.from(newStack)
        .reverse()
        .find((route) => !route.includes('/filter/'));
      if (filterPreviousRoute) {
        state.filterPreviousRoute = filterPreviousRoute;
      }
    },
    { isUndoable: false, eventName: 'route-changes', userInteraction: null },
  );
};

export const setHighlightIdToOpenAt = (
  highlightId: DocumentId | null,
  options: StateUpdateOptionsWithoutEventName,
): ReturnType<typeof updateState> | undefined => {
  // We unnecessarily set highlightIdToOpenAt to null too much. This is a quick hack to avoid wasted state updates.
  // In an ideal world, we would set highlightIdToOpenAt to null only once we're done using it, but that is hard!
  if (highlightId === null && globalState.getState().highlightIdToOpenAt === null) {
    return;
  }
  return updateState(
    (state) => {
      state.highlightIdToOpenAt = highlightId;
    },
    { ...options, isUndoable: false, eventName: 'set-highlight-id-to-open-at' },
  );
};

export const toggleAppearancePanelOpen = (options: StateUpdateOptionsWithoutEventName): void => {
  updateState(
    (state) => {
      window.isRadixDropdownOpen = !state.isAppearancePanelShown;
      state.isAppearancePanelShown = !state.isAppearancePanelShown;
    },
    { ...options, eventName: 'appearance-panel-toggled' },
  );
};

export const setAppearancePanelOpen = async (
  isOpen: boolean,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      window.isRadixDropdownOpen = isOpen;
      state.isAppearancePanelShown = isOpen;
    },
    { ...options, eventName: 'appearance-panel-opened' },
  );
};

export const toggleVideoSettingsPanelOpen = (options: StateUpdateOptionsWithoutEventName): void => {
  updateState(
    (state) => {
      state.isVideoSettingsPanelShown = !state.isVideoSettingsPanelShown;
    },
    { ...options, eventName: 'video-settings-panel-toggled' },
  );
};

export const setVideoSettingsPanelOpen = async (
  isOpen: boolean,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      state.isVideoSettingsPanelShown = isOpen;
    },
    { ...options, eventName: 'video-settings-panel-opened' },
  );
};

export const setMobileDocumentImageModalData = async (modalData: MobileImageModalData) => {
  await updateState(
    (state) => {
      if (isEqual(modalData, state.mobileDocumentImageModalData)) {
        throw new CancelStateUpdate();
      }
      state.mobileDocumentImageModalData = modalData;
    },
    {
      eventName: 'mobile-document-image-modal-data-updated',
      userInteraction: null,
      shouldCreateUserEvent: false,
    },
  );
};

export const setMobileImageModalDataLinkAction = async (modalData: MobileImageModalData) => {
  await updateState(
    (state) => {
      if (isEqual(modalData, state.mobileImageModalDataLinkAction)) {
        throw new CancelStateUpdate();
      }
      state.mobileImageModalDataLinkAction = modalData;
    },
    {
      eventName: 'mobile-document-image-modal-data-updated',
      userInteraction: null,
      shouldCreateUserEvent: false,
    },
  );
};

export const updateMobileLoggedInRootContainerNavBarHeight = async (height: number) => {
  await updateState(
    (state) => {
      if (state.client.mobileHomeNavBarHeight === height) {
        throw new CancelStateUpdate();
      }
      state.client.mobileHomeNavBarHeight = height;
    },
    {
      eventName: 'mobile-nav-bar-height-updated',
      userInteraction: 'unknown',
      isUndoable: false,
      shouldCreateUserEvent: false,
    },
  );
};

export const setWebEffectiveTheme = (theme: DisplayTheme) =>
  updatePropertyInState('webEffectiveTheme', theme, {
    eventName: 'web-effective-theme-updated',
    userInteraction: null,
    isUndoable: false,
  });

export const setGPTPromptLoadingStatus = (loading: boolean) => {
  updatePropertyInState('gptPromptLoading', loading, {
    eventName: 'set-gpt-loading-state',
    shouldCreateUserEvent: false,
    userInteraction: 'unknown',
    isUndoable: false,
  });
};

export const setMobileActiveTab = (mobileActiveTab: string) => {
  updatePropertyInState('mobileActiveTab', mobileActiveTab, {
    eventName: 'set-mobile-active-tab',
    shouldCreateUserEvent: false,
    userInteraction: 'unknown',
    isUndoable: false,
  });
};

export const setVideoHeaderOpen = async (isOpen: boolean): Promise<void> => {
  await updatePropertyInState('isVideoHeaderShown', isOpen, {
    eventName: 'video-header-opened',
    userInteraction: 'click',
  });
};

export const toggleVideoHeaderOpen = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.isVideoHeaderShown = !state.isVideoHeaderShown;
    },
    { eventName: 'video-header-opened', userInteraction: 'click' },
  );
};

export const toggleIsPdfSnapToolEnabled = async (
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      state.isPdfSnapToolEnabled = !state.isPdfSnapToolEnabled;
    },
    { ...options, isUndoable: false, eventName: 'is-pdf-snap-tool-toggled' },
  );
};

export const setIsPdfSnapToolEnabled = async (
  value: boolean,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      state.isPdfSnapToolEnabled = value;
    },
    { ...options, isUndoable: false, eventName: 'is-pdf-snap-tool-set' },
  );
};

export const resetZustandState = () => {
  globalState.setState(() => ({ ...getDefaultFullZustandState() }));
};

export const setOpenedAnnotationBarPopoverHighlightId = async (
  highlightId: Highlight['id'] | null,
): Promise<void> => {
  if (globalState.getState().openedAnnotationBarPopoverHighlightId === highlightId) {
    return;
  }
  updateState(
    (state) => {
      state.openedAnnotationBarPopoverHighlightId = highlightId;
    },
    { eventName: 'opened-annotation-bar-popover-highlight-id-set', userInteraction: 'click' },
  );
};

export const setPathNameToRedirectAfterOnboarding = async (pathName: string | null): Promise<void> => {
  updateState(
    (state) => {
      state.pathNameToRedirectAfterOnboarding = pathName;
    },
    { eventName: 'pathname-to-redirect-after-onboarding-set', userInteraction: 'click' },
  );
};

export const setFilteredViewIdToDelete = async (
  filteredViewIdToDelete: string | null,
): Promise<void> => {
  updateState(
    (state) => {
      state.filteredViewIdToDelete = filteredViewIdToDelete;
    },
    { eventName: 'view-id-to-delete-set', userInteraction: 'unknown' },
  );
};

export const setFeedIdToDelete = async (feedIdToDelete: string | null): Promise<void> => {
  updateState(
    (state) => {
      state.feedIdToDelete = feedIdToDelete;
    },
    { eventName: 'feed-id-to-delete-set', userInteraction: 'unknown' },
  );
};

export const setFeedIdsToAddInFolder = async (
  feedIdsToAddInFolder: string[],
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  updateState(
    (state) => {
      state.feedIdsToAddInFolder = feedIdsToAddInFolder;
    },
    { ...options, isUndoable: false, eventName: 'children-filtered-view-ids-to-add-in-folder-set' },
  );
};

export const toggleIsMobileCPUThrottled = async (): Promise<void> => {
  updateState(
    (state) => {
      state.isMobileCPUThrottled = !state.isMobileCPUThrottled;
    },
    { eventName: 'toggle-mobile-cpu-throttle', userInteraction: 'tap', isUndoable: false },
  );
};

export const toggleAppReviewDebugMode = async (): Promise<void> => {
  updateState(
    (state) => {
      state.isMobileAppReviewDebugging = !state.isMobileAppReviewDebugging;
    },
    { eventName: 'toggle-mobile-app-review-debug-mode', userInteraction: 'tap', isUndoable: false },
  );
};

/*
  The source of truth for this is inside the content frame. Changes are made inside and then synced out
  and applied to Zustand using this function.
*/
export async function setHighlightResizeState(value: HighlightResizeState) {
  if (isEqual(value, globalState.getState().highlightResizeState)) {
    return;
  }
  await updateState(
    (state) => {
      state.highlightResizeState = value;
      if (state.highlightResizeState.status === 'actively-resizing') {
        state.focusedHighlightId = null;
      }
    },
    { eventName: 'highlight-resize-state-updated', isUndoable: false, userInteraction: 'unknown' },
  );
}

export const unsetFocusedHighlightIdIfSafe = async (): ReturnType<typeof setFocusedDocumentId> => {
  if (globalState.getState().highlightResizeState.status !== 'inactive') {
    return;
  }
  return setFocusedHighlightId(null);
};
