import PortalGate, { CallResponsePayload } from '../../../../PortalGate';
// eslint-disable-next-line import/no-cycle
import * as methods from '../../../contentFramePortalGateInternalMethods';
import foregroundEventEmitter from '../../../eventEmitter';
import type * as foregroundMethods from '../../../methods';
import { ContentFrameEventMessageArgumentMap } from '../../../types/events';

/*
  This is the portal gate to the foreground from the content frame. Specifically, when both
  the foreground and content frame aren't separated into multiple processes / webviews. See
  the equivalent file in `../to` for more info.
*/

export const portalGate = new PortalGate<typeof foregroundMethods, ContentFrameEventMessageArgumentMap>({
  getGateName: () => 'ContentFrame',
  localMethods: methods,
  loggingPrefix: '📖',
  loggingColor: 'navy',
  portalName: 'foreground-contentFrame',
  sendMessage: async ({ message, data }) => {
    foregroundEventEmitter.emit(message, data);
  },
});

foregroundEventEmitter.onAny((event: string | string[], data: CallResponsePayload['data']) => {
  portalGate.handleIncomingMessage({ message: event as string, data });
});

export default portalGate.methods;
