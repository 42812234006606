export const getOriginalEmailStyles = () => `
<style>
  table, tr, tbody {
    width: 100% !important;
  }

  table, tr, td, tbody {
    max-width: 100% !important;
  }

  table, tr, td, tbody {
    overflow-x: scroll;
  }

  .document-content--original-email {
    padding-left: 10px;
    padding-right: 10px;
  }
</style>
`;
