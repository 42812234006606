import sanitizeHtml, { type Attributes, type Tag } from 'sanitize-html';

import { Category } from '../../types';

export const allowVulnerableTags = true;

export const defaultAllowedClasses: { [key: string]: string[]; } = {
  article: ['rw-embedded-tweet'],
  hr: ['twitter-thread-delimiter'],
  p: ['rw-outer-content', 'rw-ai', 'rw-hide'],
  span: ['rw-ai', 'rw-ai-explainer', 'rw-ai-highlighted'],
  li: ['rw-ai'],
  div: ['rw-digest-thread-wrapper', 'rw-embed-wrapper'],
};

export const defaultAllowedSchemesByTag = {
  img: sanitizeHtml.defaults.allowedSchemes.concat(['data']),
};

export function getDefaultAllowedTags() {
  return sanitizeHtml.defaults.allowedTags.concat([
    'img',
    'video',
    'source',
    'svg',
    'path',
    'picture',
    'center',
    'embed',
    'details',
    'summary',
  ]);
}

export function getDefaultAllowedAttributes(
  attributesAllowedOnAnyTag: string[],
  allowImageStyles: boolean,
) {
  return {
    a: ['href', 'name', 'target', 'rel'], // eslint-disable-line id-length
    img: ['src', 'referrerpolicy', ...(allowImageStyles ? ['style', 'width', 'height', 'class'] : [])], // eslint-disable-line @typescript-eslint/no-extra-parens
    source: ['src', 'type', 'srcset', 'media'],
    video: ['controls', 'src'],
    embed: ['src'],
    path: ['*'],
    svg: ['*'],
    ol: ['start'],
    th: ['rowspan', 'colspan'],
    td: ['rowspan', 'colspan'],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '*': attributesAllowedOnAnyTag,
  };
}

export function getDefaultTransformTags(allowImageStyles: boolean, category: Category) {
  return {
    svg(tagName: string, attribs: Attributes): Tag {
      const viewBox = attribs.viewbox;
      if (viewBox) {
        try {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_x, _y, width, height] = viewBox.replace(',', '').split(' ');
          attribs.width = width;
          attribs.height = height;
        } catch (e) {
          // do nothing
        }
      } else {
        // Pick some default size for this SVG, so it isn't humungous.
        attribs.width = '32';
        attribs.height = '32';
      }
      return {
        tagName,
        attribs,
      };
    },
    img: (tagName: string, attribs: Attributes) => {
      attribs.referrerpolicy = 'no-referrer';
      if (allowImageStyles) {
        attribs.class = 'allow-image-styles';

        // Preserve width/height properties _unless_ they're a % as they'll likely break our formatting
        // because we don't preserve their parent properties often...
        if (typeof attribs.height === 'string') {
          // In case of height attribute, if its set to specific value, we replace height with max-height, to preserve image ratio when on mobile or small screen device
          if (!attribs.height.includes('%')) {
            attribs.style = attribs.style
              ? `${attribs.style}; max-height: ${attribs.height}px;`
              : `max-height: ${attribs.height}px;`;
          }
          delete attribs.height;
        }
        // If style attribute includes height, change it to max-height
        if (typeof attribs.style === 'string') {
          if (attribs.style.startsWith('height:')) {
            attribs.style = attribs.style.replace('height:', 'max-height:');
          }
          if (attribs.style.includes(' height:')) {
            attribs.style = attribs.style.replace(' height:', ' max-height:');
          }
          if (attribs.style.includes(';height:')) {
            attribs.style = attribs.style.replace(';height:', ';max-height:');
          }
        }
        if (typeof attribs.width === 'string' && attribs.width.includes('%')) {
          delete attribs.width;
        }
        if (attribs.style?.includes('width: 100%')) {
          attribs.style = attribs.style.replace('width: 100%', '');
        }
        if (attribs.style?.includes('height: 100%')) {
          attribs.style = attribs.style.replace('height: 100%', '');
        }
      }
      return {
        attribs,
        tagName: 'img',
      };
    },
    a: (tagName: string, attribs: Attributes) => {
      const isInternalLink = attribs?.href?.startsWith('#');
      if (!isInternalLink) {
        attribs.target = '_blank';
      }
      attribs.rel = 'noreferrer';
      return {
        attribs,
        tagName: 'a',
      };
    },
  };
}
