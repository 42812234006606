import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

/**
 * Coerce the input into an Array. If the input is already an Array, it will
 * create a copy of the original Array.
 *
 * @param input
 */
export default function asArray<T>(input: T | T[] | { [key: string]: T; }): T[] {
  if (isArray(input)) {
    return Array.from(input);
  }
  if (isObject(input)) {
    return Object.values(input);
  }
  return [input];
}
