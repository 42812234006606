/*
  This is an exceptional case where a cycical dependency breaks mobile. If we import the portal gate to the foreground
  here, it will break. The bundler will give up and replace stuff with `undefined`.
  This actually imports:
  - Mobile: `shared/foreground/database/index.ts`.
  - Everything else: `shared/background/databaseSpecialCase.ts` (which exports the background database)
*/
import database from '_database-special-case';

import clearLocalRxdbData from '../database/clearLocalRxdbData.platform';
import { removeAllDatabases } from '../database/internals/migration/helpers.platform';
// eslint-disable-next-line restrict-imports/restrict-imports
import type { AnyDocumentWithContent } from '../foreground/documentSearchEngine';
// eslint-disable-next-line restrict-imports/restrict-imports
import type { StoreItemEventArgument, StoreItemEventName, StoreName } from '../foreground/types/events';
// eslint-disable-next-line import/no-cycle,restrict-imports/restrict-imports
import networkDetector from '../networkDetector.platform';
import reloadApplication from '../reloadApplication.platform';
import {
  ContentParsingStatus,
  DocumentContent,
  DocumentId,
  FirstClassDocument,
  Profile,
  RSSSuggestion,
  StateSyncingOptions,
  UserEventWithDataUpdate,
} from '../types';
import { notEmpty } from '../typeValidators';
import delay from '../utils/delay';
import { isExtension, isMobile, isTest } from '../utils/environment';
import exceptionHandler from '../utils/exceptionHandler.platform';
import getServerBaseUrl from '../utils/getServerBaseUrl.platform';
import HttpError from '../utils/HttpError';
import makeLogger from '../utils/makeLogger';
import promiseAllSettled from '../utils/promiseAllSettled.platform';
import requestWithAuth from '../utils/requestWithAuth.platformIncludingExtension';
import { shouldIncludeDocumentInSearch } from '../utils/shouldIncludeDocumentInSearch';
import { Cache as OurCache } from './cache.platform';
import onNextUserInteraction from './onNextUserInteraction.platform';
// eslint-disable-next-line import/no-cycle
import foreground, { portalGate as foregroundPortalGate } from './portalGates/toForeground';
import { StateSyncer } from './stateSyncing';
import stores from './stores';

const logger = makeLogger(__filename);

let stateSyncer: StateSyncer;

let isAllowedToFetchAvailableRssFeeds = false;
const lastFetchRelatedRSSCallArguments: string[] = [];

let profileData: Profile;

export const init = async (options: Omit<StateSyncingOptions, 'database'>): Promise<void> => {
  await database.initialize({
    onSchemaConflictError: foreground.onDatabaseSchemaConflict,
  });

  // Always use our background's database for syncing.
  stateSyncer = new StateSyncer({ ...options, database });
  stateSyncer.onPersistentStateLoadedFromServer = async () => {
    logger.debug('onPersistentStateLoadedFromServer called');
    // TODO: really this should wait until hybrid storage replication is done.
    await delay(15000);
    logger.debug('Loading all document content');
    await loadDocumentContentInBackground();
  };

  // Listen to all store events and emit events across the foreground portal gate
  const foregroundGate = foregroundPortalGate as NonNullable<typeof foregroundPortalGate>;
  for (const [name, store] of Object.entries(stores)) {
    store.onAny((event: string | string[], data: unknown) => {
      foregroundGate.emit(
        `stores__${name as StoreName}__${event as StoreItemEventName}`,
        data as StoreItemEventArgument,
      );
    });
  }

  if (isExtension) {
    return;
  }

  // Let's avoid impacting the initial load / performance too much
  // eslint-disable-next-line promise/catch-or-return
  delay().then(async () => {
    if (!isMobile) {
      // Lighthouse, Calibre, etc. will notice if we only use a timeout and lower our perf score
      await onNextUserInteraction();
      // update foreground state only when retrieving LinkSets by ID: when fetching from cache or upserting from server.
      stores.documentLinks.onItemsReceivedById(({ foundItems }) => {
        foreground.updateDocumentLinks(foundItems);
      });
    }

    await delay(3000);
    await setUpRelatedRSS();
  });
};

export const getCacheItem = OurCache.getItem;
export const setCacheItem = OurCache.setItem;
export const getCacheKeys = OurCache.keys;
export const removeCacheItems = OurCache.removeItems;

// NOTE: this uses the Cache Storage API, not our typical cache
type CachePut = (typeof Cache)['prototype']['put'];
export const putItemInCacheStorage = async (
  cacheName: Parameters<typeof caches.open>[0],
  request: Parameters<CachePut>[0],
  responseBody: ConstructorParameters<typeof Response>[0],
): ReturnType<CachePut> => {
  const cache = await caches.open(cacheName);
  return cache.put(request, new Response(responseBody));
};

export const triggerCloudSyncs = async (): Promise<void> => {
  const options: RequestInit & { headers: { [key: string]: string; }; } = {
    credentials: 'include',
    headers: {},
    method: 'GET',
    mode: 'cors',
  };
  try {
    await requestWithAuth(`${getServerBaseUrl()}/reader/api/trigger_cloud_syncs/`, options);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (!e?.response?.status || e?.response?.status !== 403) {
      throw e;
    }
  }
};

export const uploadInstapaperCSV = async (file: FormData): Promise<Response> => {
  const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/instapaper_csv/`, {
    credentials: 'include',
    method: 'POST',
    mode: 'cors',
    body: file,
  });

  if (!resp.ok) {
    const exception = new HttpError(resp);
    await exception.getReasonFromJson();
    throw exception;
  }

  return resp;
};

export const getRssSuggestions = async (
  query: string,
  signal?: AbortSignal,
): Promise<RSSSuggestion[]> => {
  const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/rss_feed_search?query=${query}`, {
    credentials: 'include',
    method: 'GET',
    signal,
  });
  if (!resp.ok) {
    const exception = new HttpError(resp);
    await exception.getReasonFromJson();
    throw exception;
  }
  return resp.json();
};

export const getUserFeedSuggestions = async (): Promise<RSSSuggestion[]> => {
  const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/suggested_feeds`, {
    credentials: 'include',
    method: 'GET',
  });
  if (!resp.ok) {
    const exception = new HttpError(resp);
    await exception.getReasonFromJson();
    throw exception;
  }
  return resp.json();
};

export const clearAllLocalData = async (shouldReloadApplication = true): Promise<void> => {
  // Don't clear the cache while there's any content in the queues -- that'll break stuff!
  if (stateSyncer) {
    stateSyncer.syncingStopped = true;
    let timeWaiting = 0;
    while (!stateSyncer.safeToInterrupt() && timeWaiting < 5000) {
      await delay(100);
      timeWaiting += 100;
    }
  }

  await foreground.disableAllDatabaseHooks();

  await promiseAllSettled([
    removeAllDatabases(),
    OurCache.clear(),
    ...Object.values(stores).map((store) => store.clearCache()),
    foreground.searchDestroy(),
  ]);
  await clearLocalRxdbData();

  if (!isExtension && shouldReloadApplication) {
    await reloadApplication();
  }
};

export const getCacheDataForDebugging = async () => {
  return stateSyncer.getCacheDataForDebugging();
};

export const downloadSyncerCacheForDebugging = async (): Promise<void> => {
  stateSyncer.downloadCacheForDebugging();
};

export function watchLinksReceivedFromServer(parsedDocIds: DocumentId[]): { unsubscribe: () => void; } {

  /*
   * (Temporarily) subscribe Zustand state to LinkSet updates from the server for a specific set of documents.
   * Usually updated LinkSets are only saved in the Store, not in the Zustand state, for performance reasons.
   *
   * @param parsedDocIds parsed doc IDs to subscribe to LinkSet updates for
   * @return object with unsubscribe callback
   */
  const watchedIds = new Set(parsedDocIds);
  const listener = stores.documentLinks.onItemsReceivedFromServer((items) => {
    const linkSetsToUpdate = items.filter((item) => watchedIds.has(item.parsed_doc_id));
    foreground.updateDocumentLinks(linkSetsToUpdate);
  });
  return { unsubscribe: () => listener.off() };
}

export const loadDocumentLinksByIds = async (
  ...args: Parameters<typeof stores.documentLinks.loadByIds>
): Promise<void> => stores.documentLinks.loadByIds(...args);
export const loadDocumentContentByIds = (
  ...args: Parameters<typeof stores.documentContent.loadByIds>
): ReturnType<typeof stores.documentContent.loadByIds> => stores.documentContent.loadByIds(...args);
export const loadChunkedDocumentContentByIds = stores.chunkedDocumentContent.loadByIds.bind(
  stores.chunkedDocumentContent,
);

export const clearAllDocumentContentStores = () =>
  Promise.all([stores.documentContent.clearCache(), stores.chunkedDocumentContent.clearCache()]);

// TODO: remove the following variable once we're loading all content
let hasCalledPopulateDocumentSearchEngine = false;

function isUsingInternalDebuggingTool(): boolean {
  return !isMobile && window.location.pathname.endsWith('/database-explorer');
}

export async function loadDocumentContentInBackground(): Promise<void> {
  if (
    hasCalledPopulateDocumentSearchEngine ||
    stores.documentContent.hasLoadedAll ||
    isUsingInternalDebuggingTool()
  ) {
    return;
  }
  logger.debug('loadDocumentContentInBackground start');
  hasCalledPopulateDocumentSearchEngine = true;
  let totalIndexed = 0;
  const onDocumentContentsReceived = async (documentContentBatch: DocumentContent[]) => {
    // documentContentBatch will have at most 120 items in it, since that's the page limit of the document content API endpoint.
    // That's few enough so that indexing them in bulk is safe i.e. it won't freeze up the main thread.
    const timerId = Math.random().toString().slice(2);
    const parsedDocIds = documentContentBatch.map(({ id }) => id);
    logger.time(`loadDocumentContentInBackground findDocsForParsedDocIds ${timerId}`);
    const docsWithParsedDocId = await foreground.findDocsForParsedDocIds(parsedDocIds);
    logger.timeEnd(`loadDocumentContentInBackground findDocsForParsedDocIds ${timerId}`);

    const itemsToAddToSearchDb: FirstClassDocument[] = documentContentBatch
      // eslint-disable-next-line @typescript-eslint/naming-convention
      .map(({ id: parsed_doc_id, status, html: html_content }) => {
        const doc = docsWithParsedDocId[parsed_doc_id]?.[0];
        if (status !== ContentParsingStatus.Success || !doc || !shouldIncludeDocumentInSearch(doc)) {
          return null;
        }
        const docToUpsert: AnyDocumentWithContent = {
          ...doc,
          html_content,
        };
        return docToUpsert;
      })
      .filter(notEmpty);

    if (itemsToAddToSearchDb.length === 0) {
      logger.debug('loadDocumentContentInBackground no items to index, skipping', {
        documentContentBatchCount: documentContentBatch.length,
        parsedDocIds,
      });
      return;
    }
    logger.time(`loadDocumentContentInBackground searchUpsertDocuments ${timerId}`);
    await foreground.searchUpsertDocuments(itemsToAddToSearchDb);
    totalIndexed += itemsToAddToSearchDb.length;
    logger.timeEnd(`loadDocumentContentInBackground searchUpsertDocuments ${timerId}`);

    logger.debug('loadDocumentContentInBackground after searchUpsertDocuments', {
      parsedDocIds,
      itemsToAddToSearchDb: itemsToAddToSearchDb.length,
      totalIndexed,
    });
  };

  await foreground.searchInit();
  const contentStore = await foreground.shouldUseChunkedDocumentContent()
    ? stores.chunkedDocumentContent
    : stores.documentContent;
  // From here on, if any new items are fetched and loaded into cache, push them into search
  contentStore.on('items-received-from-server', onDocumentContentsReceived);
  contentStore.loadAll();
}

const setUpRelatedRSS = async () => {
  isAllowedToFetchAvailableRssFeeds = true;
  if (lastFetchRelatedRSSCallArguments.length) {
    stores.knownRssFeeds.loadByIds(lastFetchRelatedRSSCallArguments);
  }
  stores.knownRssFeeds.on(
    'items-received-by-id',
    ({
      foundItems,
      missingIds,
    }: {
      foundItems: RSSSuggestion[];
      missingIds: string[];
    }) => {
      foreground.onRSSFeedsLoaded(foundItems, missingIds);
    },
  );
};

export const loadRelatedRSSFeedsForDomains = (domains: string[]): Promise<void> => {
  if (!isAllowedToFetchAvailableRssFeeds) {
    lastFetchRelatedRSSCallArguments.push(...domains);
    return Promise.reject(new Error('Not allowed yet. Will fetch once allowed.'));
  }
  return stores.knownRssFeeds.loadByIds(domains);
};

// Syncing helper functions:

export const pollLatestState = async (
  timesToPoll: number,
  shouldTriggerCloudSyncs = false,
): Promise<void> => {
  // If user is offline, try later
  if (!networkDetector.isOnline) {
    setTimeout(() => {
      pollLatestState(timesToPoll, shouldTriggerCloudSyncs);
    }, 1500);
    return;
  }

  if (shouldTriggerCloudSyncs) {
    triggerCloudSyncs();
  }

  if (stateSyncer) {
    // TODO: timesToPoll is just a hack until we can know when an instapaper/pocket sync is done
    await stateSyncer.syncServerStateForNext(5);
  }
  if (timesToPoll > 1) {
    setTimeout(() => pollLatestState(timesToPoll - 1), 1500);
  }
};

export const setUpInitialState = async (): ReturnType<StateSyncer['setUpInitialState']> => {
  if (!stateSyncer) {
    throw new Error('stateSyncer does not exist yet!');
  }
  return stateSyncer.setUpInitialState();
};

export const queueStateUpdateFromForeground = async (event: UserEventWithDataUpdate): Promise<void> => {
  return stateSyncer.queueStateUpdateFromForeground(event);
};

export const getAndSetProfileData = async (): Promise<Profile> => {
  const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/profile_details/`, {
    credentials: 'include',
    method: 'GET',
  });
  if (!resp.ok) {
    const exception = new HttpError(resp);
    await exception.getReasonFromJson();
    throw exception;
  }

  profileData = await resp.json();
  foreground.setProfile(profileData, { userInteraction: null });
  exceptionHandler.setUser({ id: profileData.profile_id, email: profileData.email });

  return profileData;
};

// Utility functions just for testing:
export const _syncingTestHelper = (action: string) => {
  if (!isTest) {
    throw new Error('Can only use _testHelper in tests');
  }
  logger.debug(`stateSyncing:${action}`);

  if (!stateSyncer) {
    logger.warn("Can't apply syncing test helper since stateSyncer is not initialized yet.");
    return;
  }

  if (action === 'stopSyncing') {
    stateSyncer.syncingStopped = true;
  } else if (action === 'resumeSyncing') {
    stateSyncer.syncingStopped = false;
    stateSyncer.consumeUpdateQueues();
  }
};

export const checkIfNonceExists = async (nonce: string) => {
  const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/nonce_exists?nonce=${nonce}`, {
    credentials: 'include',
    method: 'GET',
    mode: 'cors',
  });
  if (resp.ok) {
    const data = await resp.json();
    return data.exists;
  }
};

export const tellStateSyncerThatAppFocusChanged = (isFocused: boolean) => {
  if (stateSyncer) {
    stateSyncer.onAppFocusChange(isFocused);
  }
};
