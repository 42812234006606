/* eslint-disable no-plusplus */

import { isTextNode } from '../../../typeValidators';
import makeLogger from '../../../utils/makeLogger';
import replaceStringStrict from '../../../utils/replaceStringStrict';
import type { RangyClassApplier, RangyDomPosition, RangyRange, RangySelection } from '../../types/rangy';
// eslint-disable-next-line import/no-cycle
import { removeCustomHighlightElementDescendants } from '../cleanUpHtmlForHighlighting';
import findLastIndex from '../findLastIndex';
// eslint-disable-next-line import/no-cycle
import getRangeFromNodes from '../getRangeFromNodes';
import isHighlightNode from '../isHighlightNode';
import isImage from '../isImage';
import rangy, { getDomPosition } from '../rangy';
// eslint-disable-next-line import/no-cycle
import Renderer from '../Renderer';
import { splitSerializedRange, unparseSerializedPosition } from './utils';

const logger = makeLogger(__filename);

/*
  We use Rangy's highlighter module
  (https://github.com/timdown/rangy/wiki/Highlighter-Module) for injecting highlight
  elements, etc. Rangy has a module for de/serializing locations
  (https://github.com/timdown/rangy/wiki/Serializer-Module) but it's not compatible
  with the highlighter module (https://github.com/timdown/rangy/issues/438), i.e.
  the injected elements throw off the de/serialization.

  So we have to use our own custom logic for de/serialization. This is based on
  Rangy's serializer as much as possible. In fact, it was started by copying their
  internal functions and modifying them.

  So the best place to start is actually the Rangy documentation on the serialized
  string format and its serialization methods:
  https://github.com/timdown/rangy/wiki/Serializer-Module.

  How our logic is different:
  1. When serializing (creating a string representation of the location/selection),
    it tries to describe an untouched page / it treats the page as it was before
    our extension touched it. I.e. it ignores and actively discounts our elements.
    So for example, when recording the index
    of an element, it'll do some subtractions based on how many preceeding siblings
    are highlight elements.
  2. When deserializing (creating a Selection object from a location, in order to
    re-highlight some text on load), the opposite will be done.
*/

const isTextLikeNode = (node?: Node | null): boolean => isTextNode(node) || isHighlightNode(node);

const getNodeOffsetDisplacement = (node: Node, classApplier: RangyClassApplier): number => {
  if (
    !node ||
    classApplier.isIgnorableWhiteSpaceNode(node) ||
    // Word-Joiner character (used in resize handles)
    node.nodeValue && /^(\u2060)+$/.test(node.nodeValue) ||
    isTextNode(node) && !node.textContent?.trim()
  ) {
    return 0;
  }

  if (node.childNodes.length) {
    const childNodes = Array.from(node.childNodes).filter(
      (childNode) => !Renderer.isCustomHighlightChild(childNode),
    );
    // Only count from the last <br> if there is one, for example
    const indexOfLastNonTextLikeNode = findLastIndex(
      childNodes,
      (childNode: ChildNode) => !isTextLikeNode(childNode),
    );
    return childNodes
      .slice(indexOfLastNonTextLikeNode + 1)
      .reduce((total, childNode) => total + getNodeOffsetDisplacement(childNode, classApplier), 0);
  }

  const range = getRangeFromNodes([node]);
  return range.endOffset - range.startOffset;
};

// Reverse source-order
const getPreviousSiblings = (node: Node) => {
  const results = [];
  let previousSibling = node?.previousSibling;
  while (previousSibling) {
    results.push(previousSibling);
    previousSibling = previousSibling.previousSibling;
  }
  return results;
};

// ([0, 1, 1, 0], Boolean, true) => [1, 1]
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNextGroupOfMatchingItems = <T = any>(
  items: T[],
  matcher: (current: T, previous: T, next: T) => boolean,
  shouldIgnoreLeadingMismatches?: boolean,
) => {
  const results = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (matcher(item, items[i - 1], items[i + 1])) {
      results.push(item);
    } else if (!(shouldIgnoreLeadingMismatches && !results.length)) {
      break;
    }
  }
  return results;
};

const getDirectlyPreviousTextLikeNodes = (node: ChildNode) =>
  getNextGroupOfMatchingItems(
    getPreviousSiblings(node),
    (sibling: ChildNode, previous: ChildNode) => {
      if (!isTextLikeNode(sibling)) {
        return false;
      }
      // Only count from closest <br> if there was one
      return (
        !previous ||
        [previous, ...previous.childNodes].every(
          (previousNode: Node | Element) =>
            !('tagName' in previousNode) || previousNode.tagName.toLowerCase() !== 'br',
        )
      );
    },
    false,
  );

const findChildWhichContainsOffset = ({
  classApplier,
  indexToStartAt,
  isEndPosition,
  node,
  offset,
}: {
  classApplier: RangyClassApplier;
  indexToStartAt: number;
  isEndPosition?: boolean;
  node: Node;
  offset: number;
}): {
  index: number;
  node: Node;
  newOffset: number;
} => {
  const children = node.childNodes;
  let index;
  let newOffset = offset;
  let offsetTraversed = 0;

  for (let j = indexToStartAt; j < children.length; j++) {
    const child = children[j];
    const nodeOffsetDisplacement = getNodeOffsetDisplacement(child, classApplier);
    const elementEndOffset = offsetTraversed + nodeOffsetDisplacement;
    if (
      elementEndOffset > offset ||
      elementEndOffset === offset && (isEndPosition || isImage(child))
    ) {
      index = j;
      newOffset -= offsetTraversed;

      /*
        Sometimes the deepest node accordingy to the serialized string isn't
        actually the deepest node. It may be split / have two children;
        a highlight element from another highlight, then a text element.
        When this happens, we this function again on the result.
      */
      if (
        children[index].childNodes.length &&
        !Array.from(children[index].childNodes).every(isTextNode)
      ) {
        const innerResult = findChildWhichContainsOffset({
          classApplier,
          indexToStartAt: 0,
          isEndPosition,
          node: children[index],
          offset: newOffset,
        });

        index = innerResult.index;
        newOffset = innerResult.newOffset;
        // eslint-disable-next-line no-param-reassign
        node = isHighlightNode(innerResult.node)
          ? innerResult.node
          : (innerResult.node.parentNode as ParentNode);

        // If the result is a highlight and it contains other elements we've inserted, move down to the text node
        if (isHighlightNode(node.childNodes[index]) && node.childNodes[index].childNodes.length > 1) {
          index = Array.from(node.childNodes[index].childNodes).findIndex(isTextNode);
          // eslint-disable-next-line no-param-reassign
          node = node.childNodes[index];
        }
      }

      break;
    }
    offsetTraversed += nodeOffsetDisplacement;
  }

  if (index === undefined) {
    throw new Error("Didn't find child which contains offset");
  }

  return {
    index,
    node,
    newOffset,
  };
};

// Exported so it can be tested
export const getNumberOfNodesIfDocumentWasUntouched = (
  nodes: (Element | Node)[],
  classApplier: RangyClassApplier,
) => {
  if (!nodes.filter(isHighlightNode).length) {
    return nodes.length;
  }

  let html = '<div>';
  // eslint-disable-next-line no-restricted-syntax
  for (const node of nodes) {
    html += isTextNode(node) ? node.textContent : (node as Element).outerHTML;
  }

  // Opening highlight tag
  html = replaceStringStrict(html, new RegExp(`<${Renderer.textHighlightTagName}[^>]+>`, 'ig'), '');
  // Icon wrapper inside highlight
  html = html.replace(/ ?><\/path>/g, ' />'); // Make sure the end </path> tag uses the same form

  html = removeCustomHighlightElementDescendants(html);

  // Closing highlight tag(s)
  html = replaceStringStrict(html, new RegExp(`</${Renderer.textHighlightTagName}>`, 'ig'), '');
  html += '</div>';

  const parser = new DOMParser();
  let result = (parser.parseFromString(html, 'text/html').body.firstElementChild as HTMLElement)
    .childNodes.length;

  // Make sure leading / trailing whitespace nodes are included
  if (classApplier.isIgnorableWhiteSpaceNode(nodes[0])) {
    result++;
  }
  if (classApplier.isIgnorableWhiteSpaceNode(nodes[nodes.length - 1])) {
    result++;
  }

  return result;
};

const throwNonChildNotFoundError = ({
  index,
  isEndPosition,
  serializedLocation,
  methodName,
  node,
  nodeIndex,
}: {
  index: number;
  isEndPosition?: boolean;
  serializedLocation: string;
  methodName: string;
  node: Node;
  nodeIndex: number;
}) => {
  const message = `${methodName}() failed: node ${rangy.dom
    .inspectNode(node)
    .replace(/(^\s+)|(\s+$)/gm, '')} has no child with index ${nodeIndex}, ${index}`;
  logger.debug('[locationSerializer]', { message, isEndPosition, serializedLocation });
  throw new Error(message);
};

function serializedPositionToAbsolutePath(position: string): number[] {
  // For the purposes of comparing, we treat a position as an array of indices made of <path> + <offset>.
  const [pathString, offset] = position.split(':');
  const stringPath = pathString.split('/');
  stringPath.reverse();
  // append offset as the last path component
  stringPath.push(offset);
  return stringPath
    .map((component) => parseInt(component, 10))
    .filter((component) => !Number.isNaN(component));
}

/**
 * Compare two serialized DOM element locations e.g. '0/40:0,0/40:52' vs. '0/1/60:0,0/5/60:52'.
 * Each start position is transformed into an array of indices (node index or text offset) in descending order of importance,
 * then compared.
 *
 * @param first first serialized location e.g. '0/40:0,0/40:52'
 * @param second second serialized location e.g. '0/1/60:0,0/5/60:52'
 * @return less than 0 if `first` comes before `second` in the DOM, > 0 if after, 0 if they're the same location, undefined if location formats invalid.
 */
export function compareSerializedLocations(
  first: string | undefined,
  second: string | undefined,
): number | undefined {
  if (first === undefined || second === undefined) {
    return undefined;
  }
  const [firstStart, secondStart] = [first, second].map((loc) => loc.split(',')[0]);
  if (!firstStart || !secondStart) {
    return undefined;
  }
  const [firstPath, secondPath] = [firstStart, secondStart].map(serializedPositionToAbsolutePath);
  if (firstPath.length === 0 || secondPath.length === 0) {
    return undefined;
  }
  const numberOfComponents = Math.max(firstPath.length, secondPath.length);
  for (let i = 0; i < numberOfComponents; i++) {
    const [firstComponent, secondComponent] = [firstPath, secondPath].map(
      (components) => components[i] ?? 0,
    );
    if (firstComponent !== secondComponent) {
      return firstComponent - secondComponent;
    }
  }
  return 0;
}

export const deserializePosition = ({
  classApplier,
  isEndPosition,
  rootNode,
  serialized,
}: {
  classApplier: RangyClassApplier;
  isEndPosition?: boolean;
  rootNode: Node;
  serialized: string;
}): RangyDomPosition => {
  const parts = serialized.split(':');
  let node = rootNode;
  const nodeIndices = parts[0] ? parts[0].split('/') : [];
  let i = nodeIndices.length;

  // Fix the offset by subtracting the preceeding direct highlight siblings' content length
  let offset = parseInt(parts[1], 10);

  while (i--) {
    const originalNodeIndex = parseInt(nodeIndices[i], 10);
    let correctedNodeIndex: number | null = null;
    let indexToStartFindingOffsetFrom: number | undefined;

    // https://linear.app/readwise/issue/RW-7906/bug-domzarlengoicloudcom-mobile-ios-20-13-[tia-290]-connecting
    if (
      i === 0 &&
      originalNodeIndex === 0 &&
      classApplier.isIgnorableWhiteSpaceNode(node) &&
      node.nextSibling &&
      !classApplier.isIgnorableWhiteSpaceNode(node.nextSibling)
    ) {
      node = node.nextSibling;
    }

    const hasHighlightChildNodes = Array.from(node.childNodes).filter(isHighlightNode).length > 0;

    if (hasHighlightChildNodes) {
      // Adjust the start index taking into account previous (and maybe even following) highlight nodes
      for (let j = node.childNodes.length - 1; j >= originalNodeIndex; j--) {
        const previousSiblings = getPreviousSiblings(node.childNodes[j]);
        const childNodeAndPreviousSiblings = [node.childNodes[j], ...previousSiblings];

        const numberOfNodesIfDocumentWasUntouched = getNumberOfNodesIfDocumentWasUntouched(
          Array.from(childNodeAndPreviousSiblings).reverse(), // Same order as DOM
          classApplier,
        );

        if (numberOfNodesIfDocumentWasUntouched === originalNodeIndex + 1) {
          correctedNodeIndex = j;

          if (i === 0) {
            const matchingNodes = getNextGroupOfMatchingItems(
              childNodeAndPreviousSiblings,
              isTextLikeNode,
            );
            indexToStartFindingOffsetFrom =
              correctedNodeIndex -
              (matchingNodes.length -
                getNumberOfNodesIfDocumentWasUntouched(matchingNodes, classApplier));
          }

          break;
        }
      }

      if (correctedNodeIndex === null) {
        throwNonChildNotFoundError({
          index: i,
          isEndPosition,
          methodName: 'deserializePosition',
          node,
          nodeIndex: originalNodeIndex,
          serializedLocation: serialized,
        });
      }
    } else {
      correctedNodeIndex = originalNodeIndex;
      indexToStartFindingOffsetFrom = originalNodeIndex;
    }

    if (i === 0) {

      /*
        Fix the index of the deepest node if there are previous rw-highlight siblings
        by walking child nodes until the offset is reached
      */
      if (hasHighlightChildNodes) {
        const childOffsetResult = findChildWhichContainsOffset({
          classApplier,
          indexToStartAt: indexToStartFindingOffsetFrom as number,
          isEndPosition,
          node,
          offset,
        });

        correctedNodeIndex = childOffsetResult.index;
        node = childOffsetResult.node;
        offset = childOffsetResult.newOffset;
      }

      const correctedNodeIndexNumber = correctedNodeIndex as number;
      if (
        node.childNodes[correctedNodeIndexNumber]?.childNodes.length === 1 &&
        isTextNode(node.childNodes[correctedNodeIndexNumber].childNodes[0])
      ) {
        node = node.childNodes[correctedNodeIndexNumber];
        correctedNodeIndex = 0;
      }
    }

    const correctedNodeIndexNumber = correctedNodeIndex as number;
    if (correctedNodeIndexNumber < node.childNodes.length) {
      node = node.childNodes[correctedNodeIndexNumber];
    } else if (node.childNodes.length > 0 || !isTextLikeNode(node)) {

      /*
        Why does the above depend on the number of children?

        Primarily here, we want to throw an error if we come across a non-existent index.

        However, if the serialized location is incorrectly pointing at a child when there
        are no children (it's a text node), then we'll skip throwing the error, `node`
        doesn't change, and the highlight should be drawn correctly.

        Yes, the serialized location shouldn't be incorrect but let's make this
        fault-tolerant as much as possible.
      */
      throwNonChildNotFoundError({
        index: i,
        isEndPosition,
        methodName: 'deserializePosition',
        node,
        nodeIndex: originalNodeIndex,
        serializedLocation: serialized,
      });
    }
  }

  return getDomPosition(node, offset);
};

export const deserializeRange = (
  serialized: string,
  rootNode: Node,
  doc: Document,
  classApplier: RangyClassApplier,
): RangyRange => {
  const result = splitSerializedRange(serialized);

  const start = deserializePosition({
    classApplier,
    isEndPosition: false,
    rootNode,
    serialized: result.start,
  });
  const end = deserializePosition({
    classApplier,
    isEndPosition: true,
    rootNode,
    serialized: result.end,
  });

  const range = rangy.createRange(doc);
  // Make sure they're in the right order, otherwise the offsets/positions will be moved (at least in Chrome)
  if (rangy.dom.comparePoints(start.node, start.offset, end.node, end.offset) === -1) {
    range.setStartAndEnd(start.node, start.offset, end.node, end.offset);
  } else {
    range.setStartAndEnd(end.node, end.offset, start.node, start.offset);
  }
  return range;
};

// text or rw-highlight
const getTotalOffsetOfDirectlyPreviousTextLikeNodes = (
  node: ChildNode,
  classApplier: RangyClassApplier,
) =>
  getDirectlyPreviousTextLikeNodes(node).reduce(
    (sum, previousSibling) => sum + getNodeOffsetDisplacement(previousSibling, classApplier),
    0,
  );

export const serializePosition = ({
  classApplier,
  node: targetNode,
  offset,
  rootNode,
}: {
  classApplier: RangyClassApplier;
  node: Node;
  offset: number;
  rootNode: Node;
}): string => {
  if (!rootNode.contains(targetNode)) {
    throw new Error('node is not a descendant of rootNode');
  }

  const pathParts: number[] = [];
  let node: Node | null = targetNode;

  // eslint-disable-next-line no-param-reassign
  rootNode = rootNode || rangy.dom.getDocument(targetNode).documentElement;
  while (node && !node.isEqualNode(rootNode)) {
    let index = rangy.dom.getNodeIndex(node);

    // Fix the index if there are previous rw-highlight siblings
    if (node.previousSibling) {
      const previousSiblingsAndNode = [node, ...getPreviousSiblings(node)];

      index = Math.max(
        0,
        getNumberOfNodesIfDocumentWasUntouched(previousSiblingsAndNode, classApplier) - 1,
      );
    }

    /*
      The serialized string should describe the untouched
      page / as it was before we mutated it.
    */
    const allNodesAtThisLevel = node.parentNode ? Array.from(node.parentNode.childNodes) : [];
    if (

      /*
        If the position is within a highlight, skip including this
        level in the serialized string because it wouldn't exist if
        it wasn't highlighted.
      */
      !(isTextNode(node) && isHighlightNode(node.parentElement)) &&

      /*
        If the deepest level only exists because the node was split
        by a previous highlight (into a highlight element and a text
        element) then we won't include this level in the serialized
        string.
      */
      (isTextLikeNode(node) ||
        !(allNodesAtThisLevel.some(isHighlightNode) && allNodesAtThisLevel.every(isTextLikeNode)))
    ) {
      pathParts.push(index);
    }

    node = node.parentNode as Node;
  }

  // Fix the offset by adding the preceeding direct highlight siblings' content length
  const offsetResult =
    offset +
    getTotalOffsetOfDirectlyPreviousTextLikeNodes(
      (isTextNode(targetNode) && isHighlightNode(targetNode.parentElement)
        ? targetNode.parentElement
        : targetNode) as ChildNode,
      classApplier,
    );

  return unparseSerializedPosition(pathParts, offsetResult);
};

export function extractStartAndEndIfValid(range: RangyRange, containerNode: Node) {
  let { startContainer } = range;
  let { startOffset } = range;
  let { endContainer } = range;
  let { endOffset } = range;

  const isStartInContainer = containerNode.contains(startContainer);
  const isEndInContainer = containerNode.contains(endContainer);

  // Error if completey out of bounds, cap if partially
  if (!isStartInContainer) {
    if (!isEndInContainer) {
      throw new Error('Range is outside of containerNode');
    }

    const containerRange = getRangeFromNodes([containerNode]);
    startContainer = containerRange.startContainer;
    startOffset = containerRange.startOffset;
  }

  if (!isEndInContainer) {
    const containerRange = getRangeFromNodes([containerNode]);
    endContainer = containerRange.endContainer;
    endOffset = containerRange.endOffset;
  }
  return { startContainer, startOffset, endContainer, endOffset };
}

export const serializeRange = ({
  classApplier,
  containerNode,
  range,
}: {
  classApplier: RangyClassApplier;
  containerNode: Node;
  range: RangyRange;
}): string => {
  if (!containerNode) {
    throw new Error('No containerNode given');
  }
  const { startContainer, startOffset, endContainer, endOffset } = extractStartAndEndIfValid(
    range,
    containerNode,
  );

  const serializedPositions = [
    serializePosition({
      classApplier,
      node: startContainer,
      offset: startOffset,
      rootNode: containerNode,
    }),
    serializePosition({
      classApplier,
      node: endContainer,
      offset: endOffset,
      rootNode: containerNode,
    }),
  ];

  return serializedPositions.join(',');
};

export const deserializeSelection = (
  serialized: string,
  classApplier: RangyClassApplier,
  rootNode: Node = document.body,
  deserializeRangeFunc = deserializeRange,
): RangySelection => {
  const win = rangy.dom.getWindow(rootNode);
  const serializedRanges = serialized.split('|');
  const sel = rangy.getSelection(win);
  const ranges = [];
  for (let i = 0, len = serializedRanges.length; i < len; ++i) {
    ranges[i] = deserializeRangeFunc(serializedRanges[i], rootNode, win.document, classApplier);
  }
  sel.setRanges(ranges);
  return sel;
};

export const serializeSelection = (
  classApplier: RangyClassApplier,
  selection: RangySelection = rangy.getSelection(),
  containerNode: Node = document.body,
  serializeRangeFunc = serializeRange,
): string => {
  const ranges = selection.getAllRanges();
  const serializedRanges = [];
  for (let i = 0, len = ranges.length; i < len; ++i) {
    serializedRanges[i] = serializeRangeFunc({
      classApplier,
      containerNode,
      range: ranges[i],
    });
  }
  return serializedRanges.join('|');
};

export default {
  deserializePosition,
  deserializeSelection,
  serializePosition,
  serializeRange,
  serializeSelection,
  splitSerializedRange,
};
