import pick from 'lodash/pick';

import type { PointerLikeEvent, PointerLikeEventCoordinatesObject } from '../../types/browserEvents';

export default function getCoordinatesObjectFromPointerLikeEvent(
  event: PointerLikeEvent,
): PointerLikeEventCoordinatesObject {
  const parent = 'clientX' in event ? event : event.touches[0];
  return pick(parent, ['clientX', 'clientY', 'pageX', 'pageY', 'screenX', 'screenY']);
}
