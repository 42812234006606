type CaretRangeFromPoint = typeof document.caretRangeFromPoint;

function caretRangeFromPointPonyfill(x: number, y: number): ReturnType<CaretRangeFromPoint> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const caretPosition = document.caretPositionFromPoint(x, y);
  const range = document.createRange();
  range.setStart(caretPosition.offsetNode, caretPosition.offset);
  return range;
}

export default function caretRangeFromPoint(
  ...args: Parameters<CaretRangeFromPoint>
): ReturnType<CaretRangeFromPoint> {
  if ('caretRangeFromPoint' in document) {
    return document.caretRangeFromPoint(...args);
  }

  // E.g. Firefox
  return caretRangeFromPointPonyfill(...args);
}
