import get from 'lodash/get';
import set from 'lodash/set';
import type { MangoQuery, MangoQueryOperators, MangoQuerySelector, RxCollection } from 'rxdb';
import { PropertyType } from 'rxdb/dist/types/types/rx-query';

import {
  DatabaseCollectionNames,
  DatabaseCollectionNamesToDocType,
  DatabaseDocType,
  SubObjectPathResult,
} from '../../types/database';
import generateLeafNodePaths from '../../utils/generateLeafNodePaths';
import cloneQueryInput from './cloneQueryInput';
import lookUpSchemaSubObjectPaths from './lookUpSchemaSubObjectPaths';

// Modifies input
function convertBooleansToBooleanIntegers<T extends MangoQuery>(rxCollection: RxCollection, input: T) {
  if (!input.selector) {
    return input;
  }

  const pathSegmentArrays = generateLeafNodePaths(input.selector);

  const booleanFieldPaths = lookUpSchemaSubObjectPaths({
    data: rxCollection.schema.jsonSchema.properties,
    matcher: (data) => data['x-auto-convert-value-to-and-from-boolean'],
    memoizeKey: `${rxCollection.name}-x-auto-convert-value-to-and-from-boolean`,
  });

  for (const pathSegmentArray of pathSegmentArrays) {
    const currentValue = get(input.selector, pathSegmentArray);
    if (typeof currentValue !== 'boolean') {
      continue;
    }

    // Remove `$and`, `$not`, array indexes, etc, and join into dot-separated string
    const cleanStringPath = pathSegmentArray
      .filter((pathSegment) => typeof pathSegment === 'string' && !pathSegment.startsWith('$'))
      .join('.');

    const booleanDefinitionInfo = booleanFieldPaths.find((item: SubObjectPathResult) =>
      cleanStringPath.startsWith(item.path));
    if (!booleanDefinitionInfo) {
      continue;
    }
    set(input.selector, pathSegmentArray, currentValue ? 1 : 0);
  }
}

export default function optimizeMangoQuery<
  TCollectionName extends DatabaseCollectionNames,
  TCollectionDocType extends
    DatabaseCollectionNamesToDocType[TCollectionName] = DatabaseCollectionNamesToDocType[TCollectionName],
  TQuery extends MangoQuery<TCollectionDocType> = MangoQuery<TCollectionDocType>,
>(rxCollection: RxCollection<TCollectionDocType>, input?: TQuery) {
  if (!input) {
    return;
  }

  const result = cloneQueryInput(input);

  if (result.selector) {
    result.selector = simplifyQuerySelector<TCollectionDocType>(result.selector);
    result.selector = replaceIndexedFields<TCollectionDocType>(result.selector);
  }

  convertBooleansToBooleanIntegers(rxCollection, result);

  return result;
}

type DocType = DatabaseCollectionNamesToDocType['documents'];
type Selector<TDocType = DocType> = MangoQuerySelector<TDocType>;
type Operand = MangoQueryOperators<never> | PropertyType<DocType, never>;
type LogicalOperator = '$and' | '$or' | '$nor';
type Operator = keyof MangoQueryOperators<never>;

const LOGICAL_OPERATORS: LogicalOperator[] = ['$and', '$or', '$nor'];

function isLogicalOperator(operator: string): operator is LogicalOperator {
  return LOGICAL_OPERATORS.includes(operator as LogicalOperator);
}

function visitOperands(operand: Operand) {
  const logicalOperands = new Map<LogicalOperator, Operand[]>();
  const simpleOperands = new Map<Operator, Operand>();

  for (const [key, innerOperand] of Object.entries(operand)) {
    if (isLogicalOperator(key)) {
      logicalOperands.set(key, innerOperand);
    } else {
      simpleOperands.set(key as Operator, innerOperand);
    }
  }

  return {
    logicalOperands,
    simpleOperands,
  };
}

function isPrimitive(operand: Operand): boolean {
  if (operand === null) {
    return true;
  }

  return !(typeof operand === 'object' || typeof operand === 'function');
}

function isSimpleOperand(operand: Operand): boolean {
  // 1. Primitives are always considered simple
  if (isPrimitive(operand)) {
    return true;
  }

  const keys = Object.keys(operand);

  // 2. Check if there are no logical operands
  const hasLogicalOperators = keys.some(isLogicalOperator);
  if (hasLogicalOperators) {
    return false;
  }

  // 3. check recursively
  return keys.every((key) => {
    const value = keys[key];
    return isSimpleOperand(value);
  });
}

function getSingleInnerOperator(operand: Operand): false | Operator {
  const keys = Object.keys(operand);
  if (keys.length === 1) {
    return keys[0] as Operator;
  }

  return false;
}

function simplifyOperatorSelector(operator: Operator, operand: Operand): Operand {
  if (operator === '$in') {
    if (operand[operator]?.length === 1) {
      const values = operand[operator] as never[];
      return values[0];
    }
  }

  return operand;
}

function simplifyQuerySelectorOperand(operand: Operand) {
  const { logicalOperands, simpleOperands } = visitOperands(operand);
  const hasOnlySimpleOperands = logicalOperands.size === 0;

  // 1. we can't simplify further
  if (hasOnlySimpleOperands) {
    return Object.fromEntries(
      Object.entries(operand).map(([operator, innerOperand]) => {
        const innerOperator = getSingleInnerOperator(innerOperand);
        if (innerOperator) {
          return [operator, simplifyOperatorSelector(innerOperator, innerOperand)];
        }
        return [operator, innerOperand];
      }),
    );
  }

  // 2. optimize all operands in logical $and operators
  if (logicalOperands.size > 0) {
    const operators = new Set(logicalOperands.keys());

    // 1. try to hoist operands into parent when they are 1. part of an $and clause, and 2. all simple operands
    if (operators.size === 1 && operators.has('$and')) {
      for (let operands of logicalOperands.values()) {
        // find invalid queries (and do not optimize)
        // 1.1 filter for $eq on the same field
        const fieldOperations = new Map<string, number>();
        // eslint-disable-next-line guard-for-in
        for (const operand of operands) {
          const fields: string[] = Object.keys(operand);
          const values: MangoQueryOperators<never>[] = Object.values(operand);
          if (fields.length > 1) {
            for (const field of fields) {
              fieldOperations.set(field, 0);
            }
          } else if (fields.length === 1) {
            const key = fields[0];
            const value = values[0];
            if (!isSimpleOperand(value)) {
              fieldOperations.set(key, 0);
            } else if (fieldOperations.has(key)) {
              fieldOperations.set(key, fieldOperations.get(key)! + 1);
            } else {
              fieldOperations.set(key, 1);
            }
          }
        }
        const hasFieldOperationDuplicate = Array.from(fieldOperations).some(
          ([_, count]) => count > 1 || count === 0,
        );
        if (hasFieldOperationDuplicate) {
          return operand;
        }

        // 1.2 simplify operation
        operands = operands.map(simplifyQuerySelectorOperand);
        const onlySimpleSelectors = operands.every(isSimpleOperand);
        if (onlySimpleSelectors) {
          return operands.reduce(
            (final, selector) => {
              return { ...final, ...selector };
            },
            { ...Object.fromEntries(simpleOperands) },
          );
        }
      }
    }

    // 2. if every field:value combination is the same, replace with { field: { $in: […] } }
    if (operators.size === 1 && operators.has('$or')) {
      for (let operands of logicalOperands.values()) {
        operands = operands.map(simplifyQuerySelectorOperand);
        if (Object.keys(operands).length > 0) {
          const firstOperand = operands[0];
          const fields = Object.keys(firstOperand);
          if (fields.length === 1) {
            const field = fields[0];
            const canBeCollapsedTo$in = operands.every((operand) => {
              const keys = Object.keys(operand);
              return keys.length === 1 && keys[0] === field && isPrimitive(operand[keys[0]]);
            });
            if (canBeCollapsedTo$in) {
              return {
                [field]: {
                  $in: operands.map((operand) => operand[field]),
                },
              };
            }
          }
        }
      }
    }
  }

  return operand;
}

function simplifyQuerySelector<TCollectionDocType extends DatabaseDocType>(
  selector: Selector<TCollectionDocType>,
): Selector<TCollectionDocType> {
  return simplifyQuerySelectorOperand(selector as Operand) as Selector<TCollectionDocType>;
}

function replaceIndexedFields<TCollectionDocType extends DatabaseDocType>(
  selector: Selector<TCollectionDocType>,
): Selector<TCollectionDocType> {
  return selector;
}
