import { RxStorage, RxStorageInstance, RxStorageInstanceCreationParams } from 'rxdb';

import type { DatabaseCollectionNamesToDocType } from '../types/database';
import { isDesktopApp, isDevOrTest, isMobile, shouldPrintSqlQueryPlans } from '../utils/environment';
// eslint-disable-next-line import/no-cycle
import exceptionHandler from '../utils/exceptionHandler.platform';
import makeLogger from '../utils/makeLogger';
import {
  type RxStorageInstanceSQLite,
  type SQLiteInternals,
  type SQLiteQueryWithParams,
  SQLResultRow,
} from './internals/storages/storage-sqlite';

const logger = makeLogger(__filename);

// run 'ANALYZE;' every 5 minutes to optimize sqlite index selection
const SQLAnalyzeIntervalMillis = 5 * 60 * 1000;
// run 'ANALYZE;' 1 second after the storage is initialized.
const SQLAnalyzeFirstRunDelay = 10 * 1000;

const DocumentsCollectionName: keyof DatabaseCollectionNamesToDocType = 'documents';

async function initializeSQLiteStorage<Internals, InstanceCreationOptions, RxDocType>(
  storage: RxStorage<Internals, InstanceCreationOptions>,
  params: RxStorageInstanceCreationParams<RxDocType, InstanceCreationOptions>,
) {
  const instance = (await storage.createStorageInstance(
    params,
  )) as unknown as RxStorageInstanceSQLite<RxDocType>;
  const database = await (instance.internals as unknown as SQLiteInternals).databasePromise;

  if (params.collectionName === DocumentsCollectionName) {
    if (isMobile) {
      const runSqlAnalyze = async () => {
        if (!database || !instance || !instance.run) {
          return;
        }
        logger.debug('Updating SQLite stats table for better index selection.');
        const query = `ANALYZE "${DocumentsCollectionName}-${params.schema.version}"`;
        instance.run(database, {
          query,
          params: [],
          context: {
            method: 'wrappedMobileSQLiteDocumentsStorage initializeSQLiteStorage runSqlAnalyze',
            data: null,
          },
        });
      };
      setTimeout(runSqlAnalyze, SQLAnalyzeFirstRunDelay);
      setInterval(runSqlAnalyze, SQLAnalyzeIntervalMillis);
    } else if (isDesktopApp) {
      instance.run(database, {
        query: 'PRAGMA optimize;',
        params: [],
        context: {
          method: 'wrappedMobileSQLiteDocumentsStorage initializeSQLiteStorage',
          data: null,
        },
      });
    }
  }
  if (instance.all && instance.run) {
    if (shouldPrintSqlQueryPlans && isDevOrTest) {
      // Force enable all logs
      logger.shouldLog = () => true;
      const originalInstanceAll = instance.all.bind(instance);
      const logQueryPlan = async (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        db: any,
        queryWithParams: SQLiteQueryWithParams,
        startTime: number,
      ) => {
        const duration = performance.now() - startTime;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const plan: any[] = await originalInstanceAll(db, {
          ...queryWithParams,
          query: `EXPLAIN QUERY PLAN ${queryWithParams.query}`,
        });
        const planLines: string[] = plan.map(({ detail }) => detail);
        logger.debug(
          `--- SQLITE QUERY TOOK ${duration.toFixed(3)}ms\n` +
            `||| SQL: '${queryWithParams.query}'\n` +
            `||| PARAMS: ${JSON.stringify(queryWithParams.params)}\n` +
            `||| QUERY PLAN: \n${planLines.join('\n')}\n---\n\n`,
        );
        return planLines;
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      instance.all = async (db: any, queryWithParams: SQLiteQueryWithParams) => {
        const start = performance.now();
        const result = await originalInstanceAll(db, queryWithParams);
        await logQueryPlan(db, queryWithParams, start);
        return result;
      };
      const originalInstanceRun = instance.run.bind(instance);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      instance.run = async (db: any, queryWithParams: SQLiteQueryWithParams) => {
        const start = performance.now();
        const result = await originalInstanceRun(db, queryWithParams);
        await logQueryPlan(db, queryWithParams, start);
        return result;
      };
    }
    const originalInstanceRun = instance.run.bind(instance);
    instance.run = async (db: unknown, queryWithParams: SQLiteQueryWithParams): Promise<void> => {
      try {
        return await originalInstanceRun(db, queryWithParams);
      } catch (e) {
        exceptionHandler.captureException(e, {
          extra: {
            ...queryWithParams,
            method: 'run',
          },
        });
      }
    };
    const originalInstanceAll = instance.all.bind(instance);
    instance.all = async (
      db: unknown,
      queryWithParams: SQLiteQueryWithParams,
    ): Promise<SQLResultRow[]> => {
      try {
        return await originalInstanceAll(db, queryWithParams);
      } catch (e) {
        exceptionHandler.captureException(e, {
          extra: {
            ...queryWithParams,
            method: 'all',
          },
        });
        // in case of a SQL execution error, just return empty results rather than crashing the app to reduce blast radius.
        return [];
      }
    };
  }
  return instance as unknown as RxStorageInstance<RxDocType, Internals, InstanceCreationOptions>;
}

export function wrappedSQLiteDocumentsStorage<Internals, InstanceCreationOptions>({
  storage,
}: {
  storage: RxStorage<Internals, InstanceCreationOptions>;
}): RxStorage<Internals, InstanceCreationOptions> {
  return {
    ...storage,
    createStorageInstance<RxDocType>(
      params: RxStorageInstanceCreationParams<RxDocType, InstanceCreationOptions>,
    ): Promise<RxStorageInstance<RxDocType, Internals, InstanceCreationOptions>> {
      return initializeSQLiteStorage(storage, params);
    },
  };
}
