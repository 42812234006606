export default function getRectIntersection(rectA: DOMRect, rectB: DOMRect): DOMRect | null {
  if (
    rectA.left < rectB.right &&
    rectA.right > rectB.left &&
    rectA.top < rectB.bottom &&
    rectA.bottom > rectB.top
  ) {
    const left = Math.max(rectA.left, rectB.left);
    const right = Math.min(rectA.right, rectB.right);
    const top = Math.max(rectA.top, rectB.top);
    const bottom = Math.min(rectA.bottom, rectB.bottom);

    return new DOMRect(left, top, right - left, bottom - top);
  }

  return null;
}
