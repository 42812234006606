import { DatabaseGetSchemaResult } from '../../../types/database';
import * as commonDefinitions from './commonDefinitions';

function getSchema(): { global_tags: DatabaseGetSchemaResult<'global_tags'>; } {
  return {
    global_tags: {
      schema: {
        primaryKey: 'id',
        additionalProperties: false,
        properties: {
          firstClassDocumentsCount: commonDefinitions.getIndexableInteger(),
          highlightsCount: commonDefinitions.getIndexableInteger(),
          id: commonDefinitions.getString(100), // Lower-cased name
          lastAssignedAt: commonDefinitions.getTimestamp(),
          name: commonDefinitions.getString(100),
          totalCount: commonDefinitions.getIndexableInteger(),
        },
        required: [
          'firstClassDocumentsCount',
          'highlightsCount',
          'id',
          'lastAssignedAt',
          'name',
          'totalCount',
        ],
        type: 'object',
        version: 0,
      },
      migrationStrategies: {},
    },
  } as const;
}

export default {
  getSchema,
};
