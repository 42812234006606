/*
  See https://stackoverflow.com/questions/53024819/sendresponse-not-waiting-for-async-function-or-promises-resolve
*/

import makeExtensionLogger from '../common/makeExtensionLogger';

const logger = makeExtensionLogger(__filename);

type OnRuntimeMessageCallback = Parameters<typeof chrome.runtime.onMessage.addListener>[0];
type OnRuntimeMessageCallbackParameters = Parameters<OnRuntimeMessageCallback>;

export default function adaptAsyncCallbackForChromeRuntimeOnMessage(
  // Whatever is returned is sent back as a response
  callback: (
    request: OnRuntimeMessageCallbackParameters[0],
    sender: OnRuntimeMessageCallbackParameters[1],
  ) => Promise<unknown>,
): OnRuntimeMessageCallback {
  return (request, sender, sendResponse) => {
    callback(request, sender)
      .then(sendResponse)
      .catch((error) => {
        logger.error(error instanceof Error ? error.message : error.toString(), { error });
        throw error;
      });
    return true;
  };
}
