import { getDesktopSessionId } from '../foreground/auth.desktop';
import { isDesktopApp, isDevOrTest, isExtension } from './environment';
import makeLogger from './makeLogger';
import request from './request';

const logger = makeLogger(__filename);

// Dev note (Tristan): Unfortunately we are not able to add custom headers to our fetch requests on web at the moment.
//  This is because adding/modifying any headers will make the request non-Simple, which will force the browser to
//  do an extra pre-flight request for each unique url, each session. We don't want this overhead at the moment, although
//  maybe we can revisit it when we implement SSEs. For more info on simple and preflight requests, see:
//  https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#simple_requests
export default async function requestWithAuth(
  input: RequestInfo | string,
  init?: RequestInit,
): ReturnType<typeof request> {
  const headers = (init?.headers ?? {}) as { [key: string]: string; };
  if (isDevOrTest && isExtension) {
    if (!('MOBILESESSION' in headers) && !('Authorization' in headers)) {
      const error = new Error('requestWithAuth: MOBILESESSION/Authorization header missing');
      logger.error(error.message, { input, init });
      throw error;
    }
  }
  if (isDesktopApp) {
    const desktopSessionId = await getDesktopSessionId();
    if (desktopSessionId) {
      headers.Authorization = desktopSessionId;
    }
    return request(input, {
      ...init,
      headers,
    });
  }
  return request(input, init);
}
