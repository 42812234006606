import { createMigrationKey } from './utils';

export enum StorageType {
  Documents = 'documents',
  Wal = 'wal',
}

export enum StorageVersion {
  Rxdb14 = 14,
  Rxdb15 = 15,
  Unknown = -1,
}

export const stateSyncingUpdatesKey = createMigrationKey('state_syncing_updates');
export const migrationStartedKey = createMigrationKey('started');
