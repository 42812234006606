import type { MaybePromise } from '../../../utils/typescriptUtils';
import type Database from '../../Database';
import prepareUpsertTransformers from './prepareUpsertTransformers';

export default async function addMiddleware(
  database: Database,
  additionalMiddleware?: (database: Database) => MaybePromise<void>,
) {
  if (!database.rxDbInstance) {
    throw new Error('database.rxDbInstance is falsy');
  }

  for (const collection of Object.values(database.rxDbInstance.collections)) {
    const runUpsertTransformers = prepareUpsertTransformers(collection);

    collection.preInsert(runUpsertTransformers, false);
    collection.preSave(runUpsertTransformers, false);
  }

  if (additionalMiddleware) {
    await additionalMiddleware(database);
  }
}
